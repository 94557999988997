import Title2Amatic from "../../components/Title3Amatic";
import { useTranslation } from "react-i18next";
const Loading = () => {
const { t } = useTranslation();
return (
    <div
    id="loading"
    className=" w-full  xxs:h-[600px] xs:h-[600px] s:h-[650px] sm:h-[420px] md:h-[430px] lg:h-[550px] xl:h-[650px] xxl:h-[750px]
                         flex flex-col  justify-center items-center
                         bg-transparent "
    >
   
      <div className="flex justify-center ml-5">
        <div className="lds-roller ">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

      <Title2Amatic titleText={t("Loading")} />
  </div>
  );
}
  
  export default Loading