import { useState, useEffect } from "react";
import Title2Amatic from "../../components/Title2Amatic";
import { useTranslation } from "react-i18next";
import Fondvideo from "../../assets/images/Fondvideo.jpg";
import Play from "../../assets/images/play.png"

function WatchVideo() {
  const { t,i18n } = useTranslation();
  const [src, setSrc] = useState("https://www.youtube.com/embed/LxRFfwQkDWk");

  useEffect(() => {
    const language = i18n.language;
    let x;

    if (language === "fr") {
      x = "https://www.youtube.com/embed/WcjDyAC27rI";
    } else if (language === "nl") {
      x = "https://www.youtube.com/embed/wWtxZsuk-Bw";
    } else {
      x = "https://www.youtube.com/embed/LxRFfwQkDWk";
    }
    setSrc(x);
  }, [i18n.language]);

  const [showVideo, setShowVideo] = useState(false);
  const thumbnailUrl = Fondvideo; 

  return (
    <div className="flex flex-col w-full items-center xxs:mb-10 lg:mb-16">
      <Title2Amatic titleText={t("Watch our video")} />
      <div onClick={() => setShowVideo(true)} style={{ cursor: 'pointer' }}>
      {showVideo ? (
       <iframe
       className="  items-center rounded-xl overflow-hidden 
         xxs:w-[80vw]  sm:w-[80vw] md:w-[50vw] xxl:w-[50vw] 
         xxs:h-[45vw]  sm:h-[45vw] md:h-[30vw] xxl:h-[30vw]"
       src={src}
       title="YouTube video player" 
       allowFullScreen
     ></iframe>
      ) : (
        <div className=" hover:shadow-inner rounded-xl  overflow-hidden  hover:bg-black hover:opacity-75 relative"> 
        <img  className="
        xxs:w-[80vw]  md:w-[60vw] lg:w-[50vw] xxl:w-[50vw]  " 
        src={thumbnailUrl} alt="Aperçu de la vidéo" />
            <img alt="play" src={Play} className="w-[30px] absolute bottom-5 left-5 z-[2]  "/>

        </div>
      )}
    </div>

    </div>
  );
}

export default WatchVideo;
