import Title1Amatic from "../components/Title2Amatic";
import Container from "../components/Container";
import { useTranslation } from "react-i18next";

const Starterskit = () => {
  const { t } = useTranslation();
  return (
    <div className="font-lexendlight flex flex-col items-center w-full text-base">
      <Container>
        <Title1Amatic titleText={t("Starterskit")} />

        <div className="flex justify-center w-full">
          <p className="font-lexendlight text-center ">
            {t(
              "Here is an initial roadmap of what you need to think about for setting up a short-chain activity:"
            )}
          </p>
        </div>

        <div className=" mt-5 w-full flex flex-col  text-justify  p-10 rounded-lg bg-light-green ">
          <div className="flex justify-between w-full py-2">
            <p className="font-amatic lg:text-[40px] xxs:text-[25px] text-green w-4/5 ">
              1 {t("Register as self-employed + apply for VAT no.")}
            </p>
            <input
              className="border border-green shadow-inner max-w-[50px] w-[10vw]"
              type="checkbox"
              id="item1"
            />
          </div>
          <hr/>

          <div className="flex justify-between w-full py-2">
            <p className="font-amatic lg:text-[40px] xxs:text-[25px] text-green w-4/5 ">
              2 {t("Find a bookkeeper for guidance")}
            </p>{" "}
         
          <input
            className="border border-green shadow-inner max-w-[50px] w-[10vw]"
            type="checkbox"
            id="item1"
          />
           </div>
           <hr/>
          <div className="flex justify-between w-full py-2">
            <p className="font-amatic lg:text-[40px] xxs:text-[25px] text-green w-4/5 ">
              3 {t("Open a bank account")}
            </p>{" "}
          
          <input
            className="border border-green shadow-inner max-w-[50px] w-[10vw]"
            type="checkbox"
            id="item1"
          />
          </div>
          <hr/>
          <div className="flex justify-between w-full py-2">
            <p className="font-amatic lg:text-[40px] xxs:text-[25px] text-green w-4/5 ">
              4 {t("Civil liability insurance (food retail)")}
            </p>{" "}
         
          <input
            className="border border-green shadow-inner max-w-[50px] w-[10vw]"
            type="checkbox"
            id="item1"
          />
           </div>
           <hr/>
          <div className="flex justify-between w-full py-2">
            <p className="font-amatic lg:text-[40px] xxs:text-[25px] text-green w-4/5 ">
              5{" "}
              {t(
                "Register with AFSCA/FAVV and draw up self-checking plan, possibly in consultation with Steunpunt Korte Keten"
              )}
            </p>{" "}
         
          <input
            className="border border-green shadow-inner max-w-[50px] w-[10vw]"
            type="checkbox"
            id="item1"
          />
           </div>
           <hr/>
          <div className="flex justify-between w-full py-2">
            <p className="font-amatic lg:text-[40px] xxs:text-[25px] text-green w-4/5 ">
              6{" "}
              {t(
                "Create domain name, brand name, logo and possibly register brand name and logo at www.boip.int"
              )}
            </p>{" "}
         
          <input
            className="border border-green shadow-inner max-w-[50px] w-[10vw]"
            type="checkbox"
            id="item1"
          />
           </div>
           <hr/>
          <div className="flex justify-between w-full py-2">
            <p className="font-amatic lg:text-[40px] xxs:text-[25px] text-green w-4/5 ">
              7 {t("Contact Linked.farm (or others) for a digital solution")}
            </p>{" "}
         
          <input
            className="border border-green shadow-inner max-w-[50px] w-[10vw]"
            type="checkbox"
            id="item1"
          />
           </div>
           <hr/>
          <div className="flex justify-between w-full py-2">
            <p className="font-amatic lg:text-[40px] xxs:text-[25px] text-green w-4/5 ">
              8{" "}
              {t(
                "Create Facebook page (FB) and link it to your website. Start using FB to build a community even before the project has started."
              )}
            </p>{" "}
          
          <input
            className="border border-green shadow-inner max-w-[50px] w-[10vw]"
            type="checkbox"
            id="item1"
          />
          </div>
          <hr/>
          <div className="flex justify-between w-full py-2">
            <p className="font-amatic lg:text-[40px] xxs:text-[25px] text-green w-4/5 ">
              9 {t("Collect mail addresses for newsletter.")}
            </p>{" "}
         
          <input
            className="border border-green shadow-inner max-w-[50px] w-[10vw]"
            type="checkbox"
            id="item1"
          />
           </div>
           <hr/>
          <div className="flex justify-between w-full py-2">
            <p className="font-amatic lg:text-[40px] xxs:text-[25px] text-green w-4/5 ">
              10 {t("Create Instagram account and link to FB and website.")}
            </p>{" "}
          
          <input
            className="border border-green shadow-inner max-w-[50px] w-[10vw]"
            type="checkbox"
            id="item1"
          />
          </div>
          <hr/>
          <div className="flex justify-between w-full py-2">
            <p className="font-amatic lg:text-[40px] xxs:text-[25px] text-green w-4/5 ">
              11 {t("Create Twitter account and link to Instagram and FB")}
            </p>{" "}
         
          <input
            className="border border-green shadow-inner max-w-[50px] w-[10vw]"
            type="checkbox"
            id="item1"
          />
           </div>
           <hr/>
          <div className="flex justify-between w-full py-2">
            <p className="font-amatic lg:text-[40px] xxs:text-[25px] text-green w-4/5 ">
              12 {t("Create Mailchimp account and link to website")}
            </p>{" "}
         
          <input
            className="border border-green shadow-inner max-w-[50px] w-[10vw]"
            type="checkbox"
            id="item1"
          />
           </div>
           <hr/>
          <div className="flex justify-between w-full py-2">
            <p className="font-amatic lg:text-[40px] xxs:text-[25px] text-green w-4/5 ">
              13{" "}
              {t("Create Mollie account (payment system) and link to website")}
            </p>{" "}
          
          <input
            className="border border-green shadow-inner max-w-[50px] w-[10vw]"
            type="checkbox"
            id="item1"
          />
          </div>
          <hr/>
          <div className="flex justify-between w-full py-2">
            <p className="font-amatic lg:text-[40px] xxs:text-[25px] text-green w-4/5 ">
              14{" "}
              {t(
                "Find farmers, visit them, take pictures of farm, products, ... Clearly agree on a delivery day : order is done early morning day before delivery. For each product, ask the farmer's price excluding 6% VAT (what he/she wants to keep) and weight (+photo)."
              )}{" "}
            </p>{" "}
         
          <input
            className="border border-green shadow-inner max-w-[50px] w-[10vw]"
            type="checkbox"
            id="item1"
          />
           </div>
           <hr/>
          <div className="flex justify-between w-full py-2">
            <p className="font-amatic lg:text-[40px] xxs:text-[25px] text-green w-4/5 ">
              15 {t("Find means of transport or carrier")} <br />.{" "}
              {t(
                "Invest in plastic crates and if needed, cool boxes or refrigerated transport"
              )}{" "}
            </p>{" "}
         
          <input
            className="border border-green shadow-inner max-w-[50px] w-[10vw]"
            type="checkbox"
            id="item1"
          />
           </div>
           <hr/>
          <div className="flex justify-between w-full py-2">
            <p className="font-amatic lg:text-[40px] xxs:text-[25px] text-green w-4/5 ">
              16{" "}
              {t(
                "Find space for picking (preferably with cold store and already approved by AFSCA/FAVV)"
              )}{" "}
            </p>{" "}
          
          <input
            className="border border-green shadow-inner max-w-[50px] w-[10vw]"
            type="checkbox"
            id="item1"
          />
          </div>
          <hr/>
          <div className="flex justify-between w-full py-2">
            <p className="font-amatic lg:text-[40px] xxs:text-[25px] text-green w-4/5 ">
              17 {t("Reach out to municipal services and ask for support.")}
            </p>{" "}
         
          <input
            className="border border-green shadow-inner max-w-[50px] w-[10vw]"
            type="checkbox"
            id="item1"
          />
           </div>
           <hr/>
          <div className="flex justify-between w-full py-2">
            <p className="font-amatic lg:text-[40px] xxs:text-[25px] text-green w-4/5 ">
              18 {t("Expand network and collect mail addresses for newsletter")}
            </p>{" "}
         
          <input
            className="border border-green shadow-inner max-w-[50px] w-[10vw]"
            type="checkbox"
            id="item1"
          />
           </div>
           <hr/>
        </div>
      </Container>
    </div>
  );
};
export default Starterskit;
