import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Container from "../components/Container";
import SubmitForm from "../components/SubmitForm";
import { useForm } from "react-hook-form";
import Title2Amatic from "../components/Title2Amatic";

const Contact = () => {
  const { register } = useForm();
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const source = "Contact";

  const handleSubmit = () => {};

  return (
    <div className=" font-lexend w-full text-green flex flex-col  xxs:items-center lg:items-center  ">
      <Container>
        <Title2Amatic titleText={t("Send us a message")} />

        <SubmitForm
          onSubmit={handleSubmit}
          source={source}
          payload={{ name, email, phone, subject, message }}
        >
          <div className="flex flex-col w-full items-center">
            <input
              {...register("name", { required: true })}
              type="text"
              id="name"
              name="name"
              required
              className=" p-2 border my-2 shadow-inner rounded-lg bg-grey border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none block  w-full text-sm"
              placeholder="Anna Jansen"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <input
              {...register("email", { required: true })}
              type="email"
              placeholder="AnnaJansen@gmail.com"
              id="email"
              name="email"
              value={email}
              required
              className=" p-2  border my-2 shadow-inner rounded-lg bg-grey border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none block flex-1 w-full text-sm"
              onChange={(e) => setEmail(e.target.value)}
            />

            <input
              {...register("phone", { required: true })}
              required
              onChange={(e) => setPhone(e.target.value)}
              className=" p-2 border my-2   shadow-inner rounded-lg bg-grey border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none block flex-1 w-full text-sm"
              id="phone"
              type="text"
              placeholder={t("Phone")}
              pattern="\d{9,}"
              title="Please enter a valid phone number with at least 9 digits"
            />
            <input
              {...register("subject", { required: true })}
              type="text"
              className=" p-2 border my-2   shadow-inner rounded-lg bg-grey border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none block flex-1 w-full text-sm"
              placeholder={t("Subject")}
              value={subject}
              required
              id="subject"
              name="subject"
              onChange={(e) => setSubject(e.target.value)}
            />

            <textarea
              {...register("message")}
              className="p-2 h-[75px] my-2 border  mb-8 border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none rounded-md bg-grey w-full shadow-inner text-black text-sm"
              rows="5"
              name="message"
              required
              id="message"
              placeholder={t("What is your question or message?")}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
        </SubmitForm>
      </Container>
    </div>
  );
};

export default Contact;
