import React from "react";
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";

const ButtonGreen = ({ text, link, type, onClick, target,text2}) => {
    return (
        <Link to={link} target={target}>
            <motion.div  className="border-green hover:border
                            box font-lexendbold flex justify-center items-center capitalize text-white bg-green  hover:bg-light-green  hover:text-green drop-shadow-sm
                            box-border  tracking-wider 
                            xxs:px-5  sm:px-6  xl:px-10 
                            xxs:mb-0 s:mb-10 lg:mb-16 mt-6 xxs:mt-0  md:mt-0
                            xxs:min-w-[180px] sm:min-w-[180px]   xl:min-w-[250px]  xxl:min-w-[300px]  
                            4xl:text-3xl xxl:text-lg xl:text-lg lg:text-sm xxs:text-sm   hyphens-auto 
                            xxs:py-[9px]   sm:py-[8px] md:py-[8px]  xl:py-[9px]  xxl:py-3 
                            xxs:rounded-lg sm:rounded-lg"
                            whileHover={{ scale: 1.02 }}
                            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
                <button onClick={onClick} className="p-O m-O h-fit" type={type} >
                {text} <br/> {text2}
                </button>
            </motion.div>                            
        </Link>
    );
}
export default ButtonGreen;
    