import React, { useState, useRef } from "react";
import Title1Amatic from "../../components/Title1AmaticGreen";
import Title2Amatic from "../../components/Title2Amatic";
import { useForm } from "react-hook-form";
import Container from "../../components/Container";
import SubmitForm from "../../components/SubmitForm";
import Typeofshares from "../../components/Typeofshares";
import { useTranslation } from "react-i18next";
import Goodtoknow from "../../components/Goodtoknow";
import { Link } from "react-router-dom";

const JoinAsEmployee = () => {
  const { t } = useTranslation();
  const { register } = useForm();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [nrn, setNrn] = useState("");
  const [addressStreet, setAddressStreet] = useState("");
  const [addressPlace, setAddressPlace] = useState("");
  const [addressPostalCode, setAddressPostalCode] = useState("");
  const [shareType, setShareType] = useState("C");
  const [amountShares, setAmountShares] = useState("1");
  const [howDidYouKnowUs, setHowDidYouKnowUs] = useState("");
  const sectionRef = useRef(null);
  const source = "CooperantJoinAsSelfEmployed";
  const [selectedValue, setSelectedValue] = useState("");
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  

  const handleSubmit = () => {
    // Handle form submission
  };

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    setAmountShares(e.target.value);
  };

  const shares = [];
  for (let i = 21; i <= 1000; i++) {
    const text = `${i} € ${(i * 250).toFixed(2)}`;
    shares.push(
      <option  key={i} value={i}>
        {text}
      </option>
    );
  }

  const shares2 = [];
  for (let i = 1; i <= 100; i++) {
    const text = `${i} € ${(i * 50).toFixed(2)}`;
    shares2.push(
      <option  key={i} value={i}>
        {text}
      </option>
    );
  }

  return (
    <Container>
      <Title1Amatic titleText={t("Join a self-employed or as an individual")} />

     <Goodtoknow/>

      <div className="flex justify-between xxs:flex-col md:flex-row  md:items-start xxs:items-center mb-10">
        <SubmitForm
          onSubmit={handleSubmit}
          source={source}
          payload={{
            name,
            email,
            nrn,
            howDidYouKnowUs,
            amountShares,
            shareType,
            addressPlace,
            addressPostalCode,
            addressStreet,
            phone,
          }}
        >
          <Title2Amatic titleText={t("Let's start")} />
          
           {/*name */}
          <div className="flex flex-col my-2  ">
            <label className="flex text-end w-full tracking-wider mb-2">
              {t("Full Name")}
            </label>
            <input
              required
              {...register("name")}
              onChange={(e) => setName(e.target.value)}
              className="appearance-none flex w-full  p-2 border leading-tightshadow-inner rounded-lg  bg-grey  border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none mb-3  "
              id="name"
              type="text"
              placeholder="Anna Jansen"
            />
          </div>

           {/*email*/}
          <div className="flex flex-col my-2  ">
            <label className=" flex text-end w-full tracking-wider mb-2">
              {t("Email")}
            </label>
            <input
              required
              {...register("email")}
              onChange={(e) => setEmail(e.target.value)}
              className="appearance-none flex mb-3 w-full  p-2 border leading-tight shadow-inner rounded-lg bg-grey  border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none  "
              id="email"
              type="email"
              placeholder="anna@gmail.com"
            />
          </div>

          {/*phone */}
          <div className="flex  flex-col my-2 ">
            <label className=" flex  mb-1">{t("Phone")} </label>
            <input
              {...register("phone")}
              required
              onChange={(e) => setPhone(e.target.value)}
              className="mb-3 text-green  flex p-2 border leading-tight shadow-inner rounded-lg  bg-grey  border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none"
              id="NRN"
              type="text"
              placeholder="0480000000"
              pattern="\d{9,}"
              title="Please enter a valid phone number with at least 9 digits"
            />
          </div>

          {/*NNR */}
          <div className="flex flex-col my-2  ">
            <label className=" flex text-end w-full tracking-wider mb-2">
              {t("National Registration N°")}
            </label>
            <input
              required
              {...register("nrn")}
              onChange={(e) => setNrn(e.target.value)}
              className="mb-3 appearance-none flex p-2 border leading-tight shadow-inner rounded-lg  bg-grey  border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none  "
              id="NRN"
              pattern="\d{11}"
              title="Please enter a valid national registration number with 11 digits"
              type="text"
              placeholder="00000000000"
            />
          </div>

          {/*address */}
          <div className="flex flex-col my-2  ">
            <label className=" flex text-end w-full tracking-wider mb-2">
              {t("Address + n°")}
            </label>
            <input
              required
              {...register("addressStreet")}
              onChange={(e) => setAddressStreet(e.target.value)}
              className="mb-3 appearance-none flex w-full  p-2 border leading-tightshadow-inner rounded-lg  bg-grey  border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none  "
              id="address"
              type="address"
              placeholder="123 Nieuwstraat Straat"
            />
          </div>

           {/*zipcode */}
          <div className="flex flex-col my-2  ">
            <label className=" flex text-end w-full tracking-wider mb-2" />
            {t("Zip Code")}
            <input
              required
              {...register("addressPostalCode")}
              onChange={(e) => setAddressPostalCode(e.target.value)}
              className="mb-3 appearance-none flex w-full  p-2 border leading-tightshadow-inner rounded-lg  bg-grey  border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none  "
              id="zip"
              type="text"
              pattern="\d{4}"
              title="Please enter a valid zip code number with 4 digits"
              placeholder="1000"
            />
          </div>

          {/*city */}
          <div className="flex flex-col my-2  ">
            <label className=" flex text-end w-full tracking-wider mb-2">
              {t("City")}
            </label>
            <input
              required
              {...register("addressPlace")}
              onChange={(e) => setAddressPlace(e.target.value)}
              className="mb-3 appearance-none flex w-full  p-2 border leading-tightshadow-inner rounded-lg  bg-grey  border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none  "
              id="city"
              type="text"
              placeholder="Brussel"
            />
          </div>

          {/*share type */}
          <div className="flex flex-col my-2  ">
            <label className=" flex text-center tracking-wider mb-1">
              {t("Share Type")}
            </label>

            <select
              required
              {...register("shareType")}
              onChange={(e) => setShareType(e.target.value)}
              id="shareType"
              className="  hyphens-auto  bg-white border-green border  p-2 rounded-lg shadow leading-tight "
            >
              <option value="C" className="hyphens-auto">
                {t("C-Investor-250Eur/Sh")}
              </option>
              <option value="D" className="hyphens-auto">
                {t("D-Sympathizers-50Eur/sh")}
              </option>
            </select>

            <p className="text-green text-s pl-1 ">
              C min 21 Pieces pp
              <br />D max 100 Pieces pp
            </p>
            <Link
              href="/becomeacooperant"
               /* target="_blank"*/
              className="hover:text-green text-start w-fit  text-black text-sm mb-3 underline"
            >
              {t("Find out about our other options")}
            </Link>
          </div>

          {/* n° Shares */}
          <div className="flex  flex-col my-2 w-full  ">
            <label className=" flex w-full mb-1">{t("Number of shares")}</label>

            {shareType === "C" && (
              <select
                {...register("amountShares")}
                required
                className="hyphens-auto w-full bg-white border-green border mb-3 p-2 rounded-lg shadow leading-tight"
                value={selectedValue}
                onChange={handleChange}
              >
                <option value="" disabled hidden>
                {t("Select a number")}
                </option>
                {shares}
              </select>
            )}

            {shareType === "D" && (
              <select
                {...register("amountShares")}
                required
                className="hyphens-auto w-full bg-white border-green border mb-3 p-2 rounded-lg shadow leading-tight"
                value={selectedValue}
                onChange={handleChange}
              >
                <option value="" disabled hidden>
                {t("Select a number")}
                </option>
                {shares2}
              </select>
            )}
          </div>

          <div className="flex flex-col my-2 mb-10  ">
            <label className=" flex mb-1">
              {t("How you heard about us ?")}
            </label>
            <select
              required
              {...register("howDidYouKnowUs")}
              ref={sectionRef}
              onChange={(e) => {
                const value = e.target.value;
                if (value !== "Other") {
                  setHowDidYouKnowUs(value);
                }
                setIsOtherSelected(value === "Other");
              }}
              
              id="howDidYouKnowUs"
              className="  hyphens-auto mb-10 bg-white border-green border  p-2 rounded-lg shadow leading-tight "
            >
              <option value="">{t("Select an option")}</option>
              <option value="Social Media">{t("Social Media")}</option>
              <option value="Word of Mouth">{t("Word of Mouth")}</option>
              <option value="Website">{t("Website")}</option>
              <option value="Colleagues">{t("Colleagues")}</option>
              <option value="Friends">{t("Friends")}</option>
              <option value="Other">{t("Other")}</option>
            </select>
            {isOtherSelected && (
              <input
                required
                {...register("howDidYouKnowUs")}
                className="mb-3 appearance-none flex w-full  p-2 border leading-tightshadow-inner rounded-lg  bg-grey  border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none  "
                id="other"
                type="text"
                value={howDidYouKnowUs || ""} 
                placeholder={t("Please specify")}
                onChange={(e) => setHowDidYouKnowUs(e.target.value)}
              />
            )}
          </div>
          
        </SubmitForm>

       <Typeofshares/>
      </div>
    </Container>
  );
};

export default JoinAsEmployee;
