import {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import img1 from "../../assets/logo/logobrussels.png";
import img2 from "../../assets/logo/Logo-Be-Circular.png";
import img3 from "../../assets/logo/Logo-Voedsel.png";
import img4 from "../../assets/logo/ShiftingEconomies.png";
import NewsLetter from "./NewsLetter";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t ,i18n} = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState("en_EN");


  useEffect(() => {
    const language = i18n.language;
    let x;
    if (language === "fr") {
      x = "fr_FR";
    } else if (language === "nl") {
      x = "nl_BE";
    } else {
      x = "en_EN";
    }
    setCurrentLanguage(x);
  }, [i18n.language]);


  return (
    <div className=" text-green flex items-center flex-col w-full cursor-default bottom-0">
      <NewsLetter />
      <div className="bg-green w-full max-w-[2000px] ">
        <footer className="bg-green pt-5 tracking-wider  w-full xxs:text-s  text-white font-lexendbold">
          <div className=" xxs:px-[10vw] flex flex-wrap s:px-[10vw] sm:px-10  xl:px-28 xxl:px-28  w-full ">
            <div className="xxs:w-full  lg:w-1/4 mb-2  ">
              <h2 className="xxs:mt-5 s:mt-0 mb-2 xxs:text-center lg:text-left font-lexendbold xxs:text-[17px] sm:text-[15px] xxl:text-xl   text-white tracking-wider uppercase">
                {t("Cooperative")}
              </h2>
              <ul className="  text-white ">
                <li className="mb-3 xxs:text-center lg:text-left">
                  <Link to="/" className="  text-white hover:underline">
                    {t("Home")}
                  </Link>
                </li>
                <li className="mb-3 xxs:text-center lg:text-left">
                  <Link to="/about" className=" text-white  hover:underline">
                    {t("About")}
                  </Link>
                </li>
               {/*  <li className="mb-3 xxs:text-center lg:text-left">
                  <Link
                    to="/Services"
                    className=" text-white  hover:underline"
                  >
                   Services
                  </Link>
                </li>
                */}
                <li className="mb-3 xxs:text-center lg:text-left">
                  <Link
                    to="/linkedfarmfamily"
                    className=" text-white  hover:underline"
                  >
                    Linked.Farm family
                  </Link>
                </li>
                <li className="mb-3 xxs:text-center lg:text-left">
                  <Link to="/rates" className=" hover:underline">
                    {t("Rates and pricing")}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="xxs:w-full   lg:w-1/4 mb-2">
              <h2 className="mb-2 xxs:text-center lg:text-left text-white font-lexendbold xxs:text-[17px] sm:text-[15px] xxl:text-xl  text-base tracking-wider uppercase">
                {t("Join us")}
              </h2>
              <ul className="text-white">
                <li className="mb-3 xxs:text-center lg:text-left">
                  <Link to="/becomeacooperant" className="hover:underline">
                    {t("As a cooperant")}
                  </Link>
                </li>
                <li className="mb-3 xxs:text-center lg:text-left">
                  <Link to="/farmer" className="hover:underline">
                    {t("As a single producer")}
                  </Link>
                </li>
                <li className="mb-3 xxs:text-center lg:text-left">
                  <Link to="/shop" className="hover:underline">
                    {t("As a shop/hub")}
                  </Link>
                </li>
                <li className="mb-3 xxs:text-center lg:text-left">
                  <Link to="/restaurant" className="hover:underline">
                    {t("As a restaurant")}
                  </Link>
                </li>
                <li className="mb-3 xxs:text-center lg:text-left">
                  <Link to="/wholesaler" className="hover:underline">
                    {t("As a wholesaler")}
                  </Link>
                </li>
              </ul>
            </div>

            <div className="xxs:w-full  lg:w-1/4 mb-2 ">
              <h2 className="mb-2 xxs:text-center lg:text-left font-lexendbold xxs:text-[17px] sm:text-[15px] xxl:text-xl  text-base text-white tracking-wider uppercase">
                {t("Help center")}
              </h2>
              <ul className=" ">
                <li className="mb-3 xxs:text-center lg:text-left">
                  <Link to="/faq" className=" hover:underline">
                  {t("Frequently asked questions")}
                  </Link>
                </li>
                <li className="mb-3 xxs:text-center lg:text-left">
                  <Link to="/starterskit" className="hover:underline ">
                    {t("Starterskit")}
                  </Link>
                </li>

                <li className="mb-3 xxs:text-center lg:text-left">
                  <Link
                    to="mailto:support@linkedfarm.eu"
                    className="hover:underline "
                  >
                    {t("Support")}
                  </Link>
                </li>

                <li className="mb-3 xxs:text-center lg:text-left">
                  <Link   to={`https://wiki.linkedfarm.eu/#/${currentLanguage}`}
                      target="_blank"
                    className=" hover:underline"
                  >
                    Wiki
                  </Link>
                </li>
              </ul>
            </div>

            <div className="xxs:w-full  lg:w-1/4 mb-2 ">
              <h2 className="mb-2 xxs:text-center lg:text-left text-white font-lexendbold xxs:text-[17px] sm:text-[15px] xxl:text-xl  text-base tracking-wider uppercase">
                {t("In collaboration")}
              </h2>

              <div className="flex my-2  w-full xxs:justify-evenly  lg:justify-between ">
                <img
                  className="h-[2vw] my-2 min-h-[25px]  object-contain"
                  src={img4}
                  alt="shifting-economy-brussels"
                />

                <img
                  className="h-[2vw]  min-h-[40px] object-contain "
                  src={img1}
                  alt="be-chez-vous-be-brussels "
                />
              </div>
              <div className="flex my-2  w-full xxs:justify-evenly lg:justify-between items-center">
                <img
                  className="h-[2vw] mt-2 min-h-[40px] object-contain"
                  src={img3}
                  alt="be-chez-vous-be-brussels"
                />
                <img
                  className="h-[2vw]  min-h-[40px] object-contain"
                  src={img2}
                  alt="be-Circular-Be-brussels"
                />
              </div>
            </div>
          </div>
        </footer>
        <span className="block text-white text-center xxl:text-sm  text-xs">
          Linked.farm © 2023.{t("All rights reserved.")}
        </span>
        <Link
        target="_blank"
          to="/termsofsales"
          className="block text-center text-white underline xxl:text-sm  text-xs "
        >
          {t("Terms of sales")}
        </Link>
        <Link
        target="_blank"
          to="/privacycharter"
          className="block text-center text-white underline xxl:text-sm  text-xs pb-2"
        >
          {t("Privacy charter")}
        </Link>
      </div>
    </div>
  );
}

export default Footer;
