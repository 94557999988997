import {motion} from "framer-motion"

const animations = {
    initial:{opacity:0, y:200, },
    animate:{opacity:1, y:0}
}

const AnimationBottom = ({children}) =>{
return (
    <motion.div
    variants={animations}
    initial="initial"
    animate="animate"
    transition={{duration:0.6, delay:0.2}}
>
    {children}

    </motion.div>
)


}
export default AnimationBottom