import React, { useEffect, useRef, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
const MapAutoComplete = ({ onLoad, options, onPlaceChanged }) => {
  const inputRef = useRef(null);
  const [hasSelected, setHasSelected] = useState(false);
  const { t } = useTranslation();

  const handleGeocodeQuery = useCallback((query) => {
    
    const geocoder = new window.google.maps.Geocoder();
  
    console.log("Attempting to geocode:", query); 
  
    geocoder.geocode({ address: query + ', Belgium' }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        console.log("Geocoding success:", results); 
        onPlaceChanged(results[0]);
      } else {
        console.log("Geocoding failed:", status); 
        alert('No results found');
      }
    });
  }, [onPlaceChanged]);
  
  
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const input = e.target.value;
      // Ici, vous pouvez ajouter des validations supplémentaires si nécessaire
      handleGeocodeQuery(input);
    }
  };
  
  
  useEffect(() => {
    const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, options);
    const handlePlaceSelection = (place) => {
      if (place && place.geometry) {
        // Place sélectionnée via l'autocomplétion
        setHasSelected(true);
        onPlaceChanged(place);
      } else if (!hasSelected) {
        // Saisie manuelle non sélectionnée via l'autocomplétion
        handleGeocodeQuery(inputRef.current.value);
      }
    };
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      handlePlaceSelection(place);
    });
  
    onLoad(autocomplete);
  }, [onLoad, options, onPlaceChanged,handleGeocodeQuery, hasSelected]);
  

  useEffect(() => {
    const currentInput = inputRef.current;

    const handleBlur = () => {
      if (!hasSelected) {
        handleGeocodeQuery(currentInput.value);
      }
    };

    currentInput.addEventListener("blur", handleBlur);

    return () => {
      currentInput.removeEventListener("blur", handleBlur);
    };
  }, [hasSelected, onPlaceChanged, handleGeocodeQuery]);

  return (
    <input
  onKeyDown={handleKeyDown}
  ref={inputRef}
  placeholder={t("Enter a location")}
  className="px-4 xxs:mb-5 md:mb-16 text-gray-700 focus:border-blue-300 p-2  border  shadow-inner rounded-lg bg-grey border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none block xxs:w-full  s:w-1/2 lg:w-full text-sm"
/>

  );
};

export default MapAutoComplete;
