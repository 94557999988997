import React from "react";
import BoxMain from "./BoxMain";

const SmallHeader3Main = ({
  buttonText1,
  text1,
  buttonText2,
  text2,
  buttonText3,
  text3,
  text4,
  link1,
  link2,
  link3,
  link4,
  path1,
  path2,
  path3,
  para1,
  para2,
  para3,
  path4,
  buttonText4,
}) => {
  return (
    <div className=" flex flex-col items-center w-full 
    xxs:px-[10vw] s:px-[10vw] sm:px-10 md:px-32 lg:px-10 xl:px-10 xxl:px-20 "
    >
      <div
        className=" flex xxs:justify-center lg:justify-between items-center w-full flex-wrap   
                  box-border   xxs:h-fit lg:h-[240px] xl:h-[280px]  xxl:h-[350px] "
      >
        <BoxMain text={text1} path={path1} para={para1} link={link1} buttonText={buttonText1}/>
        <BoxMain text={text2} path={path2} para={para2} link={link2} buttonText={buttonText2}/>
        <BoxMain text={text4} path={path4} para={para2} link={link4} buttonText={buttonText4}/>
        <BoxMain text={text3} path={path3} para={para3} link={link3} buttonText={buttonText3}/>
      </div>
    </div>
  );
};

export default SmallHeader3Main;
