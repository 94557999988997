import { useTranslation } from "react-i18next";

const MapListToggle = ({ currentView, setCurrentView, filterOption }) => {
  const { t } = useTranslation();
  return (
    <div className="absolute xxs:pl-0 md:pl-2 m-4 z-10 flex xxs:flex-col sm:flex-row font-lexendbold justify-between ">
      {filterOption !== "deliveries" && (
        <button
          className={`xxs:px-2 s:px-4 py-2 s:w-[100px] xxs:w-[70px]  ${
            currentView === "map"
              ? "bg-green text-white"
              : "bg-white  text-green"
          }`}
          onClick={() => setCurrentView("map")}
        >
          {t("Map")}
        </button>
      )}
      <button
        className={`xxs:px-2 s:px-4 py-2 s:w-[100px] xxs:w-[70px] ${
          currentView === "list"
            ? "bg-green text-white"
            : "bg-white text-green"
        }`}
        onClick={() => setCurrentView("list")}
      >
        {t("List")}
      </button>
    </div>
  );
};

export default MapListToggle  