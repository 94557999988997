import React from "react";
import Box from "./Box";

const SmallHeader2 = ({ onClick, buttonText1, text1, buttonText2, text2 ,link1}) => {
  return (
    <div className=' flex flex-col items-center w-full 
    xxs:px-[10vw] s:px-[10vw] sm:px-10 md:px-20 lg:px-20 xl:px-28 xxl:px-28 
    xxl:max-w-[2000px]
    '>
    <div
      className=" flex justify-evenly items-center w-full  sm:flex-row s:items-center xxs:flex-col xxs:items-center  
      box-border  s:h-[580px] xs:h-[580px] xxs:h-[580px] sm:h-[240px] xl:h-[280px]  xxl:h-[350px] "
    >
      <Box buttonText={buttonText1} link={link1} text={text1}/>
      <Box buttonText={buttonText2} onClick={onClick} text={text2}/>
    </div>
    </div>
  );
};

export default SmallHeader2;
