import React from "react";
import { LoadScript } from "@react-google-maps/api";
import Mapelements from "./Mapelements";
import Title2Amatic from "../../components/Title2Amatic";
import farmer from "../../assets/Drawings/farmerduck.png";
import Smallimage from "../../components/SmallImageSquare";
import Container from "../../components/Container";
import woman from "../../assets/Drawings/mensplukt.svg";
import { useTranslation } from "react-i18next";
import ButtonGreen from "../../components/ButtonGreen";
const libraries = ["places"];

const Map2Page = () => {
  const { t } = useTranslation();
  return (
    < div className="flex flex-col items-center">
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        libraries={libraries}>
       
        <Mapelements />
      </LoadScript>
      <div className="flex flex-col items-center">
      <Container>
        <Title2Amatic titleText={t("How do I buy from a Linked.farm hub?")} />
        <div
          className="flex  w-full md:flex-row md:justify-between xxs:flex-col  sm:flex-col items-center  
                      xxs:mb-8 sm:mb-8 mb-12 
                      cursor-default"
        >
          <p
            className="  xxs:w-full s:w-full  sm:w-[580px] md:w-[370px] lg:w-[490px] xl:w-[600px] xxl:w-[800px]
                              tracking-wide text-justify font-lexend   
                              xxs:mb-5 sm:mb-5"
          >
            {t("1. Choose your hub on the map.")}
            <br /> <br />
            {t("2. Order your products.")}
            <br /> <br />
            {t(
              "You don't have to become a Linked.Farm cooperant to buy from a hub."
            )}
          </p>
          <Smallimage imagePath={farmer} />
        </div>
        <Title2Amatic titleText={t("How do I deliver to a Linked.farm hub?")} />
        <div
          className="flex w-full md:flex-row md:justify-between xxs:flex-col  sm:flex-col items-center  
                      xxs:mb-8 sm:mb-8 mb-12  "
        >
          <Smallimage imagePath={woman} />
          <p
            className="  xxs:w-full s:w-full  sm:w-[580px] md:w-[370px] lg:w-[490px] xl:w-[600px] xxl:w-[800px]   
                              tracking-wide text-justify font-lexend   
                              xxs:mb-5 sm:mb-5"
          >
            {t("1. Choose your hub on the map.")}
            <br /> <br />
            {t("2. Click on the hub's contact button.")}
            <br /> <br />
            {t(
              "You don't have to become a Linked.Farm cooperant to deliver to a hub. Delivery to a hub is free of charge."
            )}
          </p>
        </div>
        <ButtonGreen text={t("Start my hub")} link={"/shop"}/>
      </Container>
      </div>
    </div>
  );
};

export default Map2Page;
