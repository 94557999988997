import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { BsFacebook } from "react-icons/bs";
import {
  AiFillInstagram,
  AiOutlineTwitter,
  AiFillLinkedin,
} from "react-icons/ai";

const NewsLetter = () => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const API ="https://www.linked.farm/api/v2/linkedfarm/view/linkedfarm.php";

  const url = `${API}?data=newsletter&api=wefg496ad45afsjeg4s633924ujimshd4g56a4f13aghg56f4sa&hubID=66&name=${name}&email=${email}`;
  const formRef = useRef(null);

  const NewsLetterAPI = (event) => {
    event.preventDefault();

  // Check if agree
  if (event.target.agreetoterms.value===1) {
    return;
  }

    // Send the subscription request to the server using Axios
    axios
      .get(url)
      .then((response) => {
        // Handle the success response here
        alert("Subscription successful!");
        formRef.current.reset();
      })
      .catch((error) => {
        // Handle the error response here
        alert(error.response.data.error);
      });
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div className="bg-light-green xxs:px-[10vw] s:px-[10vw] sm:px-10 md:px-10  xl:px-28 xxl:px-28 w-full max-w-[2000px] "
    >
      <h2 className="font-amatic pt-5 xxs:text-center lg:text-left  text-green hyphens-none mb-5 
       xxl:text-[60px]  lg:text-[45px] md:text-[40px]  sm:text-[35px] s:text-[40px] xs:text-[36px] xxs:text-[32px]">
      {t("Stay connected with us")} <br className="sm:hidden"/> {t("and join our family")}
      </h2>

      <div className="w-full flex xxs:flex-col lg:flex-row justify-between items-center  ">
        <form
          ref={formRef}
          onSubmit={NewsLetterAPI}
          className="flex xxs:w-full lg:w-1/2 flex-col pb-6 justify-between xxs:items-center lg:items-start "
        >
          <h6 className="font-lexendbold w-full  flex xxs:justify-center lg:justify-start xxs:text-[17px] sm:text-[15px] xxl:text-xl  text-base   mb-3">
            NEWSLETTER
          </h6>

          <div className="w-full xxs:justify-between lg:justify-start xxs:items-center flex xxs:flex-col lg:flex-row">
            <input
              placeholder="Name"
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={handleNameChange}
              required
              className="w-[200px] xxl:w-[250px]  mb-6 border lg:mr-5 
                          xxs:mr-0 text-txt-grey md:text-s  xxl:text-base p-2 bg-grey shadow-inner rounded-lg 
                          xxs:text-xs sm:text-xs 
                         border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none "
            />

            <input
              placeholder="Email"
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleEmailChange}
              required
              className=" w-[200px]  xxl:w-[250px]  mb-6 border text-txt-grey   md:text-s  xxl:text-base p-2  bg-grey  shadow-inner rounded-lg 
                      xxs:text-xs sm:text-xs 
                    border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none "
            />

            {/* Agree-to-terms ;) */}
            <input type="checkbox"  name="agreetoterms" id="agreetoterms"  value="1" className="hidden" tabIndex="-1" autoComplete="off"/>
          </div>

          <div className="xxs:w-full flex xxs:justify-center lg:justify-left lg:w-fit">
            <motion.div
              className=" box font-lexendbold flex justify-center items-center capitalize text-white bg-green  hover:drop-shadow-lg shadow-sm
                          box-border  tracking-wider 
                          xxs:px-5  sm:px-6  xl:px-10 
                           xxl:text-lg xl:text-md lg:text-s xxs:text-s hyphens-auto 
                          xxs:py-[4px]   sm:py-[4px] md:py-[8px]  lg:py-2 xl:py-[7px]  xxl:py-2 
                          xxs:rounded-lg sm:rounded-lg "
              whileHover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <input name="redir" type="hidden" value="index.php"></input>
              
              <button className="p-O  m-O h-fit" type="submit">
              {t("Subscribe")}
              </button>
            </motion.div>
          </div>
        </form>

        <div className="xxs:w-full lg:w-1/2 xxs:flex-col lg:flex-col border-box  
                        flex justify-between h-full items-center text-center pb-7 
                        xxs:text-sm lg:text-sm xxl:text-lg"
        >
          <div className="flex xxs:flex-col lg:flex-row w-full justify-between">
            <h6 className="font-lexendbold xxs:w-full flex xxs:justify-center lg:justify-left lg:w-fit 
            xxs:text-[17px] sm:text-[15px] xxl:text-xl  mb-3">
              LINKED.FARM
            </h6>
            <p className="font-lexend">
              BE0633967155
            </p>
          </div>
          
          <div className="flex xxs:flex-col lg:flex-row w-full xxs:pb-0 md:pb-3 justify-between font-lexend">
            <p className="">Laurence Claerhout </p>
            <p className="">+32 472 50 81 53 </p>
          </div>

          <div className="flex xxs:flex-col lg:flex-row w-full pb-3 justify-between font-lexend">
            <Link to="/contact" className="underline ">
              we@linkedfarm.eu
            </Link>
            <p>Asbeekstraat 4, 1730 Asse</p>
          </div>

          <div className="w-full  flex justify-between items-center border-box">
            <Link target="_blank" to={"https://www.facebook.com/linkedfarm/"}>
              <BsFacebook  className="min-w-[35px] min-h-[35px] h-[1.5vw] w-[1.5vw]" color="#2D604D"/>
            </Link>

            <Link target="_blank" to={"https://www.instagram.com/linked.farm/"}>
              <AiFillInstagram  className="min-w-[35px] min-h-[35px] h-[1.5vw] w-[1.5vw]" color="#2D604D"/>
            </Link>

            <Link target="_blank" to={"https://twitter.com/LinkedFarm"}>
              <AiOutlineTwitter  className="min-w-[35px] min-h-[35px] h-[1.5vw] w-[1.5vw]" color="#2D604D"/>
            </Link>

            <Link target="_blank" to={"https://www.linkedin.com/company/linked-farm/"}>
              <AiFillLinkedin  className="min-w-[35px] min-h-[35px] h-[1.5vw] w-[1.5vw]" color="#2D604D"/>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsLetter;
