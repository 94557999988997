import React, { forwardRef } from "react";

const Title2Amatic = forwardRef(({ titleText,  titleText1 }, ref) => {
  return (
    <h2 
      ref={ref}
      className="font-amatic w-full text-center text-green hyphens-none xxs:mb-5 lg:mb-16  xl:text-[60px] lg:text-[45px] md:text-[40px] s:text-[40px] sm:text-[40px] xs:text-[40px] xxs:text-[40px]"
    >
      {titleText} <br /> {titleText1}
    </h2>
  );
});

export default Title2Amatic;
