import React from 'react';

const SmallImageSquare = ({ imagePath }) => {
  return ( <div className="flex justify-center xxs:w-[80vw]  sm:w-[80vw] md:w-[30vw] xxl:w-[30vw]">
 <div className="xxs:w-[70vw] s:w-[70vw] sm:w-[240px] md:w-[200px] lg:w-[240px] xl:w-[300px] xxl:w-[350px] 
 xxs:h-[70vw] s:h-[70vw] sm:h-[240px] md:h-[200px] lg:h-[240px] xl:h-[300px] xxl:h-[350px] 
                    rounded-3xl overflow-hidden flex object-cover 
               md:mb-10 xxs:mb-0 "
    >
      <img alt="choux" src={imagePath} className="w-full object-cover h-full" />
    </div>
    </div>
  );
};

export default SmallImageSquare;
