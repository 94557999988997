import React, { useRef, useEffect, useState } from "react";
import BgImage1box from "../components/BgImage1box";
import TextRightImgLeft from "../components/TextRightImgLeft";
import TextLeftImgRight from "../components/TextLeftImgRight";
import ButtonGreen from "../components/ButtonGreen";
import Title2Amatic from "../components/Title2Amatic";
import aubergine from "../assets/Drawings/aubergine.png";
import pumkin from "../assets/Drawings/pumkin.png";
import veggies from "../assets/images/10.jpg";
import peer from "../assets/Drawings/peer.png";
import apple from "../assets/Drawings/apple.png";
import Container from "../components/Container";
import SmallImageSquare from "../components/SmallImageSquare";
import BubbleCornerLeft from "../components/BubleCornerLeft";
import KortemLogo from "../assets/logo/kortom_small.png"
import Kortom from "../assets/images/Kortem.jpg"
import ReusableList from '../components/ReusableList'
import { useTranslation } from "react-i18next";
import GoodToKnow2 from "../components/GoodToKnow2"


function WholesalerPage() {
  const { t,i18n } = useTranslation();
  const sectionRef = useRef(null);

  const [currentLanguage, setCurrentLanguage] = useState("https://wiki.linkedfarm.eu/#/page/1047/en_EN");

  

  useEffect(() => {
    const language = i18n.language;
    let x;

    if (language === "fr") {
      x = "https://wiki.linkedfarm.eu/#/page/1047/fr_FR";
    } else if (language === "nl") {
      x = "https://wiki.linkedfarm.eu/#/page/1047/nl_BE";
    } else {
      x = "https://wiki.linkedfarm.eu/#/page/1047/en_EN";
    }
    setCurrentLanguage(x);
  }, [i18n.language]);
  
  const line1 = [
    t('Farmers possibly manage assortment and price'),
    t('Hub decides on supply and margin'),
    t('Simple administration (packing slip, picking,…)'),
    t('User-friendly (labels, refunds,…)'),
    t('Online payment or by invoice'),
  "GS1 categories, INCO n° 1169/2011, Inco-terms",
  ];
  const line2 = [
    t('Professional and easy webshop'),
    t('Newsletters, Facebook, Instagram, LinkedIn'),
    t('Promotions, volume and customer-discount'),
    t('Statistics'),
    t('Adjusted assortment per customer segment'),
    t('Segment API/EDI possible'),
  ];
  const boxright1 = [
    t('Labels, empties, return logistics'),
    t('Optimal route'),
    t('ERP: orders, logistics, invoicing,...'),
    t('WMS: Inventory and purchasing management'),
    t('Transport & logistics: picking'),
    t('Pick-up points or deliveries'),
    t('Invoicing (Peppol)'),
    t('Traceability from farmer to customer'),
    t('Supply chain management'),
    t('Statistics'),
  ];
  const boxright2 = [
    t('Pricing by producer, margin by hub'),
    t('Adjustable margins per delivery method, per customer, per product'),
    t('Automatic (delivery) costs possible'),
    t('Several delivery or collection methods'),
    t('Easy link with farmers on the platform'),
    t('Easy collaboration between hubs'),

  ];
  const handleLink2Click = () => {
    const sectionRefElement = sectionRef.current;
      window.scrollTo({
        top: sectionRefElement.offsetTop,
        behavior: "smooth",
      });
  };
  return (
  
   
<div className=" flex flex-col items-center w-full">
    {/*Background*/}
    <BgImage1box 
          imagePath={veggies} 
          buttonText={t('Watch our demo')}
          text={t('This is affordable and easy via the B2B Linked.Farm module')}
          title1Text={t('products from multiple farmers?')}
          titleText={t('Want to offer your customers')}
          onClick={handleLink2Click}
          link={currentLanguage}
          target={"_blank"}
    />


    <Container>
      <Title2Amatic titleText={t('What do we offer ?')} ref={sectionRef} />
      
      <TextLeftImgRight
          titleTextAmatic={t('You get a professional ERP')}
          lines={line1}
          imagePath={pumkin}
          link={currentLanguage}
          text={t("Watch our demo")}
          target={"_blank"}
      />
     
        <TextRightImgLeft
          imagePath={peer}
          titleTextAmatic={t('You work cost efficiently')}
          lines={boxright1}
          link={"/startahub"}
          text={t("Get started")}
        />
  
        <TextLeftImgRight
          titleTextAmatic={t('You sell more')}
          lines={line2}
          imagePath={apple}
          text={t("Get started")}
          link={"/startahub"}
        />
  
        <TextRightImgLeft
          imagePath={aubergine}
          titleTextAmatic={t('You determine your own margin & supply')}
          lines={boxright2}
          link={"/startahub"}
          text={t("Get started")}
          
        />
           <GoodToKnow2/>


      <div className="flex xxs:flex-col-reverse sm:flex-col-reverse lg:flex-row justify-between items-center w-full">
        <div className="flex flex-col items-center justify-end">
        <img alt="#" src={KortemLogo} className="mb-5"/>
        <SmallImageSquare imagePath={Kortom}/>
        </div>
        <BubbleCornerLeft text={t('As a hub, the platform is a useful tool for working directly with our farmers and our restaurants and retail customers at the same time. It’s easy to operate.')}
         name={"Michael,"} name2={"Kort'om Leuven"}/> 
      </div>
     
        <Title2Amatic titleText={t("It's easy to start")}/>
    
       <ReusableList linkform={"/startahub"}  pageId="page3" />
     
        <ButtonGreen text={t('Rates / Pricing')} link={"/rates"}></ButtonGreen>
    
     
    </Container>
    </div>
  );
}
export default WholesalerPage;