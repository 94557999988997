import React from "react";
import Title3Amatic from "./Title3Amatic";
import ButtonGreen from "./ButtonGreen";

const TextLeftImgRight = ({ titleTextAmatic, target, text, lines, imagePath, link }) => {
  return (
    <div className="flex xxs:flex-col lg:flex-row items-center justify-between w-full xxs:mb-10 lg:mb-10"
    >
      <div
        className=" flex box-border   xxs:w-[80vw]  sm:w-[80vw] md:w-[40vw] xxl:w-[40vw] 
        flex-col justify-center text-center bg-light-green rounded-3xl p-5"
      >
        <Title3Amatic titleText={titleTextAmatic} />
        
        <ul className="text-green text-left pl-7 leading-relaxed  lg:tracking-wider px-4">
          {lines.map((line, index) => (
            <li className="list-disc" key={index}>
              {line}
            </li>
          ))}
        </ul>
      </div>

      <div className="flex flex-col justify-evenly h-full items-center xxs:w-[80vw]  sm:w-[80vw] md:w-[30vw] xxl:w-[30vw]">
       
          <img
            alt="schema"
            src={imagePath}
            className="w-fit xxs:h-[150px] my-5 sm:h-[150px] md:h-[180px] xl:h-[180px] xxl:h-[180px] object-contain  "
          />
       
        <div className="w-fit">
          <ButtonGreen text={text} link={link} target={target}/>
        </div>
      </div>

    </div>
  );
};

export default TextLeftImgRight;
