import React, { useState, useEffect } from "react";
import StoryText from "../components/StoryTexte";
import Title2Amatic from "../components/Title2Amatic";
import hands from "../assets/images/hands.jpg";
import people from "../assets/Drawings/people.jpg";
import ButtonGreen from "../components/ButtonGreen";
import BgImage1box from "../components/BgImage1box";
import { useTranslation } from "react-i18next";
import Container from "../components/Container";
import ReusableList from "../components/ReusableList1";
import { Link } from "react-router-dom";
import Title3Amatic from "../components/Title3Amatic";
import farm from "../assets/Drawings/farm.jpg";
import BubbleRight from "../components/BubbleRight";
import BubbleLeft from "../components/BubbleLeft";

const BecomeCooperantPage = () => {
  const { t, i18n } = useTranslation();
  const [general_assembly_report, setGeneral_assembly_report] = useState("https://www.linked.farm/downloads/_LF/presentatie.pdf");
  const [latest_annual_figures, setLatest_annual_figures] = useState("https://www.linked.farm/downloads/_LF/balans_resultatenrekening_nl.pdf");
  const [special_report, setSpecial_report] = useState("https://www.linked.farm/downloads/_LF/bijzonder_verslag.pdf");
  const [householdRules, setHouseholdRules] = useState("https://docs.google.com/document/d/1rXYn9a21kFRhBmKXsIHm-u0jb-n24jVFVea7E_zh28Y/edit");  
  
  useEffect(() => {
    // Get the current language
    const language = i18n.language;
    //let temp_houseHoldRules;

    // Determine the URL based on the language
    if (language === "fr") {
      setHouseholdRules("https://docs.google.com/document/d/1Db9lmsHhhBq7cCAI2ow2dUojK6QIVMv-PQum_VEjHNg/edit?usp=sharing");
      setLatest_annual_figures("https://www.linked.farm/downloads/_LF/balans_resultatenrekening_fr.pdf")
      // balans_resultatenrekening_fr.pdf
    } else if (language === "nl") {
      setHouseholdRules("https://docs.google.com/document/d/1Db9lmsHhhBq7cCAI2ow2dUojK6QIVMv-PQum_VEjHNg/edit?usp=sharing");
      setLatest_annual_figures("https://www.linked.farm/downloads/_LF/balans_resultatenrekening_nl.pdf")
    } else {
      setHouseholdRules("https://docs.google.com/document/d/1rXYn9a21kFRhBmKXsIHm-u0jb-n24jVFVea7E_zh28Y/edit");
      setLatest_annual_figures("https://www.linked.farm/downloads/_LF/balans_resultatenrekening_nl.pdf")
    }
    
    //setHouseholdRules(temp_houseHoldRules);
  }, [i18n.language]);

  return (
    <div className=" w-full flex flex-col items-center">
      <BgImage1box
        imagePath={hands}
        titleText={t("Do you want")}
        title2Text={t("to support")}
        title1Text={t(" FarmToFork players ?")}
        buttonText={t("Register")}
        link={"/become-cooperent-form"}
        text={t("Join the Linked.Farm family by becoming a cooperant")}
      />

      <Container>
        <Title2Amatic titleText={t("Cooperative")} />

        <StoryText
          paragraph={t("Linked.farm is a platform cooperative for Farm2Fork. Farmers are co-owners of the platform. They can sell, deliver, cooperate online themselves. They have a website under their own name if they wish.")}
        />

        <div className="flex w-full lg:flex-row lg:justify-evenly lg:items-center xxs:flex-col  xxs:items-center sm:flex-col sm:items-center xxs:mb-0 lg:mb-0 h-fit ">
          <div className=" xxs:w-full   lg:w-[470px]  xl:w-[500px] xxl:w-[600px]  flex justify-center object-cover border-box xxs:mb-10 lg:mb-0">
            <img alt="farm" src={farm} className="h-fit" />
          </div>

          <div className="h-full flex flex-col justify-between">
            <BubbleRight>
              {t(
                "What is developed for one farm is reused for another. In this way we create a new ecosystem to make family farms and small scale logistics resilient."
              )}
            </BubbleRight>

            <BubbleRight>
              {t(
                "Each participating hub is a cooperant. We also receive support from private or impact investors"
              )}{" "}
              <Link target="_blank"
                className="text-green font-lexendbold hover:text-white"
                to="https://citizenfund.coop/"
              >
                (ex: CitizenFund)
              </Link>
            </BubbleRight>
          </div>
        </div>
        <div className="xxs:mb-5 lg:mb-0">
          <ButtonGreen text={t("Find out more about us")} link={"/about"} />
        </div>
        <Title2Amatic titleText={t("Approved Cooperative")} />

        <div className="w-full flex  lg:flex-row lg:items-center lg:justify-between xxs:flex-col xxs:items-center sm:flex-col sm:items-center box-border   mb-12 xxs:mb-8 sm:mb-8  ">
          <div className=" bg-light-green rounded-3xl pl-7
                                xxs:p-5 xs:p-6 s:p-6 sm:p-6 md:p-6 lg:p-7 xl:p-8 xxl:p-9  
                                mb-8
                                xxs:w-full  sm:w-full  md:w-[400px] lg:w-[490px] xl:w-[600px] xxl:w-[800px]  "
          >
            <ul className="  pl-7 xxs:pl-2 sm:pl-2 list-disc text-green tracking-wide text-justify font-lexend leading-wider ">
              <li>{t("CV = Coöperatieve Vennootschap")}</li>

              <li>
                {t("Advice from ")}
                <Link target="_blank"
                  className="text-green font-lexendbold hover:text-white"
                  to="https://febecoop.be/"
                >
                  Febecoop
                </Link>
              </li>

              <li>
                {t("Recognition by the ")}
                <Link target="_blank"
                  className="text-green font-lexendbold hover:text-white"
                  to="https://economie.fgov.be/en/themes/enterprises/starting-business/steps-starting-business/forms-companies/cooperative-societies/approval-cooperative-societies"
                >
                  {t("National Council for Cooperatives (NRC)")}
                </Link>
              </li>
              <li>{t("Free entry and leaving for new associates")}</li>

              <li>{t("Lack of a dominant partner")}</li>

              <li>
                {t(
                  "Each partner has one vote, irrespective of the number of shares"
                )}
              </li>

              <li>{t("Absence of a speculative target")}</li>
            </ul>
          </div>

          <div
            className=" xxs:mb-8 sm:mb-8 flex flex-col
                             xxs:w-full sm:w-full md:w-[220px] lg:w-[240px]  xl:w-[280px] xxl:w-[350px] 
                             text-justify font-lexend hyphens-auto items-center "
          >
            <Title3Amatic titleText={t("Read :")} />

            <div className="flex  xxs:w-[240px] flex-col 	xxs:gap-y-5 s:gap-y-0 lg:w-[240px]  xl:w-[320px] xxl:w-[350px]  ">
              <ButtonGreen
                target="_blank"
                text={t("Report of the last General Assembly")}
                link={general_assembly_report}
              />
              <ButtonGreen
                target="_blank"
                text={t("Latest annual figures of Linked.Farm")}
                link={latest_annual_figures}
              />
              <ButtonGreen
                target="_blank"
                text={t("Special report of Linked.Farm")}
                link={special_report}
              />
              <ButtonGreen
                target="_blank"
                text={t("Household")} text2={t('rules')}
                link={householdRules}
              />
            </div>
          </div>
        </div>

        <Title2Amatic titleText={t("Become a Cooperant")} />

        <StoryText
          paragraph={t(
            "Everyone is welcome in our cooperative: our family consists of sympathizers, farmers, stores, entrepreneurs, investors, governments, etc."
          )}
        />

        <div
          className=" flex w-full  items-center  lg:flex-row lg:justify-evenly lg:items-start xxs:flex-col-reverse  xxs:items-center "
        >
          <div className="h-full flex flex-col justify-between">
            <BubbleLeft>
              {t(
                "We ask you to become a cooperant if you want to start your own hub (single farmer, hub)."
              )}
            </BubbleLeft>

            <BubbleLeft>
              {t(
                "As a customer or as a producer without a hub, you do not have to become a cooperant, unless you want to do some-thing useful with your savings."
              )}
            </BubbleLeft>
          </div>

          <div className="    xxs:w-full  sm:w-full  md:w-[400px] lg:w-[490px] xl:w-[600px] xxl:w-[800px] 
                          flex flex-col justify-center border-box  items-center
                          xxs:mb-0 lg:mb-0"
          >
            <div className="xxs:w-full  sm:w-full  md:w-[390px] lg:w-[440px] xl:w-[500px] xxl:w-[700px]
                          flex justify-center object-cover border-box
                         mb-5"
            >
              <img alt="people" src={people} className="h-fit" />
            </div>
          </div>

        </div>

        <div className="w-full h-fit flex justify-evenly xxs:flex-col lg:flex-row items-center mb-16 ">
          <div className="flex flex-col items-center">
            <p className="text-center   font-lexendbold text-green  
                          w-full mb-10
                          xxs:w-full sm:w-full md:w-[220px] lg:w-[240px]  xl:w-[280px] xxl:w-[350px] 
                            xxs:text-2xl lg:text-2xl xl:text-3xl xxl:text-[28px] 4xl:text-[30px] "
            >
              1 {t("Shareholder")}
              <br /> = <br />1 {t("Vote")}
            </p>

            <ButtonGreen
              target="_blank"
              text={t("Read Our Statutes")}
              link={
                "https://www.linked.farm/templates/0/downloads/statuten.pdf"
              }
            />
          </div>
          <div className="flex xxs:mt-10 lg:mt-0 flex-col">
            <p
              className="  leading-9 bg-light-green rounded-b-3xl rounded-r-3xl mb-10
                            xxs:w-full  sm:w-full  md:w-[400px] lg:w-[490px] xl:w-[600px] xxl:w-[800px]   
                             text-green font-lexend 
                             xxs:p-5 xs:p-6 s:p-6 sm:p-6 md:p-6 lg:p-7 xl:p-8 xxl:p-9  
                             xxs:text-justify s:text-justify  text-center  "
            >
              {t(
                "Entry is possible as a natural person (private individual), independant or as a company (legal entity)."
              )}
            </p>
            <p
              className="  leading-9 bg-light-green  rounded-b-3xl rounded-r-3xl  mb-10
                            xxs:w-full  sm:w-full  md:w-[400px] lg:w-[490px] xl:w-[600px] xxl:w-[800px]   
                             text-green font-lexend 
                             h-full
                             xxs:mb-10 lg:mb-0
                             xxs:p-5 xs:p-6 s:p-6 sm:p-6 md:p-6 lg:p-7 xl:p-8 xxl:p-9  
                             xxs:text-justify s:text-justify  text-center   "
            >
              {t("Your commitment: 2 years but for C-shares investor 4 years")}
            </p>
          </div>
        </div>

        <Title2Amatic titleText={t("Join Linked.Farm as a cooperant")} />

        <ReusableList />
      </Container>
    </div>
  );
};

export default BecomeCooperantPage;
