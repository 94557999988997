import React, { useState, useEffect, useCallback, useRef } from "react";
import FarmerReview from "./FarmerReview";
import WatchVideo from "./WatchVideo";
import ButtonGreen from "../../components/ButtonGreen";
import BgImage3boxesMain from "../../components/BgImage3boxesMain";
import salad from "../../assets/images/sla.jpg";
import artichaud from "../../assets/Drawings/artichaud.png";
import panier from "../../assets/Drawings/panier1.png";
import farmer from "../../assets/Drawings/farmer.png";
import { useTranslation } from "react-i18next";
import Container from "../../components/Container";
import Title2Amatic from "../../components/Title2Amatic";
import Title3Amatic from "../../components/Title3Amatic";
import img4 from "../../assets/images/webshop-mobile.png";
import img from "../../assets/images/webshop-desktop.png";
import img1 from "../../assets/images/webshop-lg-screen.png";
import img2 from "../../assets/images/webshop-mob-2.png";
import knol from "../../assets/Drawings/knol.svg";
import champi from "../../assets/Drawings/champignons.png";
import bokalen from "../../assets/Drawings/bokalen.png";
import Intro from "./Intro";
import planche from "../../assets/Drawings/snijplank2.png";
import truck from "../../assets/images/truck.png";
import basket from "../../assets/images/people-roof-solid.png";

const HomePage = () => {
  const images = [img1, img2, img, img4];
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState("https://wiki.linkedfarm.eu/#/page/1047/en_EN");
  const slideInterval = useRef(null);

  const startSlideShow = useCallback(() => {
    if (slideInterval.current) clearInterval(slideInterval.current);
    slideInterval.current = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Slide changes every 2 seconds
  }, [images.length]);

  const pauseAndResume = useCallback(() => {
    if (slideInterval.current) clearInterval(slideInterval.current);

    setTimeout(() => {
      startSlideShow();
    }, 3000); // 3 seconds pause before resuming
  }, [startSlideShow]);

  const goToNextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
    pauseAndResume(); // after changing slide, pause and then resume
  }, [images.length, pauseAndResume]);

  const goToPrevSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
    pauseAndResume(); // after changing slide, pause and then resume
  }, [images.length, pauseAndResume]);

  useEffect(() => {
    startSlideShow(); // Start the slideshow when the component mounts

    return () => {
      clearInterval(slideInterval.current); // Cleanup the interval on unmount
    };
  }, [startSlideShow]);






  useEffect(() => {
    const language = i18n.language;
    let x;

    if (language === "fr") {
      x = "https://wiki.linkedfarm.eu/#/page/1047/fr_FR";
    } else if (language === "nl") {
      x = "https://wiki.linkedfarm.eu/#/page/1047/nl_BE";
    } else {
      x = "https://wiki.linkedfarm.eu/#/page/1047/en_EN";
    }
    setCurrentLanguage(x);
  }, [i18n.language]);

  return (
    <div className="h-full w-full flex flex-col items-center  ">
      <BgImage3boxesMain
        imagePath={salad}
        link1={"/farmer"}
        link2={"/shop"}
        link4={"/restaurant"}
        link3={"/wholesaler"}
        text1={t("I am a producer")}
        text2={t("I am a shop")}
        text4={t("I am a restaurant")}
        text3={t("I am a wholesaler")}
        buttonText1={t("Sell online at my price")}
        buttonText2={t("Order & sell products")}
        buttonText4={t("Order from farmers")}
        buttonText3={t("Simplify your work")}
        titleText={t("Your digital FarmToFork platform")}
        title1Text={t("easy & affordable")}
        path1={farmer}
        path2={panier}
        path3={artichaud}
        path4={planche}
        para1={t("My webshop & baskets")}
        para2={t("Farm2Fork")}
        para3={t("with farmers & clients")}
      />

      <Container>
        <Intro />

        <div className="items-center flex flex-col w-full ">
          <div className="flex xxs:flex-col lg:flex-row w-full items-center justify-between xxs:mb-0 lg:mb-16 ">
            <div className=" items-center flex flex-col lg:w-[40vw] xxs:w-full xxs:mb-10 lg:mb-16  px-5">
              <Title2Amatic titleText={t("Pickup points or Delivery")} />
              <div className="flex mb-10 justify-evenly w-full">
                <div className="bg-light-green rounded-xl xxs:p-6 lg:p-10">
                  <img
                    alt="basket"
                    src={basket}
                    className="xxs:w-[50px] lg:w-[80px]"
                  />
                </div>
                <div className="bg-light-green rounded-xl xxs:p-6 lg:p-10">
                  <img
                    alt="truck"
                    src={truck}
                    className="xxs:w-[50px] lg:w-[80px]"
                  />
                </div>
              </div>
              <p className="font-lexend text-center mb-1 text-black">
                {t("On your own terms.")}
              </p>
              <p className="font-lexendbold mb-1  text-center">
                {t("Save up to")} 90% {t("on administration costs.")}
              </p>
              <p className="font-lexend text-center">
                {t(
                  "Sell alone or as a hub, the beating heart of a local network connecting farms."
                )}
              </p>
            </div>

            <div
              className="xxs:mb-10 lg:mb-0 bg-light-green xxs:py-5 xs:py-0
                            xxs:w-[80vw]  md:w-[60vw]  lg:w-[40vw] xxl:w-[40vw] 
                            xxs:h-fit xs:h-[80vw] md:h-[60vw] lg:h-[40vw] xxl:h-[40vw] 
                            box-border  s:rounded-2xl  xxs:rounded-xl 
                            flex justify-center items-center flex-col relative "
            >
              {images.map((image, index) => (
                <div
                  key={index}
                  className={`w-full ${
                    index === currentIndex ? "block" : "hidden"
                  } xxs:bg-transparent sm:bg-transparent flex flex-col items-center rounded-3xl transition  duration-500`}
                >
                  <div className="flex ">
                    {index === 0 && (
                      <Title3Amatic
                        titleText={t("Manage")}
                        titleText2={t("your own Web-shop")}
                      />
                    )}

                    {index === 1 && (
                      <Title3Amatic
                        titleText={t("Choose your margin,")}
                        titleText2={t("use empties & manage returns")}
                      />
                    )}

                    {index === 2 && (
                      <Title3Amatic
                        titleText={t("Easy")}
                        titleText2={t("order managment")}
                      />
                    )}

                    {index === 3 && (
                      <Title3Amatic
                        titleText={t("Optimal")}
                        titleText2={t("logistics & administration")}
                      />
                    )}
                  </div>
                  <div className="relative w-full flex justify-center">
                    <button
                      className="absolute top-1/2 xxs:left-5 s:left-10 text-green sm:text-[30px] xxs:text-[20px]  tracking-wider transform -translate-y-1/2 bg-transparent  hover:text-white focus:outline-none"
                      onClick={goToPrevSlide}
                    >
                      {"<<"}
                    </button>

                    <button
                      className="absolute top-1/2 xxs:right-5 s:right-10 text-green sm:text-[30px] xxs:text-[20px] tracking-wider  transform -translate-y-1/2 bg-transparent  hover:text-white focus:outline-none"
                      onClick={goToNextSlide}
                    >
                      {">>"}
                    </button>

                    <img
                      className=" object-cover xxs:h-[60px] xs:h-[80px] s:h-[140px] sm:h-[190px] lg:h-[120px] xl:h-[190px] xxl:h-[200px] 
                                xxs:mb-5  lg:mb-10 xl:mb-16"
                      src={image}
                      alt={`Slide ${index + 1}`}
                    />
                  </div>
                </div>
              ))}
              <div className="xxs:hidden s:block">
                <ButtonGreen
                  target="_blank"
                  text={t("Watch our demo")}
                  link={currentLanguage}
                />
              </div>
            </div>
            <div className="xxs:block  xxs:mb-10 lg:mb-0 s:hidden">
              <ButtonGreen
                target="_blank"
                text={t("Watch our demo")}
                link={currentLanguage}
              />
            </div>
          </div>
        </div>
        <FarmerReview />
        <div className="flex flex-col w-full items-center xxs:mb-5 lg:mb-16">
          <Title2Amatic titleText={t("Growing Responsibly")} />

          <div className="grid font-lexend xxs:grid-cols-1  xxs:w-[80vw] md:w-[60vw] lg:w-full lg:grid-cols-3 lg:grid-rows-2 xxs:gap-8 lg:gap-4   text-green sm:items-center">
            <div className="bg-light-green p-4 flex flex-col rounded-lg text-center">
              <Title3Amatic titleText={t("Healthy")} />
              <p>
                {t(
                  "We want to offer an alternative to the current food system. Healthy food should be the norm."
                )}
              </p>
            </div>

            <div className=" rounded-lg mx-auto">
              <img
                className=" lg:h-[15vw] xxs:h-[22vw] "
                src={knol}
                alt="knol"
              />
            </div>

            <div className="bg-light-green p-4 flex flex-col rounded-xl text-center">
              <Title3Amatic titleText={t("Sustainable")} />
              <p>
                {t(
                  "FarmtoFork brings farmers and eaters closer to each other. Less food mileage and less packaging."
                )}
              </p>
            </div>

            <div className=" rounded-xl xxs:my-5 sm:my-5 xxs:px-5 sm:px-5 items-center flex m-auto">
              <img
                className=" lg:h-[8vw] xxs:h-[11vw]  object-contain"
                src={champi}
                alt="champignons"
              />
            </div>

            <div className="bg-light-green p-4 flex flex-col items-center text-center rounded-xl">
              <Title3Amatic titleText={t("Fair")} />
              <p>
                {t(
                  "A farmer payed honestly, produces more sustainably and transparently. Farmers are co-owners of the platform."
                )}
              </p>
            </div>

            <div className=" rounded-lg xxs:my-5 sm:my-5 justify-center flex">
              <img
                className=" lg:h-[10vw] xxs:h-[15vw] object-contain"
                src={bokalen}
                alt="bokalen"
              />
            </div>
          </div>
        </div>
        <WatchVideo />
        <ButtonGreen text={t("Contact us")} link={"/contact"} />
      </Container>
    </div>
  );
};

export default HomePage;
