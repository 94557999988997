import React from "react";
import Title1Amatic from "./Title1Amatic";
import SmallHeader from "./SmallHeader3Main";
import AnimationBottom from "./AnimationBottom";

const BgImage3boxesMain = ({ imagePath, titleText, title1Text, title2Text, link3, link1, link2,link4, text3,text4, text1, text2, buttonText1, buttonText2, buttonText3,buttonText4, path1, path2, path3,path4, par1, para1, para2, para3  }) => {
  const styleContainer = {
    backgroundImage: `url(${imagePath})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };

  return (
    
  <div style={styleContainer} className=" backdrop-blur-3xl opacity-90 box xxs:mb-10 lg:mb-16 
                                          py-10 max-w-[2000px]
                                        h-fit
                                          w-full flex flex-col justify-between items-center "
                                          initial={{ opacity: 0 }}
                                          animate={{ opacity: 1 }}
    >
      <AnimationBottom>
   <Title1Amatic titleText={titleText} title1Text={title1Text} title2Text={title2Text}  />
   </AnimationBottom>
  <SmallHeader  buttonText1={buttonText1} buttonText4={buttonText4} buttonText2={buttonText2} buttonText3={buttonText3}  link3={link3} link4={link4}  link1={link1} link2={link2} text1={text1} text2={text2} text3={text3} text4={text4} path1={path1} path2={path2} path3={path3} par1={par1} para1={para1} path4={path4} para2={para2} para3={para3}/>
  </div>

  );
};

export default BgImage3boxesMain;
