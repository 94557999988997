import Title3Amatic from "./Title3Amatic"
import { useTranslation } from "react-i18next";
const Goodtoknow =()=>{
    const { t } = useTranslation();

    return(
<div className="w-full p-5  bg-light-green rounded-xl mb-10 ">
<div className="flex justify-center">
  <Title3Amatic titleText={t("Good to know !")} />
</div>

<div className="max-w-4/5 mx-auto text-justify my-5 text-green ">
  <p className="">
    {t(
      "If you enter our cooperative, you get one voting right and you become a co-owner of the platform."
    )}
  </p>

  <p className="my-5">
    {t(
      "You can enter our cooperative as a legal entity or as a natural person. Applications from self-employed people in main or complementary occupation should be done as a natural person."
    )}
    <br />{" "}
  </p>

  <p>
    {" "}
    {t(
      "If you enter as a legal entity, the voting right is directly linked to the legal entity that can be represented by another employee. If you set up your company together with one or more partners and want to see all the names listed in the Associates list, you can indicate this below. However, the voting rights remain linked to one person who represents the legal personality at that time."
    )}
    <br />{" "}
  </p>

  <p className="my-5">
    {t("The requested information has been determined in our")}{" "}
    <a
      href="https://www.linked.farm/templates/0/downloads/statuten.pdf"
      target="_blank"
      className="underline"
      rel="noreferrer"
    >
      {t("statutes.")}
    </a>{" "}
    {t(
      "We ensure that we will not use your data for purposes other than those linked to the cooperative. Your data is safe with us. After completing this form, the approval of your application by the Board of directors follows. You will then be sent the account number on  which you can deposit the amount for the shares you subscribed to."
    )}
  </p>
</div>
</div>
    )
}
export default Goodtoknow