import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { calculateDistance } from "./CustomMarkerCluster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

const ListContainer = ({
  apiData,
  selectedCoordinates,
  selectedPostalCode,
  filterType,
}) => {
  const [filteredMarkers, setFilteredMarkers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const totalPages = Math.ceil(filteredMarkers.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const { t } = useTranslation();

  useEffect(() => {
    const newFilteredMarkers = apiData
      .map((marker) => {
        const distance = calculateDistance(selectedCoordinates, {
          lat: marker.lat,
          lng: marker.lng,
        });
        return {
          ...marker,
          distance: distance,
        };
      })
      .filter((marker) => marker.distance < 20)
      .sort((a, b) => a.distance - b.distance);

    setFilteredMarkers(newFilteredMarkers);
    setCurrentPage(1);
  }, [apiData, selectedCoordinates]);

  const currentItems = filteredMarkers.slice(startIndex, endIndex);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedCoordinates]);

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  return (
    <div
      className="flex flex-col  justify-between xxs:h-fit md:h-[500px] sm:pt-20 xxs:pt-28
     xxs:mx-6 md:mx-20"
    >
      <table className=" hover text-green text-sm text-left ">
        <tbody>
          {currentItems.map(
            ({ name, type, address, image, website, distance }, index) => (
              <tr
                key={index}
                className="bg-white border-b justify-between lg:flex-row w-full"
              >
                <th
                  scope="row"
                  className=" w-1/10 px-1 py-4 whitespace-nowrap hyphens-auto xxs:text-xs md:text-s"
                >
                  <div className="w-auto flex justify-center cursor-default h-[40px]">
                    <img
                      src={image}
                      alt="icone"
                      className="object-contain rounded-2xl h-[40px]"
                    />
                  </div>
                </th>
                <td className="px-1 w-1/5 hyphens-auto cursor-default xxs:text-xs md:text-s ">
                  <p className="text-center font-lexendbold leading-normal">
                    {name}
                  </p>
                </td>

                
                <td className="px-1 hyphens-auto w-1/5 cursor-default uppercase font-lexendbold text-center xxs:text-xs md:text-s py-4">
                {filterType !== "deliveries" && type}  {filterType === "deliveries" && "deliveries on "+ selectedPostalCode }
                </td>

                <td className="px-1 w-1/5 hyphens-auto cursor-default xxs:text-xs md:text-s ">
                  {filterType !== "deliveries" && address}
                </td>

                <td className="px-1 w-1/10 text-center cursor-default hyphens-auto xxs:text-xs md:text-s ">
                  {filterType !== "deliveries" &&
                    (distance < 1 && distance > 0
                      ? `${(distance * 1000).toFixed(0)} m`
                      : distance.toFixed(2) !== "0.00"
                      ? `${distance.toFixed(1)} km`
                      : "")}
                </td>

                <td className="px-1 w-1/5 hyphens-auto font-lexendbold xxs:text-xs md:text-s ">
                  <Link
                    to={`https://${website}`}
                    className="text-green flex items-center h-fit"
                    target="blank"
                  >
                    {t("Website")}
                  </Link>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>

      <div className="flex justify-between mt-4">
        <button
          className="text-green font-medium"
          disabled={currentPage === 1}
          onClick={handlePreviousPage}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <div className="text-green px-5 font-lexend">
          {currentPage} of {totalPages}
        </div>
        <button
          className=" text-green font-medium"
          disabled={currentPage === totalPages}
          onClick={handleNextPage}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
};

export default ListContainer;
