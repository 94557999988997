import React from 'react';

const Title3Amatic= ({titleText, titleText2}) => {
  return (
    <h4 className='font-amatic text-green text-center leading-wider
                   xl:text-[40px]  lg:text-[35px] sm:text-[35px]  s:text-[30px]  xs:text-[25px] xxs:text-[20px]
                 xxs:mb-5 sm:mb-7 '>
        {titleText} <br/> {titleText2}
    </h4>
    );
};

export default Title3Amatic;