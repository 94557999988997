import React, { useRef} from "react";
import BgImage2boxes from "../components/BgImage1box";
import veggies  from "../assets/images/monika-grabkowska-neu4T59mTcY-unsplash.jpg";
import { useTranslation } from "react-i18next";
import SmallImageSquare from "../components/SmallImageSquare";
import BubbleCornerLeft from "../components/BubleCornerLeft";
import vanier from "../assets/images/vanier.jpg";
import logoVanier from "../assets/images/gust.jpg";
import Container from "../components/Container";

function RestaurantPage() {
  const sectionRef = useRef(null);
  const { t } = useTranslation();

  const handleLink2Click = () => {
    const sectionRefElement = sectionRef.current;
    window.scrollTo({ top: sectionRefElement.offsetTop, behavior: "smooth" });
  };

  return (
    <div className="flex-col flex w-full items-center">
      <BgImage2boxes
        imagePath={veggies}
        titleText={t("Indulge in Authentic Flavors:")}
        title1Text={t("Straight From Local Farms to Your Plate")}
        buttonText={t("Find my hub")}
        text={t("Order my products from an existing hub")}
        link={"/linkedfarmfamily"}
        onClick={handleLink2Click}
      /> 
      <Container>
        <div className="flex xxs:flex-col-reverse sm:flex-col-reverse md:flex-row justify-between items-center w-full">
          <div className="flex flex-col items-center justify-end">
            <img alt="#" src={logoVanier} className="mb-5 w-[130px]" />
            <SmallImageSquare imagePath={vanier} />
          </div>
          <BubbleCornerLeft text={t("By ordering from Vanier, we get ultra-fresh products on our plates, straight from the field, at a fair price, produced by the most fantastic people.")}
            name="Evi De Cock"
            name2={t("Founder and creative at Gust & Modest")}
          />
        </div>
        </Container>
    </div>
  );
}
export default RestaurantPage;
