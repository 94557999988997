import img2 from "../../assets/images/Seizoenssmaak_serre_croped.jpg";
import Title2Amatic from "../../components/Title2Amatic";
import BigImagesquare from "../../components/BigImagesquare";
import { useTranslation } from "react-i18next";
import A from "../../assets/logo/B.png";
import ButtonExpand from "../../components/ButtonExpand";

function FarmerReview() {
  const { t } = useTranslation();

  return (
    <div
      className="  items-center flex xxs:flex-col-reverse lg:flex-row
                   xxs:mb-0 lg:mb-16 justify-between w-full"
    >
      <BigImagesquare imagePath={img2}>
        <div
          className="bg-white xxs:hidden s:flex flex-col drop-shadow-sm 
            backdrop-blur-s opacity-95 
            xxs:mr-3 mr:mr-3 lg:mr-5 
            xxs:p-3   lg:p-4 
            xxs:w-[40vw] md:w-[30vw] lg:w-[20vw]  xxl:w-[17vw] 
             border border-green
         xxs:rounded-br-[50px] xxs:rounded-t-[50px] s:rounded-br-[50px] s:rounded-t-[50px] sm:ounded-t-[100px] rounded-br-[100px]"
        >
          <img
            alt="#"
            className="2xl:w-[60px] xxs:w-[40px] lg:w-[50px] xxs:mt-3  lg:mt-5 xxs:mb-3 lg:mb-5"
            src={A}
          />

          <p className=" mb-2 italic xxs:text-s s:text-s sm:text-s lg:text-s xl:text-base font-lexend   mr-5 text-justify hyphens-auto   w-full ">
            {t(
              "As a farmer, I can adjust quantities and prices at any time during my harvest. All customers and hubs immediately buy under the new conditions."
            )}
          </p>

          <p className=" mb-3 xxs:text-s  lg:text-s xl:text-base font-lexendbold">
            Matthias, Seizoensmaak
          </p>
        </div>
      </BigImagesquare>

      <div className="flex flex-col mx-5 items-center  lg:w-[40vw] w-full">
        <Title2Amatic titleText={t("We help making producers successful")} />
        <ButtonExpand />
      </div>
    </div>
  );
}

export default FarmerReview;
