import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 300, damping: 24 },
  },
  closed: {
    opacity: 0,
    y: 20,
    transition: { duration: 0 },
  },
};

export default function JoinUs({ onLinkClick }) {
  const [isOpen, setIsOpen] = useState(false);
  const joinUsRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    function handleClickOutside(event) {
      if (joinUsRef.current && !joinUsRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLinkClick = () => {
    setIsOpen(false);
    if (onLinkClick) {
      onLinkClick(); 
  };}

  return (
    <motion.nav
      ref={joinUsRef}
      initial={false}
      animate={isOpen ? "open" : "closed"}
      className="flex xxs:w-full w-fit justify-center text-green font-lexendbold "
    >
      <motion.button
        className="arrow  lg:text-green m-0 p-O hover:lg:text-black hover:xxs:text-light-green xxs:text-white md:text-white uppercase"
        whileTap={{ scale: 0.97 }}
        onClick={() => setIsOpen(!isOpen)}
      >
        {t("Join us")}
        <motion.div
          variants={{ open: { rotate: 180 }, closed: { rotate: 0 } }}
          transition={{ duration: 0.1 }}
        >
          <ChevronDownIcon className="w-4  h-6 stroke-4" />
        </motion.div>
      </motion.button>

      <motion.ul 
        className="languages absolute 
                    mt-[30px]  xxl:mr-[7vw] xl:mr-[7vw]  lg:mr-[9vw] md:mr-[13vw]  sm:mr-[7vw] s:mr-[13vw] xxs:mr-[15vw]
                    flex w-fit
                    rounded-l-3xl  rounded-br-3xl  z-10  text-right  "
        variants={{
          open: {
            boxShadow: "1px 1px 1px #2D604D",
            backgroundColor: "white",
            color: "#2D604D",
            transition: {
              type: "spring",
              bounce: 0,
              duration: 0.1,
              delayChildren: 0.1,
              staggerChildren: 0.02,
            },
          },
          closed: {
            backgroundColor:"transparent",
            boxShadow: "none",
            transition: {
              duration: 0,
              boxShadow: "none",
             
            },
          },
        }}
        style={{ pointerEvents: isOpen ? "auto" : "none" }}
      >
        <motion.li
          className="list1 text-right text-xs tracking-wider  whitespace-nowrap"
          variants={itemVariants}
        >
          <Link to="/becomeacooperant" className="hover:text-black text-green flex justify-start" 
          onClick={handleLinkClick}>
            {t("As a cooperant")}
          </Link>
        </motion.li>

        <motion.li
          className="list1 text-right text-xs tracking-wider whitespace-nowrap"
          variants={itemVariants}
        >
          <Link className="hover:text-black text-green flex justify-start" 
           to="/farmer" onClick={handleLinkClick}>
            {t("As a single producer")}
          </Link>
        </motion.li>

        <motion.li
          className="list1 text-right  text-xs tracking-wider whitespace-nowrap"
          variants={itemVariants}
        >
          <Link to="/shop" 
          className="hover:text-black text-green flex justify-start" 
          onClick={handleLinkClick}>
            {t("As a shop/hub")}
          </Link>
        </motion.li>
        <motion.li
          className="list1 text-right  text-xs tracking-wider whitespace-nowrap"
          variants={itemVariants}
        >
          <Link to="/restaurant" 
         className="hover:text-black text-green flex justify-start" 
          onClick={handleLinkClick}>
            {t("As a restaurant")}
          </Link>
        </motion.li>

        <motion.li
          className="list1 text-right  text-xs tracking-wider whitespace-nowrap"
          variants={itemVariants}
        >
          <Link to="/wholesaler" 
         className="hover:text-black text-green flex justify-start" 
          onClick={handleLinkClick}>
            {t("As a wholesaler")}
          </Link>
        </motion.li>
      </motion.ul>
    </motion.nav>
  );
}
