import axios from "axios";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import i18n from "../translationServices/i18n";
import CustomToast from "./CustomToast";

const SubmitForm = ({ payload, children, source }) => {
  const MIN_WAIT_TIME_MS = 3000;
  const { t } = useTranslation();
  const { handleSubmit } = useForm();
  const [dataArray, setDataArray] = useState([]);
  const [pageLoadTimestamp, setPageLoadTimestamp] = useState(0);
  const url = "https://www.linked.farm/api/v2/linkedfarm/view/linkedfarm.php?api=wefg496ad45afsjeg4s633924ujimshd4g56a4f13aghg56f4sa";
  const [isChecked, setIsChecked] = useState(false);
  const [toastConfig, setToastConfig] = useState({ show: false, message: '', type: 'success' });

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    setPageLoadTimestamp(Date.now());
  }, []);
  if (isChecked === true) {
    return;
  }

  const APICall = async (formData) => {
    console.log(formData);
    try {
        const response = await axios.post(url, formData);

        if (response.status === 200) {
            setToastConfig({
                show: true,
                message: "Thank you! Your form has been submitted successfully. You will soon receive a confirmation email.",
                type: "success"
            });
            // navigate("/");
        } else {
            setToastConfig({
                show: true,
                message: "Form submission failed.",
                type: "error"
            });
        }
    } catch (error) {
        // Modifié pour utiliser le CustomToast
        setToastConfig({
            show: true,
            message: "An error occurred during form submission: " + error,
            type: "error"
        });
    }
};


  const onSubmit = (data) => {
    const elapsedTime = Date.now() - pageLoadTimestamp;

    if (elapsedTime < MIN_WAIT_TIME_MS) {
      return;
    }

    let mappedLanguage = i18n.language;
    switch (i18n.language) {
      case "en":
        mappedLanguage = "en_US";
        break;
      case "nl":
        mappedLanguage = "nl_BE";
        break;
      case "fr":
        mappedLanguage = "fr_FR";
        break;
      default:
        break;
    }

    const formData = {
      language: mappedLanguage,
      source: source,
      data: payload,
    };

    const jsonData = JSON.stringify(formData);
    setDataArray([...dataArray, data]);
    APICall(jsonData);
  };

  return (
    <>
    {toastConfig.show && <CustomToast message={toastConfig.message} type={toastConfig.type} onClose={() => setToastConfig(prev => ({ ...prev, show: false }))} />}
    <form
      className="flex flex-col xxs:w-[70vw] md:w-1/2  xxl:text-xl xl:text-lg lg:text-base xxs:text-sm "
      onSubmit={handleSubmit(onSubmit)}
    >
      {children}
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
        className=" hidden"
        name="agreetoterms"
        id="agreetoterms"
        tabIndex="-1"
        autoComplete="off"
      />
      <div className="w-full  flex justify-center items-center">
        <input name="redir" type="hidden" value="/index.php"></input>
        <button
          className="bg-green text-white rounded-lg py-2  justify-center px-4 mt-4 focus:outline-none focus:bg-light-green hover:bg-light-green mb-10"
          type="submit"
        >
          {t("Send the form")}
        </button>
      </div>
    </form>
    </>
  );
};

export default SubmitForm;
