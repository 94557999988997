import React from "react";
import Box from "./Box";

const SmallHeader3 = React.forwardRef(
  (
    { onClick, buttonText1, text1, buttonText2, text2, buttonText3, text3, link1, link3 },
    ref
  ) => {
    return (
      <div
        ref={ref}
        className="flex flex-col items-center w-full xxs:px-[10vw] s:px-[10vw] sm:px-10 md:px-10 lg:px-20 xl:px-28 xxl:px-28  xxl:max-w-[2000px]"
      >
        <div className="flex justify-between items-center w-full lg:flex-row  xxs:flex-col xxs:items-center box-border s:h-[730px] xxs:h-[730px] lg:h-[240px] xl:h-[300px] xxl:h-[350px]">
          <Box buttonText={buttonText1} link={link1} text={text1} />
          <Box buttonText={buttonText2} link={null} text={text2} onClick={onClick} />
          <Box buttonText={buttonText3} link={link3} text={text3} />
        </div>
      </div>
    );
  }
);

export default SmallHeader3;

