import React, { useState, useEffect, useRef, useCallback } from "react";
import CustomMap from "./CustomMap";
import CustomMarkerCluster from "./CustomMarkerCluster";
import CustomInfoWindow from "./CustomInfoWindow";
import ListContainer from "./ListContainer";
import Sidebar from "./SideBar";
import Loading from "./Loading";
import MapListToggle from "./MapListToggle";
import Filters from "./Filters";
import { useTranslation } from "react-i18next";

const Mapelements = () => {
  const { t } = useTranslation();
  const [zoomLevel, setZoomLevel] = useState(10);
  const [filterData, setFilterData] = useState([]);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [currentView, setCurrentView] = useState("map");
  const [mapCenter, setMapCenter] = useState({
    lat: 50.844257,
    lng: 4.351038,
  });
  const mapRef = useRef(null);
  const [filterOption, setFilterOption] = useState("all");
  const [isOpen, setIsOpen] = useState(false);
  const filterRef = useRef(null);
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
    map.addListener("zoom_changed", () => {
      setZoomLevel(map.getZoom());
    });
  }, []);
  const [isB2BChecked, setIsB2BChecked] = useState(true);
  const [isB2CChecked, setIsB2CChecked] = useState(true);
  const [currentUserPostalCode, setCurrentUserPostalCode] = useState(null);

  const infoWindowRef = useRef(null);

  useEffect(() => {
    mapRef.current?.panTo(mapCenter);
  }, [mapCenter]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        infoWindowRef.current &&
        !infoWindowRef.current.contains(event.target) &&
        selectedMarker
      ) {
        setSelectedMarker(null);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedMarker]);

  const fetchPostalCodeFromCoords = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      );
      const data = await response.json();
     
      if (data && data.results && data.results[0]) {
        const postalCodeObj = data.results[0].address_components.find(
          (component) => component.types.includes("postal_code")
        );
        if (postalCodeObj) {
          setCurrentUserPostalCode(postalCodeObj.long_name);
        } else {
          console.error("Postal code not found in the response");
        }
      } else {
        console.error("No results returned from the geocoding API");
      }
    } catch (error) {
      console.error("Error fetching postal code:", error);
    }
  };

  const handlePlaceChanged = (place) => {
    setIsLoading(true);
    if (place && place.geometry && place.geometry.location) {
      const address = place.formatted_address;
      setSelectedAddress(address);

      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();

      setMapCenter({ lat, lng });
      setSelectedCoordinates({ lat, lng });
      mapRef.current?.panTo({ lat, lng });

      // Condition pour vérifier que l'option de filtrage est "deliveries"
      if (filterOption === "deliveries") {
        fetchPostalCodeFromCoords(lat, lng);
      }
    } else {
      alert("Invalid place data:", place);
    }
    setIsLoading(false);
  };

  const handleFiltering = useCallback(
    async (filterType) => {
      setIsLoading(true);

      let filteredLocations = apiData.filter((eachLocation) => {
        const isTypeMatch =
          filterType === "all" || filterType === eachLocation.type;
        const matchesB2B = isB2BChecked && eachLocation.b2b === 1;
        const matchesB2C = isB2CChecked && eachLocation.b2c === 1;
        const hasPostalCodes =
          eachLocation.deliveryPostalCodes &&
          eachLocation.deliveryPostalCodes.length > 0;

        if (filterType === "deliveries" && eachLocation.type === "hub") {
          return (
            hasPostalCodes &&
            eachLocation.deliveryPostalCodes.includes(currentUserPostalCode)
          );
        } else {
          return isTypeMatch && (matchesB2B || matchesB2C);
        }
      });

      setFilterData(filteredLocations);
      
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Attendre 2 secondes
      setIsLoading(false);
    },
    [apiData, isB2BChecked, isB2CChecked, currentUserPostalCode]
  );

  useEffect(() => {
    handleFiltering(filterOption);
  }, [isB2BChecked, isB2CChecked, filterOption, handleFiltering]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://www.linked.farm/api/v2/linkedfarm/view/linkedfarm.php?data=hubs_v2&api=wefg496ad45afsjeg4s633924ujimshd4g56a4f13aghg56f4sa&search="
        );
        const responseData = await response.json();
        setApiData(responseData.locations);
        const hubs = responseData.locations.filter(
          (location) => location.type === "hub"
        );
        setFilterData(hubs);
        setIsLoading(false);
       
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="flex justify-between w-full mb-10 max-w-[2000px] ">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex justify-between w-full xxs:flex-col lg:flex-row ">
          <Sidebar
            onPlaceChanged={handlePlaceChanged}
            filterOption={filterOption}
          />
          <div className="flex flex-col xxs:w-full relative sm:w-full lg:w-3/4">
            <MapListToggle
              currentView={currentView}
              setCurrentView={setCurrentView}
              filterOption={filterOption}
            />

            <Filters
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              handleFiltering={() => handleFiltering(filterOption)}
              filterRef={filterRef}
              isB2BChecked={isB2BChecked}
              isB2CChecked={isB2CChecked}
              setIsB2BChecked={setIsB2BChecked}
              setIsB2CChecked={setIsB2CChecked}
              setFilterOption={setFilterOption}
            />

            {currentView === "map" && filterOption !== "deliveries" ? (
              <CustomMap
                center={mapCenter}
                onLoad={onMapLoad}
                selectedAddress={selectedAddress}
              >
                {filterData && (
                  <CustomMarkerCluster
                    options={{
                      enableRetinaIcons: false,
                    }}
                    markers={filterData}
                    setSelectedMarker={setSelectedMarker}
                    setMapCenter={setMapCenter}
                    zoomLevel={zoomLevel}
                  />
                )}

                {selectedMarker && (
                  <CustomInfoWindow
                    position={{
                      lat: selectedMarker.lat,
                      lng: selectedMarker.lng,
                    }}
                    website={selectedMarker.website}
                    type={selectedMarker.type}
                    address={selectedMarker.address}
                    image={selectedMarker.image}
                    name={selectedMarker.name}
                    ref={infoWindowRef}
                  />
                )}
              </CustomMap>
            ) : (
              <>
                {currentUserPostalCode === null &&
                filterOption === "deliveries" ? (
                  <p className="w-full h-[500px] flex justify-center items-center font-amatic text-green text-center xxs:leading-tight s:leading-normal
                  xl:text-[40px]
                 xxl:text-[45px] lg:text-[35px] md:text-[30px] s:text-[30px]   xxs:text-[30px]">
                   {t("Please insert a location")} 
                  </p>
                ) : filterData.length === 0 && filterOption === "deliveries" ? (
                  <p className="w-full h-[500px] flex justify-center items-center font-amatic text-green text-center xxs:leading-tight s:leading-normal
                    xl:text-[40px]
                   xxl:text-[45px] lg:text-[35px] md:text-[30px] s:text-[30px]   xxs:text-[30px]">
                    {t("Sorry, no deliveries with your zipcode")}
                  </p>
                ) : (
                  <ListContainer
                    apiData={filterData}
                    selectedCoordinates={selectedCoordinates}
                    filterType={filterOption}
                    selectedPostalCode={currentUserPostalCode}
                  />
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Mapelements;
