import Title2Amatic from "./Title2Amatic"
import { useTranslation } from "react-i18next";

const Typeofshares =()=>{
    const { t } = useTranslation();

    return(
        <div className="xxs:w-full md:w-1/2 font-lexend">
          <div className="text-center">
            <Title2Amatic titleText={t("Types of Shares")} />
          </div>

          <ul className="max-w-4/5 mx-auto my-5 tracking-wider list-disc">
            <li className="my-5 text-justify">
              <span className="font-lexendbold"> {t("B-Shares")}</span>
              <br />
              {t(
                "(nominal value of 250 EUR, max. 20 shares): these shares can only be registered by FarmToFork actors using the cooperative's digital Services (website, HUB,...)."
              )}
            </li>
            <li className="my-5 text-justify">
              <span className="font-lexendbold"> {t("C-Shares")}</span>
              <br />
              {t(
                "(nominal value of 250 EUR, Min. 21 shares pp): These shares can only be registered by investors who endorse the company's objectives."
              )}
            </li>
            <li className="my-5 text-justify">
              <span className="font-lexendbold"> {t("D-Shares")}</span>
              <br />
              {t(
                "(nominal value of 50 EUR, max 100 shares pp): These shares can only be registered by sympathizers who endorse the company's objectives."
              )}
            </li>
          </ul>
        </div>

    )
}
export default Typeofshares