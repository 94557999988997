import React, { useState } from 'react';

const Accordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
      <div  onClick={() => setIsOpen(!isOpen)}  className={`bg-green text-white hover:bg-light-green hover:text-green rounded-b-xl mb-10 mx-5 px-10 cursor-pointer  text-justify hyphens-auto rounded-l-xl ${isOpen && 'bg-light-green green mx-5 px-10 cursor-pointer '} `}>
        <div className=' w-full flex justify-between '>
          <h2 className="accordion-title xxs:text-[30px] flex items-center p-0">
            {title} 
          </h2>
          <h2 className="accordion-title">
            {isOpen ? '-' : '+'} 
          </h2>
        </div>
    
      {isOpen && <div className="accordion-content text-green mb-10 p-5 pt-0" dangerouslySetInnerHTML={{ __html: content }} />}
    </div>
  );
};

export default Accordion;
