import React, { useState, useEffect } from "react";
import { Marker, MarkerClusterer } from "@react-google-maps/api";
import pinpickpoint from "../../assets/Drawings/pinpickpoint.png";
import pinhub from "../../assets/Drawings/pinshop.png";
import pinproducer from "../../assets/Drawings/pintractor.png";

export const calculateDistance = (point1, point2) => {
  if (!point1 || !point1.lat || !point1.lng) {
    return 0;
  }

  const R = 6371;
  const dLat = degToRad(point2.lat - point1.lat);
  const dLon = degToRad(point2.lng - point1.lng);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degToRad(point1.lat)) *
      Math.cos(degToRad(point2.lat)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c;
  return distance;
};

const degToRad = (deg) => {
  return deg * (Math.PI / 180);
};

const CustomMarkerCluster = ({
  markers,
  setSelectedMarker,
  setMapCenter,
}) => {
  const [filteredMarkers, setFilteredMarkers] = useState([]);

  

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
    setMapCenter({ lat: marker.lat, lng: marker.lng });
  };
  useEffect(() => {
    setFilteredMarkers(markers);
  }, [markers]);

  return (
    <MarkerClusterer >
      {(clusterer) =>
        filteredMarkers.map((marker) => (
          <Marker
          icon={{
              url:
                  marker.type === "hub"
                  ? pinhub
                  : marker.type === "pickupLocation"
                  ? pinpickpoint
                  : pinproducer,
              scaledSize: new window.google.maps.Size(30, 30),
          }}
          clusterer={clusterer}
            key={`${marker.lat}-${marker.lng}`}
            position={{ lat: marker.lat, lng: marker.lng }}
            onClick={() => handleMarkerClick(marker)}
            style={{
              position: "absolute",
              transform: "translate(-50%, -100%)",
            }}
          />
        
      )) }
    </MarkerClusterer>
  );
};

export default CustomMarkerCluster;
