import React from 'react';
import { useNavigate } from 'react-router-dom';


const CustomToast = ({ message, onClose }) => {
    const navigate = useNavigate();

    const handleClose = () => {
        if (onClose) onClose();
        navigate("/");
    }


    return (
        <>
         <div className=" bg-black opacity-50 fixed top-0 bottom-0 left-0 right-0 "></div>
      <div className="font-lexend  fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center" >
        <div className="bg-white p-16 opacity-100 drop-shadow-lg rounded-xl fixed max-w-[500px] flex flex-col items-center justify-center w-full" onClick={e => e.stopPropagation()}> 
          <p className='text-center pb-5'>{message}</p>
          <button className='bg-green rounded-lg  text-white px-4 p-1' onClick={handleClose}>ok</button>
        </div>
      </div>
      </>
    );
  };
  
export default CustomToast;
