import React from 'react';

const BigImagesquare= ({ imagePath ,children}) => {
  const styleContainer = {
    backgroundImage: `url(${imagePath})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };
  return (
    <div style={styleContainer}  className="xxs:mb-16 lg:mb-0  bg-light-green
                                            xxs:w-[80vw] md:w-[60vw] lg:w-[40vw] xxl:w-[40vw]
                                            xxs:h-[80vw] md:h-[60vw]  lg:h-[40vw] xxl:h-[40vw] 
                                            box-border  s:rounded-2xl  xxs:rounded-xl 
                                            flex justify-end items-start 
                                          xxs:pt-3 xl:pt-5  relative ">
      {children}
    </div>
  );
};

export default BigImagesquare;