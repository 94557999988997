import Title1Amatic from "../components/Title2Amatic";
import Container from "../components/Container";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

const ConditionsTerms = () => {
    const [apiData, setApiData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { i18n, t } = useTranslation();

    useEffect(() => {
        setIsLoading(true);
        setError(null);

        const language = i18n.language;
        const url = `https://www.linked.farm/api/v2/wiki/view/wiki.php?data=page_v2&api=wefg496ad45afsjeg4s633924ujimshd4g56a4f13aghg56f4sa&pageID=663&lang=${language === "fr" ? "fr_FR" : language === "nl" ? "nl_BE" : "en_US"}`;

        axios.get(url)
            .then(response => {
                setApiData(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error(error);
                setError(error);
                setIsLoading(false);
            });
    }, [i18n.language]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error loading data</div>;
    }

    return (
        <div className="font-lexend flex flex-col items-center w-full text-base">
            <Container>
                <Title1Amatic titleText={ t('Terms of sales')} />
                {apiData && <div className="text-justify flex flex-col hyphens-auto w-full" dangerouslySetInnerHTML={{ __html: apiData.html }} />}
            </Container>
        </div>
    );
}

export default ConditionsTerms;
