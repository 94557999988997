import React from "react";
import Title1Amatic from "../../components/Title1Amatic";
import { Link } from "react-router-dom";
import Container from "../../components/Container";
import { useTranslation } from "react-i18next";

const FormStep1 = () => {
  const { t } = useTranslation();
  return (
    <Container>
     <Title1Amatic titleText={" Become a cooperant"} />

      <div className="xxs:max-w-9/10 lg:w-3/4 h-[410px] my-10 mx-auto ">
        <form className=" xxs:w-9/10 mx-auto  ">
          <ul className="grid xxs:w-full xs:w-2/3 gap-10 md:grid-rows-2 mx-auto">
            <Link to="/become-cooperent-form/join-as-emp">
              <li>
                <input
                  type="radio"
                  id="hosting-small"
                  name="hosting"
                  value="hosting-small"
                  className="hidden peer"
                  required
                />
                <label
                  htmlFor="hosting-small"
                  className="inline-flex items-center justify-between w-full p-5 text-txt-grey bg-white border border-green rounded-lg cursor-pointer peer-checked:text-green hover:text-green hover:bg-light-green peer-checked:bg-light-green"
                >
                  <div className="block">
                    <div className="w-full xxs:text-base xs:text-lg font-semibold font-lexend">
                    {t('Join a self-employed / as an individual')}
                    </div>
                  </div>
                  <svg
                    aria-hidden="true"
                    className="w-6 h-6 ml-3"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </label>
              </li>
            </Link>
            <Link to="/become-cooperent-form/join-as-company">
              <li>
                <input
                  type="radio"
                  id="hosting-big"
                  name="hosting"
                  value="hosting-big"
                  className="hidden peer"
                ></input>
                <label
                  htmlFor="hosting-big"
                  className="inline-flex items-center justify-between w-full p-5 text-txt-grey bg-white border border-green rounded-lg cursor-pointer  peer-checked:text-green  hover:text-green hover:bg-light-green peer-checked:bg-light-green"
                >
                  <div className="block">
                    <div className="w-full xxs:text-base xs:text-lg  font-lexend font-semibold">
                      {t('Join as a legal entity / company')}
                    </div>
                  </div>
                  <svg
                    aria-hidden="true"
                    className="w-6 h-6 ml-3"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </label>
              </li>
            </Link>
          </ul>
        </form>
      </div>
    </Container>
  );
};

export default FormStep1;
