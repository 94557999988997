import { GoogleMap } from "@react-google-maps/api";
import React, { useState, useEffect } from "react";

const determineZoomLevel = (type) => {
  switch(type) {
    case "country": return 7;
    case "administrative_area_level_1": return 9;  
    case "locality": return 12;  
    default: return 14;
  }
}

const CustomMap = ({ center: initialCenter, onLoad, children, selectedAddress }) => {
  const [zoom, setZoom] = useState(8.6); 
  const [mapCenter, setMapCenter] = useState(initialCenter);

  const mapContainerStyle = {
    width: "100%",
    height: "500px",
    position: "relative",
  };
  const mapStyles = [
    {
      elementType: "geometry",
      stylers: [{ color: "#eaeaea" }],
    },
    {
      featureType: "transit",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "administrative.land_parcel", // Cibler les routes
      elementType: "geometry", // Cibler la géométrie des routes (lignes)
      stylers: [
        { color: "#OOOOOO" }, // Changer la couleur des routes en blanc
      ],
    },

    {
      featureType: "road", // Cibler les routes
      elementType: "geometry", // Cibler la géométrie des routes (lignes)
      stylers: [
        { color: "#FFFFFF" }, // Changer la couleur des routes en blanc
        { visibility: "on" }, // Afficher les routes
      ],
    },

    {
      featureType: "poi", // Cibler les points d'intérêt
      elementType: "labels", // Cibler les étiquettes des points d'intérêt
      stylers: [
        { visibility: "off" }, // Masquer les étiquettes des points d'intérêt
      ],
    },

    {
      featureType: "landscape.man_made", // Cibler les espaces extérieurs naturels (parcs, forêts, etc.)
      elementType: "geometry.stroke", // Cibler la géométrie des espaces extérieurs naturels
      stylers: [
        { color: "#D5DCD4" }, // Changer la couleur du texte des étiquettes des routes nationales en blanc
        { visibility: "on" }, // Afficher les étiquettes des routes nationales
      ],
    },
    {
      featureType: "landscape.natural", // Cibler les espaces extérieurs naturels (parcs, forêts, etc.)
      elementType: "geometry", // Cibler la géométrie des espaces extérieurs naturels
      stylers: [
        { color: "#D5DCD4" }, // Changer la couleur du texte des étiquettes des routes nationales en blanc
        { visibility: "on" }, // Afficher les étiquettes des routes nationales
      ],
    },
    {
      featureType: "poi", // Cibler
      elementType: "geometry", //
      stylers: [
        { visibility: "off" }, //
      ],
    },

    {
      featureType: "water", // Cibler l'eau
      elementType: "geometry", // Cibler la géométrie de l'eau (polygones)
      stylers: [
        { color: "#cedbdc" }, // Changer la couleur de l'eau en blanc
      ],
    },

    {
      featureType: "poi.business", // Cibler les commerces (points d'intérêt de type "point_of_interest" qui représentent les commerces)
      elementType: "geometry", // Cibler les icônes des étiquettes des commerces
      stylers: [
        { visibility: "off" }, // Afficher les icônes des étiquettes des commerces
      ],
    },

    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#2D604D" }],
    },
    {
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
  ];

  useEffect(() => {
    if (selectedAddress) {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: selectedAddress }, (results, status) => {
            if (status === "OK") {
                const locationType = results[0].types[0]; 
                const newZoom = determineZoomLevel(locationType);
                const newCenter = results[0].geometry.location;

                setZoom(newZoom);
                setMapCenter({
                    lat: newCenter.lat(),
                    lng: newCenter.lng()
                });
            } else {
                console.error(
                    "Geocode was not successful for the following reason:",
                    status
                );
            }
        });
    }
}, [selectedAddress]);

  return (
    <GoogleMap
 
    mapContainerStyle={mapContainerStyle}
    options={{
      styles: mapStyles,
      mapTypeControl: false,
      fullscreenControl: false,
    }}
    center={mapCenter}
    zoom={zoom}
    onLoad={onLoad}
  >
    {children}
  </GoogleMap>
  );
};

export default CustomMap;
