import { HashRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css";
import Footer from "./Pages/Footer/Footer";
import NavBar from "./Pages/NavBar/NavBar";
import HomePage from "./Pages/HomePage/HomePage";
import AboutPage from "./Pages/AboutPage";
import Map2Page from "./Pages/MapPage/Map2Page";
import BecomeCooperantPage from "./Pages/BecomeCooperantPage";
import FarmerPage from "./Pages/FarmerPage";
import ShopPage from "./Pages/ShopPage";
import WholesalerPage from "./Pages/WholesalerPage";
import RestaurantPage from "./Pages/RestaurantPage";
import Contact from "./Pages/Contact";
import i18n from "./translationServices/i18n";
import { I18nextProvider } from "react-i18next";
import FormStep1 from "./Pages/FormBecomeCoop/FormStep1";
import JoinAsEmployee from "./Pages/FormBecomeCoop/JoinAsEmployee";
import JoinAsCompany from "./Pages/FormBecomeCoop/JoinAsCompany";
import ConditionsTerms from "./Pages/ConditionTerms";
import PrivacyCharter from "./Pages/PrivacyCharter"; 
import Rates from "./Pages/RatesPage";
import FaqPage from "./Pages/FaqPage";
import React, { useEffect, useState } from "react";
import StartAHub from "./Pages/StartAHub";
import StartSingleProducer from "./Pages/StartSingleProducer";
import ScrollToTopOnNavigation from "./FunctionScroll";
import Starterskit from "./Pages/Starterskit";
//import Services from "./Pages/Services"

function App() {
  const [selectedLanguage, setSelectedLanguage] = useState("");

  useEffect(() => {
    const preferredLanguage = localStorage.getItem("preferredLanguage");
    const browserLanguage = navigator.language || navigator.userLanguage;

    if (preferredLanguage && ["fr", "nl", "en"].includes(preferredLanguage)) {
      setSelectedLanguage(preferredLanguage);
    } else if (["fr", "nl", "en"].includes(browserLanguage)) {
      setSelectedLanguage(browserLanguage);
    } else {
      setSelectedLanguage("en"); // Langue par défaut
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("preferredLanguage", selectedLanguage);
  }, [selectedLanguage]);

  return (
    <I18nextProvider i18n={i18n}>
      <Router className="w-screen ">
        <ScrollToTopOnNavigation />
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/termsofsales" element={<ConditionsTerms />} />
          <Route path="/privacycharter" element={<PrivacyCharter />} />
          <Route path="/rates" element={<Rates />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/linkedfarmfamily" element={<Map2Page />} />
          <Route path="/becomeacooperant" element={<BecomeCooperantPage />} />
          <Route path="/farmer" element={<FarmerPage />} />
          <Route path="/shop" element={<ShopPage />} />
          <Route path="/restaurant" element={<RestaurantPage />} />
          <Route path="/wholesaler" element={<WholesalerPage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/become-cooperent-form" element={<FormStep1 />} />
          <Route path="/starterskit" element={<Starterskit />} />
          {/* <Route path="/services" element={<Services />} />*/}
          <Route
            path="/become-cooperent-form/join-as-emp"
            element={<JoinAsEmployee />}
          />
          <Route
            path="/become-cooperent-form/join-as-company"
            element={<JoinAsCompany />}
          />
          <Route
            path="/startsingleproducer"
            element={<StartSingleProducer />}
          />
          <Route path="/startahub" element={<StartAHub />} />
        </Routes>
        <Footer />
      </Router>
    </I18nextProvider>
  );
}

export default App;
