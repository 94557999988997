import React from 'react';

const StoryText = ({ paragraph }) => {
  return (
    <div className="flex sm:justify-center leading-relaxed">
      <p className=" md:mb-10 xxs:mb-8 font-lexend
                    text-center md:w-full sm:w-[580px]  xxs:w-full  s:w-full ">
        {paragraph}
      </p>
    </div>
  );
};

export default StoryText;