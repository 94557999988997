import React from "react";
import grow from "../assets/images/07.jpg";
import Schema from "../assets/Drawings/schema.jpg";
import street from "../assets/Drawings/street.jpg";
import computer from "../assets/Drawings/ORDILINKED.png";
import women from "../assets/Drawings/womanandfarmer.png";
import plant from "../assets/images/DSC_0760-2.jpg";
import choux from "../assets/images/arnaldo-aldana-HfH5yd70ox8-unsplash.jpg";
import ButtonGreen from "../components/ButtonGreen";
import seeds from "../assets/images/joshua-lanzarini-FGvQKMP-iXY-unsplash.jpg";
import BgImage1box from "../components/BgImage1box";
import StoryText from "../components/StoryTexte";
import SmallImageSquare from "../components/SmallImageSquare";
import Title3Amatic from "../components/Title3Amatic";
import Title2Amatic from "../components/Title2Amatic";
import BigImage from "../components/BigImage";
import { useTranslation } from "react-i18next";
import Container from "../components/Container";
import { Link } from "react-router-dom";
import BubbleLeft from "../components/BubbleLeft";
import BubbleRight from "../components/BubbleRight";

const AboutPage = () => {
  const { t } = useTranslation();
  return (
    <div className=" w-full font-lexend hyphens-auto flex flex-col items-center">
      <BgImage1box
        imagePath={grow}
        titleText={t("We want to restore")}
        title2Text={t("the balance in our food system")}
        title1Text={t("together with you")}
        buttonText={t("Find my hub")}
        text={t("It takes a lot of different family members to make Farm2Fork happen")}
        link={"/linkedfarmfamily"}
      />

      <Container>
        <div className="flex flex-col items-center w-full">
          <div
            className=" xxs:w-full s:w-full flex flex-col items-center 
                        md:leading-6 lg:leading-8 xl:leading-10 xxl:leading-normal leading-9 
                        md:tracking-tighter lg:tracking-wide "
          >
            <Title2Amatic titleText={t("Our Story")} />
            <div className="flex sm:justify-center leading-relaxed">
              <div className="md:mb-10 xxs:mb-8 font-lexend
                              flex text-justify md:w-full sm:w-[580px]  xxs:w-full  s:w-full "
              >
                <p className="">
                  {t("Since 2015, thousands of farmers and producers have offered their products through dozens of hubs on the Linked.farm platform. They sell directly to individuals, to restaurants or stores and organize their logistics together through pickup or delivery. Together they own the cooperative digital platform Linked.Farm, which was originally founded by")}{" "}
                  <Link
                    className="font-lexendbold hover:text-green"
                    target="_blank"
                    to={"https://www.linkedin.com/in/laurence-claerhout-3827093"}
                  >
                    Laurence Claerhout
                  </Link>
                  {" "}{t(", Chief Cooperative Officer, who was very quickly joined by Stijn Frans, Chief Tech Officer. These, together with")}{" "}
                  <Link
                    className="font-lexendbold hover:text-green "
                    target="_blank"
                    to={"https://www.linkedin.com/in/frederikcousin/"}
                  >
                    Frederik Cousin
                  </Link>
                  {t(", now make up the board of the cooperative.")}
                </p>
              </div>
            </div>

            <div className="w-full flex   md:flex-row  md:items-start md:justify-evenly xxs:flex-col xxs:items-center box-border  ">
              <BigImage imagePath={Schema} />

              <div className="  md:flex-col md:justify-between xxs:flex-col xxs:justify-between h-full ">
                <BubbleRight>
                  {t( "Linked.farm wants to organize FarmToFork more efficiently with a very user-friendly platform.")}
                </BubbleRight>
                <BubbleRight>
                  {t("We believe qualitative and healthy food, fresh from our farms should be the norm for all, at a fair price for the eater and for the farmer.")}
                </BubbleRight>
              </div>
            </div>

            <div className="flex w-full  md:justify-evenly  md:flex-row xxs:flex-col-reverse  items-center xxs:mb-8 sm:mb-8 mb-12">
              <div>
                <BubbleLeft>
                  {t("Farmers work alone as")}{" "}
                  <Link
                    to="/farmer"
                    className=" text-green font-lexendbold hover:text-white"
                  >
                    {" "} {t("a single producer")}{" "}
                  </Link>
                  {t("or in a cluster where they collaborate as a")}
                  <Link
                    to="/shop"
                    className="text-green font-lexendbold hover:text-white"
                  >
                    {" "}hub.
                  </Link>
                </BubbleLeft>
              </div>
              <BigImage imagePath={street} />
            </div>

            <StoryText
              paragraph={t(
                "Farmers and hubs are free to do business and freely determine their supply, margin and prices."
              )}
            />
            <div className="flex w-full md:items-start md:flex-row md:justify-evenly xxs:flex-col xxs:items-center ">
              <BigImage imagePath={computer} />
              <div className=" xxs:mb-8 sm:mb-8 mb-10">
                <BubbleRight>
                  {t("Linked.Farm is a cooperative (Erkende CV) in which we want to reinvent our food system with you.")}
                </BubbleRight>
              </div>
            </div>

            <StoryText
              paragraph={t("We help producers to get started in FarmToFork in the easiest and cheapiest way.")}
            />

            <div className="flex xxs:mt-5 lg:mt-0 w-full md:flex-row md:justify-evenly md:items-start xxs:flex-col  xxs:items-center mb-10 ">
              <BubbleLeft>
                {t("We joined the Flemish Farm2Fork-Alliance and")}{""}
                <Link
                  className="font-lexendbold"
                  target="_blank"
                  to={"https://www.voedsel-anders.be/"}
                >
                  {" "}Voedsel-Anders
                </Link>
              </BubbleLeft>

              <div className="  xxs:w-[160px] sm:w-[160px] md:w-[160px] xl:w-[200px] xxl:w-[280px]
                                flex justify-center object-cover border-box
                                mb-10"
              >
                <img alt="women" src={women} className="w-full" />
              </div>
              <BubbleRight>
                {t("We try to collaborate with other cooperatives or we build bridges to other digital platforms.")}
              </BubbleRight>
            </div>

            <Title2Amatic titleText={t("Linked.Farm will grow with you")} />

            <div className="flex md:justify-start  w-full sm:justify-center xxs:justify-center">
              <Title3Amatic titleText={t("Our Vision")} />
            </div>

            <div
              className="flex w-full md:flex-row md:justify-between xxs:flex-col  sm:flex-col items-center  
                            xxs:mb-8 sm:mb-8 mb-12  "
            >
              <p
                className="  xxs:w-full s:w-full  sm:w-[580px] md:w-[370px] lg:w-[490px] xl:w-[600px] xxl:w-[800px]  
                              tracking-wide text-justify font-lexend   
                              xxs:mb-5 sm:mb-5"
              >
                {t(
                  "Linked.farm is a platform cooperative that wants to help local FarmToFork initiatives to become economically viable."
                )}
                <br /> <br />
                {t(
                  "We want to structurally change the food system to make it fairer, healthier and more transparent."
                )}
              </p>

              <SmallImageSquare imagePath={plant} />
            </div>
            <div className="flex w-full justify-end">
              <div className="flex md:justify-start  w-full sm:justify-center xxs:justify-center  xxs:w-full s:w-full  sm:w-[580px] md:w-[370px] lg:w-[490px] xl:w-[600px] xxl:w-[800px] ">
                <Title3Amatic titleText={t("Our Mission")} />
              </div>
            </div>
            <div
              className="flex  md:flex-row md:justify-between xxs:flex-col-reverse items-center w-full  
                            xxs:mb-8 sm:mb-8 mb-12  "
            >
              <SmallImageSquare imagePath={seeds} />

              <div className=" flex flex-col justify-between s:h-fit sm:h-[240px]  md:h-[200px] lg:h-[240px] xl:h-[300px] xxl:h-[350px]">
                <div>
                  <p
                    className="  xxs:w-full s:w-full sm:w-[80vw] md:w-[40vw] xxl:w-[40vw] 
                                tracking-wide text-justify font-lexend   "
                  >
                    {t("We want to scale Farm2Fork by :")}
                  </p>
                  <ul
                    className=" pl-7 xxs:pl-2 sm:pl-2 list-disc mb-5  
                                xxs:w-full s:w-full  sm:w-[580px] md:w-[370px] lg:w-[490px] xl:w-[600px] xxl:w-[800px] 
                                tracking-wide text-justify font-lexend leading-28 "
                  >
                    <li>{t("Working cooperatively")}</li>

                    <li>
                      {t(
                        "Developing (digital) tools and services to support logistics, sales and administration"
                      )}
                    </li>
                    <li>
                      {t(
                        "Developing an economically viable Farm2Fork model in which the producer is setting the price"
                      )}
                    </li>
                  </ul>
                </div>
                <div className="flex justify-center xxs:mb-10 lg:mb-0">
                  <ButtonGreen text={"Faq"} link={"/faq"} />
                </div>
              </div>
            </div>

            <div className="flex w-full md:justify-start  sm:justify-center xxs:justify-center">
              <Title3Amatic titleText={t("Our Objectives")} />
            </div>

            <div
              className="flex w-full  md:flex-row md:justify-between xxs:flex-col sm:flex-col sm:items-center xxs:items-center
                               md:mb-12  xxs:mb-5"
            >
              <ul
                className=" xxs:mb-8 sm:mb-8 mb-10
                              xxs:pl-2 sm:pl-2 pl-7  list-disc 
                              xxs:w-full s:w-full  sm:w-[580px] md:w-[370px] lg:w-[490px] xl:w-[600px] xxl:w-[800px]
                              tracking-wide text-justify font-lexend leading-28 "
              >
                <li>
                  {t(
                    "FarmToFork gets a significant market share in the food sector."
                  )}
                </li>

                <li>
                  {" "}
                  {t(
                    "Farmers make a fair living out of their product sales. They make autonomous and free choices."
                  )}
                </li>

                <li>
                  {t(
                    "Local, fair, healthy, fresh and seasonal food becomes available for ALL."
                  )}
                </li>

                <li>
                  {t(
                    "Our model is economically viable. We aim to pay a limited dividend to our cooperants"
                  )}
                </li>
              </ul>
              <SmallImageSquare imagePath={choux} />
            </div>

            <h2
              className="   w-full justify-center
                              font-amatic text-center text-green
                              xxl:text-[60px] xl:text-[50px] lg:text-[45px] md:text-[45px] s:text-[40px] sm:text-[40px]  xxs:text-[35px]
                              xl:mx-28  sm:mx-10 xxs:mx-10
                              xxs:mb-8 sm:mb-8 mb-12  
                              flex"
            >
              {t("If you eat,")}
              <br className=" sm:hidden " />
              {t("you are in")}
            </h2>

            <ButtonGreen
              text={t("Become a cooperant")}
              link={"/becomeacooperant"}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AboutPage;
