import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import{  GlobeAltIcon} from '@heroicons/react/24/outline'

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 300, damping: 24 }
  },
  closed: {
    opacity: 0,
    y: 20,
    transition: { duration: 0 }
  }
};

const Language = () => {
  const [isOpen, setIsOpen] = useState(false);
  const languageRef = useRef(null);
  const { i18n } = useTranslation();
  

  useEffect(() => {
    function handleClickOutside(event) {
      if (languageRef.current && !languageRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const changeLanguage = (lang) => {
    setIsOpen(false);
    i18n.changeLanguage(lang);
    localStorage.setItem('preferredLanguage', lang);
  };
  
  return (
    <motion.nav
    ref={languageRef}
      initial={false}
      animate={isOpen ? "open" : "closed"}
      className="menu  h-16  text-green  font-lexendbold "
    >
         
      <motion.button
        className="arrow lg:text-green  hover:lg:text-black hover:xxs:text-light-green xxs:text-white uppercase"
        whileTap={{ scale: 0.97 }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <GlobeAltIcon className='w-4 h-4  lg:text-green  hover:xxs:text-light-green hover:lg:text-black  xxs:text-white'/> 
        {i18n.language}
        <motion.div
            variants={{
                open: { rotate: 180 },
                closed: { rotate: 0 }
            }}
            transition={{ duration: 0.1 }}
            style={{ originY: 0.55 }}
        >
           <ChevronDownIcon className='w-4 h-6 stroke-4 lg:text-green xxs:text-white'/> 
        </motion.div>
      </motion.button>
      <motion.ul
        className="languages absolute  mt-[5px] mr-[14px]
         xs:rounded-b-3xl xxs:rounded-l-3xl xs:rounded-l-3xl xxs:rounded-b-3xl                              
         right-[1vh] sm:mr-15
        text-green z-[10] origin-top-right text-right  "
       
        variants={{
          open: {
            boxShadow: "1px 1px 1px #2D604D",
            backgroundColor: "white",
            color: "#2D604D",
            transition: {
              type: "spring",
              bounce: 0,
              duration: 0.1,
              delayChildren: 0.1,
              staggerChildren: 0.02,
            },
          },
          closed: {
            backgroundColor:"transparent",
            boxShadow: "none",
            transition: {
              duration: 0,
            },
          },
        }}
        style={{ pointerEvents: isOpen ? "auto" : "none" }}
      >
        <motion.li
          className="language  text-s tracking-wider hover:text-black whitespace-nowrap"
          onClick={() => changeLanguage("en")}
          variants={itemVariants}
        >
          EN{" "}
        </motion.li>
        <motion.li
          className="language text-s tracking-wider hover:text-black whitespace-nowrap"
          onClick={() => changeLanguage("fr")}
          variants={itemVariants}
        >
          FR
        </motion.li>
        <motion.li
          className="language text-s tracking-wider hover:text-black whitespace-nowrap"
          onClick={() => changeLanguage("nl")}
          variants={itemVariants}
        >
          NL
        </motion.li>
      </motion.ul>
    </motion.nav>
  );
};

export default Language;
