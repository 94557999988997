import React from "react";
import Title3Amatic from "./Title3Amatic";
import ButtonGreen from "./ButtonGreen";

const TextRightImgLeft = ({ titleTextAmatic, lines, imagePath, link, text }) => {
  return (
    <div className="flex xxs:flex-col-reverse lg:flex-row items-center justify-between w-full xxs:mb-10 lg:mb-10 ">
      <div className="flex flex-col justify-evenly h-full items-center xxs:w-[80vw]  sm:w-[80vw] md:w-[30vw] xxl:w-[30vw] ">
            <img
            alt="food"
            src={imagePath}
            className=" xxs:h-[150px] my-5 md:h-[180px] xl:h-[180px] xxl:h-[180px] object-contain  "
          />

        <div className="w-fit">
          <ButtonGreen text={text} link={link} />
        </div>
      </div>

      <div className=" flex box-border   xxs:w-[80vw]  sm:w-[80vw] md:w-[40vw] xxl:w-[40vw] flex-col justify-center text-center bg-light-green rounded-3xl p-5">
        <Title3Amatic titleText={titleTextAmatic} />
        <ul className="text-green font-lexend text-left pl-7 leading-relaxed  px-4">
            {lines.map((line, index) => (
          <li className="list-disc leading-relaxed " key={index}>
                {line}
          </li>
            ))}
        </ul>
      </div>

    </div>
  );
};

export default TextRightImgLeft;
