import Title1Amatic from "../../components/Title3Amatic";
import MapAutoComplete from "./MapAutoComplete";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import pinpickpoint from "../../assets/images/basket.png";
import pinhub from "../../assets/images/people-roof-solid.png";
import pinproducer from "../../assets/images/tractor.png";
import truck from "../../assets/images/truck.png";

const Sidebar = ({ onPlaceChanged, filterOption }) => {
  const { t } = useTranslation();
  const autocompleteRef = useRef(null);
  const [AutoCompleteOptions] = useState({
    region: "EU",
    types: ["geocode"],
    componentRestrictions: { country: 'BE' } 
  });
  const getImageByFilterOption = () => {
    switch (filterOption) {
      case "deliveries":
        return truck;
      case "hub":
        return pinhub;
      case "pickupLocation":
        return pinpickpoint;
      case "producer":
        return pinproducer;
      default:
        return null;
    }
  };
  const getTitleTextByFilterOption = () => {
    switch (filterOption) {
      case "hub":
        return t("Shop fresh and local products.");
      case "all":
        return t("Join the Linked.Farm family.");
      case "producer":
        return t("Meet the producers: discover who makes your products");
      case "pickupLocation":
        return t("Collect fresh and local products.");
        case "deliveries":
          return t("Have your farm products delivered to you.");
        default:
        return ""; 
    }
  };
  return (
    <div className="flex flex-col items-center pt-5  xxs:h-fit lg:h-[500px] justify-between xxs:w-full lg:w-1/4 cursor-default bg-light-green px-[3vw]">
      <Title1Amatic titleText={getTitleTextByFilterOption()} />
      {getImageByFilterOption() && (
      <div className="w-20 xxs:mb-0 md:mb-10 xxs:hidden md:flex">
        <img src={getImageByFilterOption()} alt="" className="ml-1" />
      </div>
    )}
      <MapAutoComplete
        onLoad={(autocomplete) => {
          autocompleteRef.current = autocomplete;
        }}
        options={AutoCompleteOptions}
        onPlaceChanged={onPlaceChanged}
      />
    </div>
  );
};

export default Sidebar;
