import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const BoxMain = ({ buttonText, para, link, text, path }) => {
  return (
    <Link to={link}
    className="bg-black/30 box rounded-2xl border-[1px]  text-white  backdrop-blur-s
               flex-col items-center drop-shadow-lg  flex justify-between
               xxs:w-[230px] xxs:h-[230px] lg:w-[220px] lg:h-[220px] xl:w-[280px] xl:h-[280px] border-box
               p-5  xl:p-4 
               xxs:mx-0 s:mx-3 xxs:mb-5 lg:mx-0"
  >
    <h3 className="tracking-tighter font-lexendbold text-center xxs:text-lg  xl:text-2xl">
      {text}
    </h3>
    <img
      src={path}
      alt=""
      className="  xxl:h-[150px] xl:h-[100px] lg:h-[80px] xxs:h-[70px] opacity-85 mb-2 max-w-[130px] object-contain"
    />

    <motion.div
      whileHover={{ scale: 1.02 }}
      transition={{ type: "spring", stiffness: 400, damping: 10 }}
    >
      <button
        className=" font-lexendbold flex justify-center items-center text-green bg-white shadow-md
                    border opacity-90 tracking-tighter
                    xxs:w-[200px] xl:w-[260px] 
                     xl:text-lg  xxs:text-sm 
                    xxs:py-2  lg:py-2 
                    xxs:rounded-lg sm:rounded-lg"
      >
        {buttonText}
      </button>
    </motion.div>

    <p className="font-lexendbold tracking-tighter  xxl:text-lg xl:text-lg xxs:text-sm ">
      {para}
    </p>
  </Link>
  );
};

export default BoxMain;
