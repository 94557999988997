import React from 'react';

import A from "../assets/images/A.png"

const BubbleCornerLeft= ({ text, letter, name,name2}) => {
  return (
    <div className="  xxs:mb-8 sm:mb-8 mb-10 w-full items-end flex justify-center
                      xxs:w-[80vw] sm:w-[80vw] md:w-[40vw] xxl:w-[40vw] "
    >
      <div className='bg-light-green flex flex-col  p-8 border-[1px] border-green 
        rounded-t-[100px] rounded-br-[100px]
        xxs:w-[80vw] sm:w-[80vw] md:w-[40vw] xxl:w-[40vw]'>
     <img alt='#' className='w-[70px] mt-5 mb-5' src={A}/>
     
     <p className='text-green font-lexend tracking-wider leading-[2em] text-justify hyphens-auto mb-5  w-full '>
     <span className="  text-[3em] ">{letter}</span> {text} 
<br/> <br/>
    <span className='font-lexendbold'> {name}  <br/>
    {name2}</span> 
     </p>
    </div>
    </div>
  );
};

export default BubbleCornerLeft;