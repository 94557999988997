import React from "react";
import ButtonGreen from "../../components/ButtonGreen";
import GetVars from "./GetVars";
import img3 from "../../assets/images/11.jpg";
import Title2Amatic from "../../components/Title2Amatic";
import BigImagesquare from "../../components/BigImagesquare";
import { useTranslation } from "react-i18next";

const Intro = () => {
    const { t } = useTranslation();

  return (
        <div
          className="flex justify-between xxs:flex-col-reverse  lg:flex-row items-center w-full
                   lg:mb-16 xxs:mb-0
                   xxs:text-md  xl:text-lg xxl:text-xl "
        >
         
            <BigImagesquare imagePath={img3}>
              <GetVars />
            </BigImagesquare>
         
            <div className="xxs:mb-10 md:mb-0 lg:w-[40vw] px-5 xxl:w-[40vw] flex flex-col items-center">
              <Title2Amatic
                titleText={t(
                  "The cooperative platform that helps farmers, producers, shops & grocers to collaborate"
                )}
              />

              <ButtonGreen text={t("About us")} link={"/about"} />
            </div>
        
          </div>
  
  );
};

export default Intro;
