import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import JoinUs from "./JoinUs";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import Language from "./Language";

function NavBar() {
  const { t } = useTranslation();
  const [navbar, setNavbar] = useState(false);
  const navbarRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setNavbar(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const closeNavbar = () => {
    setNavbar(false);
  };

  return (
    <div className="bg-white flex w-full items-center xxl:h-[100px] lg:h-[100px] md:h-[80px] xxs:h-[80px] justify-center   ">
      <nav
        className="w-full max-w-[2000px]  bg-white/50  fixed  z-[11]  backdrop-blur-md tracking-wider top-0  text-green font-lexendbold
                         xxl:h-[100px] lg:h-[100px] md:h-[80px] xxs:h-[80px]     
                         lg:px-10 md:px-10   xxs:px-5 
                         lg:py-0         
                        lg:text-[15px] xl:text-[17px] xxl:text-[20px]  xxs:text-s  md:text-s
                        flex  items-center justify-between  "
        ref={navbarRef}
      >
        <div className="justify-between w-full lg:items-center lg:flex">
          <div className="flex items-center justify-between py-3 md:py-4  lg:block">
            <Link to="/">
              <motion.div
                className="logo  xxl:w-[70px] lg:w-[70px] md:w-[50px] xxs:w-[50px]  xxl:h-[70px] lg:h-[70px]  md:h-[50px]  xxs:h-[50px]"
                whileHover={{ scale: 1.1 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
              />
            </Link>

            <button
              className=" text-green lg:hidden rounded-md outline-none focus:light-green "
              onClick={() => setNavbar(!navbar)}
            >
              {navbar ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-[30px] h-[30px]"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-[30px] h-[30px]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </button>
          </div>

          <div
            className={`box-border flex items-center justify-end  drop-shadow-xl
                                    xxs:w-[200px] md:w-[200px] lg:w-fit
                                    xxs:p-4 s:p-4 md:p-4 lg:p-0
                                    xxs:rounded-l-3xl xxs:rounded-b-3xl 
                                    lg:bg-transparent  md:bg-green xxs:bg-green
                                    xxs:right-[12vw] xs:right-[11vw] s:right-[8vw] sm:right-[5vw] md:right-[8vw] lg:right-[3vw]
                                    xxs:fixed  md:fixed  lg:block 
                                    lg:top-[0px] md:top-[60px]  sm:top-[70px] xxs:top-[53px] 
                                    ${navbar ? "block" : "hidden"}`}
          >
            <ul className=" items-center uppercase
                            xxl:h-[100px] lg:h-[100px] h-full w-full
                            whitespace-nowrap                          
                            lg:text-green md:text-white xxs:text-white  
                            justify-between xxs:space-y-8  lg:flex  lg:flex-row lg:space-x-12 lg:space-y-0 md:flex-col"
            >
              <li>
                <Link
                  className="lg:hover:text-black  lg:focus:text-black  lg:active:text-black  flex justify-start xxs:hover:text-light-green md:hover:text-light-green"
                  onClick={() => setNavbar(!navbar)}
                  to="/about"
                >
                  {t("About")}
                </Link>
              </li>

              <li className=" flex justify-start">
                <JoinUs onLinkClick={closeNavbar}/>
              </li>

             {/* <li>
                <Link
                  className="lg:hover:text-black  lg:focus:text-black  lg:active:text-black  flex justify-start xxs:hover:text-light-green md:hover:text-light-green"
                  onClick={() => setNavbar(!navbar)}
                  to="/services"
                >
                  {t("Services")}
                </Link>
              </li>
              */}


              <li className="lg:hover:text-black  lg:active:text-black  flex justify-start xxs:hover:text-light-green md:hover:text-light-green">
                <Link
                  className="lg:hover:text-black  lg:focus:text-black  lg:active:text-black  flex justify-start xxs:hover:text-light-green md:hover:text-light-green"
                  onClick={() => setNavbar(!navbar)}
                  to="/linkedfarmfamily"
                >
                  Linked.Farm Family
                </Link>
              </li>
              <li>
                <Link
                  className="lg:hover:text-black  lg:focus:text-black  lg:active:text-black  flex justify-start xxs:hover:text-light-green md:hover:text-light-green"
                  onClick={() => setNavbar(!navbar)}
                  to="/contact"
                >
                  {t("Contact")}
                </Link>
              </li>

              <li className="lg:hover:text-black  justify-end xxs:hover:text-light-green flex  items-center   md:hover:text-light-green">
                <Language />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
