import React from 'react';

const Container = ( {children}) => {
    
    return (
        <div className=' flex flex-col items-center w-full mb-16 justify-center
        xxs:px-[10vw] s:px-[10vw] sm:px-10 md:px-28 lg:px-20 xl:px-28 xxl:px-28 
        xxl:max-w-[2000px] 
         xxl:text-2xl xl:text-xl lg:text-md xxs:text-md 
        font-lexend hyphens-auto 
        cursor-default '>
             {children}
        </div>
    );
};

export default Container;
