import { Link } from "react-router-dom";
import { OverlayView } from "@react-google-maps/api";
import React from "react";
import { useTranslation } from "react-i18next";


const CustomInfoWindow = React.forwardRef(
  ({ position, website, type, image, address, name }, ref) => {
    const { t } = useTranslation();
    return (
      <OverlayView
        position={position} 
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <div
          ref={ref}
          style={{
            background: "white",
            padding: "10px",
            width: "250px",
            height: "220px",
            borderTopRightRadius: "40px",
            borderBottomRightRadius: "40px",
            borderBottomLeftRadius: "40px",
            boxShadow: "1px 1px 1px grey",
          }}
        >
          <div className="flex h-full justify-between cursor-default items-center flex-col text-xl px-3">
            <h3 className="text-md font-amatic tracking-wider mb-1  text-center ">
              {type}
            </h3>
            
              <img
                src={image}
                alt="icon"
                className="object-contain rounded-2xl mb-1 h-[50px]"
              />
              <p className=" text-sm font-lexendbold tracking-tight mb-1  uppercase text-green">
                {name}
              </p>
<div className="w-full mb-1">
              <p className="text-left text-s font-lexendbold leading-normal">
                {t("Address:")}
              </p>

              <p className="leading-normal text-xs w-[200px] capitalize text-left font-lexend">
                {address}
              </p>
              </div>
            
              <Link
                to={`https://${website}`}
                target="blank"
                className="mt-2 w-fit cursor-pointer bg-green rounded-md text-white font-lexendbold flex  h-fit px-2 p-1 uppercase text-xs"
              >
                {t("Website")}
              </Link>
            
            </div>
          </div>
      </OverlayView>
    );
  }
);

export default CustomInfoWindow;
