import React, { useRef, useState, useEffect } from "react";
import BgImage2boxes from "../components/BgImage2boxes";
import TextRightImgLeft from "../components/TextRightImgLeft";
import TextLeftImgRight from "../components/TextLeftImgRight";
import ButtonGreen from "../components/ButtonGreen";
import Title2Amatic from "../components/Title2Amatic";
import veggies from "../assets/images/22.jpg";
import kaas from "../assets/Drawings/kaas.svg";
import Container from "../components/Container";
import SmallImageSquare from "../components/SmallImageSquare";
import BubbleCornerLeft from "../components/BubleCornerLeft";
import molleke from "../assets/images/21.jpg";
import logoMolleke from "../assets/logo/molleke.png";
import ReusableList from "../components/ReusableList";
import { useTranslation } from "react-i18next";
import bocal from "../assets/Drawings/bocal.png";
import farine from "../assets/Drawings/farine.png";
import soup from "../assets/Drawings/soep.png";
import GoodToKnow2 from "../components/GoodToKnow2";

function ShopPage() {
  const sectionRef = useRef(null);
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(
    "https://wiki.linkedfarm.eu/#/page/1047/en_EN"
  );

  useEffect(() => {
    const language = i18n.language;
    let x;

    if (language === "fr") {
      x = "https://wiki.linkedfarm.eu/#/page/1047/fr_FR";
    } else if (language === "nl") {
      x = "https://wiki.linkedfarm.eu/#/page/1047/nl_BE";
    } else {
      x = "https://wiki.linkedfarm.eu/#/page/1047/en_EN";
    }
    setCurrentLanguage(x);
  }, [i18n.language]);

  const line1 = [
    t("Simple administration (packing slip, picking,…)"),
    t("User-friendly (labels, refunds,…)"),
    t("B2C and B2B sales"),
    t("Subscriptions"),
    t("Payment online or by invoice"),
    t("Farmers possibly manage assortment (marketplace)"),
  ];
  const line2 = [
    t("Newsletters, Mailchimp, Facebook, Instagram, LinkedIn"),
    t("Promotions, (volume) discounts"),
    t("Circular vouchers"),
    t("Gift cards"),
    t("Easy collaboration with other hubs"),
  ];
  const boxright1 = [
    t("Stock and purchasing management"),
    t("Labels, easy logistics"),
    t("Empties"),
    t("Return logistics"),
    t("Pickup points or Delivery"),
    t("Optimal routing"),
    t("Simple and automatic billing"),
    t("Link to your cash register (Touch Systems, Micro Concept)"),
  ];
  const boxright2 = [
    t("Pricing by producer, margin by hub"),
    t("Adjustable margins per delivery method, per customer, per product"),
    t("Automatic (delivery) costs possible"),
    t("Different delivery or collection methods"),
    t("Joint purchase easy to organize"),
  ];

  const handleLink2Click = () => {
    const sectionRefElement = sectionRef.current;
    window.scrollTo({ top: sectionRefElement.offsetTop, behavior: "smooth" });
  };

  return (
    <div className="flex-col flex w-full items-center">
      <BgImage2boxes
        imagePath={veggies}
        titleText={t("Buy & sell products")}
        title1Text={t("from several farmers")}
        buttonText1={t("Find my hub")}
        text1={t("Order my products from an existing hub")}
        buttonText2={t("Start my Hub")}
        text2={t(
          "Buy products directly from farms and sell them online B2C and B2B"
        )}
        link1={"/linkedfarmfamily"}
        onClick={handleLink2Click}
      />

      <Container>
        <Title2Amatic titleText={t("What do we offer ?")} ref={sectionRef} />

        <TextLeftImgRight
          titleTextAmatic={t("You have your own webshop")}
          lines={line1}
          imagePath={soup}
          link={currentLanguage}
          text={t("Watch our demo")}
          target={"_blank"}
        />

        <TextRightImgLeft
          imagePath={bocal}
          titleTextAmatic={t("You save costs")}
          lines={boxright1}
          link={"/startahub"}
          text={t("Get started")}
        />

        <TextLeftImgRight
          titleTextAmatic={t("You build your own brand")}
          lines={line2}
          imagePath={farine}
          link={"/startahub"}
          text={t("Get started")}
        />

        <TextRightImgLeft
          imagePath={kaas}
          titleTextAmatic={t("You determine your own margin & supply")}
          lines={boxright2}
          link={"/startahub"}
          text={t("Get started")}
        />

        <GoodToKnow2 />

        <div className="flex xxs:flex-col-reverse sm:flex-col-reverse lg:flex-row justify-between xxs:mb-10 lg:mb-0 items-center w-full">
          <div className="flex flex-col items-center justify-end">
            <img alt="#" src={logoMolleke} className="mb-5" />
            <SmallImageSquare imagePath={molleke} />
          </div>
          <BubbleCornerLeft
            text={t(
              "Do you dream of working with farmers ? Do you sell to private individuals and / or B2B customers ? Are you stuck with empties ? Do you want to deliver/have it picked up/delivered several days a week ? Do you own a store and need a link with a cash register system ? Then Linked.Farm is the right solution for you."
            )}
            name="Aline"
            name2={t("Shopkeeper at Molleke")}
          />
        </div>

        <Title2Amatic titleText={t("It's easy to start")} />

        <ReusableList linkform={"/startahub"} pageId="page2" />

        <ButtonGreen text={t("Rates / Pricing")} link={"/rates"}></ButtonGreen>
      </Container>
    </div>
  );
}
export default ShopPage;
