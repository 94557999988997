import React, { useRef, useState, useEffect } from "react";
import BgImage3boxes from "../components/BgImage3boxes";
import TextLeftImgRight from "../components/TextLeftImgRight";
import TextRightImgLeft from "../components/TextRightImgLeft";
import Title2Amatic from "../components/Title2Amatic";
import GoodToKnow2 from "../components/GoodToKnow2";
import ButtonGreen from "../components/ButtonGreen";
import wheat from "../assets/images/wheat.jpg";
import Container from "../components/Container";
import { useTranslation } from "react-i18next";
import ReusableList from "../components/ReusableList";
import poule from "../assets/Drawings/poule.png";
import framboise from "../assets/Drawings/framboise.png";
import abricot from "../assets/Drawings/abricot.png";
import duck from "../assets/Drawings/duck.png";
import BubbleCornerLeft from "../components/BubleCornerLeft";
import SmallImageSquare from "../components/SmallImageSquare";
import farmerLien from "../assets/images/farmerlien.jpg";
import GrassRootLogo from "../assets/images/GrassRootLogo.png";

function FarmerPage() {
  const sectionRef = useRef(null);
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState("https://wiki.linkedfarm.eu/#/page/1047/en_EN");

  useEffect(() => {
    const language = i18n.language;
    let x;

    if (language === "fr") {
      x = "https://wiki.linkedfarm.eu/#/page/1047/fr_FR";
    } else if (language === "nl") {
      x = "https://wiki.linkedfarm.eu/#/page/1047/nl_BE";
    } else {
      x = "https://wiki.linkedfarm.eu/#/page/1047/en_EN";
    }
    setCurrentLanguage(x);
  }, [i18n.language]);


  const line1 = [
    t("Easy administration (packing slip, harvest list,...)"),
    t("User-friendly (labels, refunds,…)"),
    t("Sales to individuals and B2B (catering…)"),
    t("Subscriptions and packages"),
    t("Online payment, automatic invoices"),
  ];
  const line2 = [
    t("Newsletters, Mailchimp, Facebook, Instagram, LinkedIn"),
    t("Promotions, (volume) discounts"),
    t("Circular vouchers"),
    t("Gift cards"),
  ];
  const boxright1 = [
    t("Inventory management, empties (EPS,...)"),
    t("Packages, harvest list, packing slips, labels"),
    t("Pick-up points, deliveries"),
    t("Return logistics and optimal routing"),
    t("Easy and fast invoicing"),
    t("Inventory management, deposit (EPS), link with cash register"),
  ];
  const boxright2 = [
    t("Own pricing, correction delivered - weight"),
    t("Adjustable margins per hub, per customer, per product"),
    t("Automatic (delivery) costs possible"),
    t("Sales statistics"),
    t("Easy collaboration with other hubs"),
  ];
  const handleLink2Click = () => {
    const sectionRefElement = sectionRef.current;
    window.scrollTo({
      top: sectionRefElement.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <div className=" xxl:text-2xl xl:text-xl lg:text-lg w-full  flex flex-col items-center font-lexend">
      <BgImage3boxes
        imagePath={wheat}
        link1={"/linkedfarmfamily"}
        link3={"/shop"}
        titleText={t("More than 1500 farmers & producers")}
        title1Text={t("trust us")}
        buttonText1={t("Join a hub")}
        text1={t(
          "Would you like to sell your products via an existing logistic hub ?"
        )}
        buttonText2={t("Start my webshop")}
        text2={t(
          "Would you like to sell your products and baskets online (B2C and B2B)?"
        )}
        buttonText3={t("Start our hub")}
        text3={t(
          "Would you like to collaborate with other producers and sell B2B and B2C ?"
        )}
        onClick={handleLink2Click}
      />

      <Container>
        <Title2Amatic titleText={t("What do we offer?")} ref={sectionRef} />

        <TextLeftImgRight
          titleTextAmatic={t("You have your own webshop")}
          lines={line1}
          imagePath={poule}
          link={currentLanguage}
          text={t("Watch our demo")}
          target={"_blank"}
        />

        <TextRightImgLeft
          imagePath={abricot}
          titleTextAmatic={t("You save costs")}
          lines={boxright1}
          link={"/startsingleproducer"}
          text={t("Get started")}
        />

        <TextLeftImgRight
          titleTextAmatic={t("You build your own brand")}
          lines={line2}
          imagePath={duck}
          link={"/startsingleproducer"}
          text={t("Get started")}
        />

        <TextRightImgLeft
          imagePath={framboise}
          titleTextAmatic={t("You decide on price and assortment")}
          lines={boxright2}
          link={"/startsingleproducer"}
          text={t("Get started")}
        />

        <GoodToKnow2 />

        <div className="flex xxs:flex-col-reverse sm:flex-col-reverse lg:flex-row xxs:mb-10 lg:mb-0 justify-between items-center w-full">
          <div className="flex flex-col items-center justify-end">
            <img alt="#" src={GrassRootLogo} className="mb-5" />
            <SmallImageSquare imagePath={farmerLien} />
          </div>
          <BubbleCornerLeft
            text={t(
              "Do you get lost in administration? Do you get all your orders in the mail without a harvest list and labels? Do you get the jitters when you think of invoicing? Do you dream of your own webshop? Then Linked.Farm is the solution for you."
            )}
            name="Lien Vrijders"
            name2={t("Farmer at Grassroots")}
          />
        </div>
        <Title2Amatic titleText={t("It's easy to start")} />
        <ReusableList linkform={"/startsingleproducer"} pageId="page1" />
        <ButtonGreen text={t("Rates / Pricing")} link={"/rates"}></ButtonGreen>
      </Container>
    </div>
  );
}
export default FarmerPage;
