import React from 'react';
import Title1Amatic from './Title1Amatic';
import SmallHeader from './SmallHeader1';
import AnimationBottom from './AnimationBottom';
const BgImage1box = ({ ref,imagePath, title1Text, title2Text, titleText, buttonText, link, text ,target }) => {

      const styleContainer = {
        backgroundImage: `url(${imagePath})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      };
    
      return (  
        <div style={styleContainer} ref={ref} className=" backdrop-blur-3xl opacity-90 box  mb-16 py-10
                     h-fit max-w-[2000px]
                      w-full flex flex-col justify-between items-center bg-white"
        >
          <AnimationBottom>
       <Title1Amatic titleText={titleText} title2Text={title1Text} title1Text={title2Text}/>
       </AnimationBottom>
      <SmallHeader buttonText={buttonText} text={text} link={link} target={target} />
      </div>
  );
};

export default BgImage1box;
