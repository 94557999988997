import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState, useRef, useEffect } from "react";
const itemVariants = {
    open: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 300, damping: 24 },
    },
    closed: {
      opacity: 0,
      y: 20,
      transition: { duration: 0.2 },
    },
  };

const ButtonExpand =()=>{
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();
    const languageRef = useRef(null);
    
    const handleLinkClick = () => {
      setIsOpen(false);
    };
    useEffect(() => {
      function handleClickOutside(event) {
        if (languageRef.current && !languageRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
  
      document.addEventListener('mousedown', handleClickOutside);
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
  
    return (
        <motion.div
        onClick={() => setIsOpen(!isOpen)}
        ref={languageRef}
 
          initial={false}
          animate={isOpen ? "open" : "closed"}
          className="flex-col font-lexendbold tracking-wider
                     bg-green  xxs:mb-36 lg:mb-28 relative
                    xxs:w-[250px] sm:w-[250px]   xl:w-[250px]  xxl:w-[300px]   box-border  
                     text-white rounded-lg "
        >
          <motion.button
            className=" xxs:w-[250px] sm:w-[250px]   xl:w-[250px]  xxl:w-[300px] box-border  flex justify-center  items-center border-b border-white
                      xxl:text-lg xl:text-lg lg:text-sm xxs:text-sm 
                      xxs:py-[9px]  sm:py-[8px] md:py-[8px]  lg:py-2 xl:py-[7px]  xxl:py-3 "
          >
            {t("Join us")}
          </motion.button>

          <motion.ul
            className="absolute rounded-b-lg xxs:w-[250px] sm:w-[250px] xl:w-[250px] xxl:w-[300px]  box-border    text-white z-10 "
            variants={{
              open: {
                backgroundColor: "#2D604D",
                color: "white",
                transition: {
                  type: "spring",
                  bounce: 0,
                  duration: 0.7,
                  delayChildren: 0.3,
                  staggerChildren: 0.05,
                },
              },
              closed: {
                color: "#2D604D",
                transition: {
                  type: "spring",
                  bounce: 0,
                  duration: 0.4,
                },
              },
            }}
          >
            <motion.li
              className="list flex-col  text-s tracking-wider text-center  hover:text-green hover:bg-light-green hover:rounded-b-lg whitespace-nowrap"
              variants={itemVariants}
            >
              <Link to="/becomeacooperant" className="hover:text-black" onClick={handleLinkClick}>
                {t('As a cooperant')}
              </Link>
              <Link to="/farmer" className="hover:text-black" onClick={handleLinkClick}>
              {t('As a single producer')}
              </Link>
              <Link to="/shop" className="hover:text-black" onClick={handleLinkClick}>
               { t('As a shop/hub')}
              </Link>
              <Link to="/restaurant" className="hover:text-black" onClick={handleLinkClick}>
               { t('As a restaurant')}
              </Link>
              <Link to="/wholesaler" className="hover:text-black" onClick={handleLinkClick}>
               { t('As a wholesaler')}
              </Link>
            </motion.li>

          </motion.ul>
        </motion.div>
        
    )
}

export default ButtonExpand