import Title1Amatic from "../components/Title2Amatic";
import Title2Amatic from "../components/Title3Amatic";
import Container from "../components/Container";
import { Table } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import ButtonGreen from "../components/ButtonGreen";
import { useTranslation } from "react-i18next";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const Rates = () => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState("https://wiki.linkedfarm.eu/#/page/617/en_EN");


  useEffect(() => {
    const language = i18n.language;
    let x;

    if (language === "fr") {
      x = "https://wiki.linkedfarm.eu/#/page/617/fr_FR";
    } else if (language === "nl") {
      x = "https://wiki.linkedfarm.eu/#/page/617/nl_BE";
    } else {
      x = "https://wiki.linkedfarm.eu/#/page/617/en_EN";
    }
    setCurrentLanguage(x);
  }, [i18n.language]);


  return (
    <div className="font-lexend normal-case flex flex-col items-center w-full text-base">
      <Container>
        <Title1Amatic titleText={t("How much does it cost to join the Linked.Farm family ?")} />

        <div className="flex w-full">
          <ul className="text-justify w-full mb-5 list-none">
            <li className="font-lexend">
              {t('If you are a producer connecting to an existing hub, nothing.')}
            </li>

            <br />
            <li className="font-lexend ">
              {t('If you want your own webshop, we always ask you to become a cooperant first. There are two options:')}
            </li>

            <br />
            <div className="justify-between w-full h-fit flex md:flex-row xxs:flex-col md:items-start xxs:items-center">
              <div className="md:w-1/2 xxs:w-full h-full flex flex-col justify-between md:pr-5 xxs:pr-0">
                <Title2Amatic titleText={t("Single producer")} />
                <li className="font-lexend mb-10">
                  {t('You only manage your own online store with your own products, prices, stock,…')}
                </li>
                <div className="flex w-full justify-center">
                  <ButtonGreen link={"/startsingleproducer"} text={t("Get started")} />
                </div>
                <FontAwesomeIcon
                  icon={faArrowDown}
                  className="text-green mt-5 mb-16"
                  beat
                  size="2xl"
                />

                <Title1Amatic titleText={t('See our rates & pricing')} />

              </div>
              <div className="md:w-1/2 xxs:w-full flex flex-col justify-between md:pl-5 xxs:pls-0">
                <Title2Amatic titleText={t("Multiple producers")} />
                <li className="font-lexend mb-10">
                  {t('If you want to start a hub where multiple producers manage their own products and all products have to be bundled and delivered or picked up together, you fall under the category Multiple producers (=Hub). Within that category, you can choose how you want to start: either with a higher entry fee (€ 8.000) and a lower percentage on turnover (2%) (=Think Big Hub), or without an entry fee but with a degressive percentage on turnover (start 4%) (=Start Small Hub).')}
                  <br />
                  {t('A hub can work as a retailer (retail hub - reseller between farmer and customer) or can facilitate direct sales from farmers to customers (facilitation hub). More info on our')} {" "}
                  <Link target="_blank"
                    className="text-green underline"
                    to={currentLanguage}
                  >
                    wiki
                  </Link>
                </li>
                <div className="flex w-full justify-center">
                  <ButtonGreen link={"/startahub"} text={t("Get started")} />
                </div>
              </div>
            </div>
          </ul>
        </div>

        <Table className="font-lexend  tracking-tighter drop-shadow-s xxs:text-xs  sm:text-xs md:text-s mb-5 w-full">
          <Table.Head className="hyphens-none  normal-case">
            <Table.HeadCell className=" md:w-1/2 xxs:w-1/4 md:p-3 xxs:p-0 sm:p-2 text-center "></Table.HeadCell>

            <Table.HeadCell className="md:p-3 xxs:p-0 font-amatic sm:p-2 xxs:text-base md:text-xl tracking-wide bg-white md:rounded-tl-xl xxs:rounded-tl-md  text-green text-center">
              {t('One producer')}
            </Table.HeadCell>

            <Table.HeadCell
              colSpan="2"
              className="md:p-3 xxs:p-0 font-amatic sm:p-2 xxs:text-base md:text-xl tracking-wide bg-white md:rounded-tr-xl xxs:rounded-tr-md  text-green text-center  "
            >
              {t('Multiple Producers')}
            </Table.HeadCell>
          </Table.Head>
          <Table.Head className="hyphens-none normal-case xxs:text-xs  py-5  sm:text-xs md:text-s  text-white   ">
            <Table.HeadCell className="xxs:p-0 xs:p-0 s:p-0  bg-green md:rounded-tl-xl xxs:rounded-tl-md  text-center  ">

            </Table.HeadCell>

            <Table.HeadCell className="xxs:p-0 xs:p-0 s:p-0 hyphens-none    px-2  font-lexend   bg-green text-center ">
              Single producer
            </Table.HeadCell>

            <Table.HeadCell className="xxs:p-0 xs:p-0 s:p-0 font-lexend  hyphens-none  px-2   bg-green text-center normal">
              Start small hub
            </Table.HeadCell>

            <Table.HeadCell className="xxs:p-0 xs:p-0 s:p-0 font-lexend  bg-green text-center">
              Think big hub
            </Table.HeadCell>
          </Table.Head>

          <Table.Body className="divide-y  text-center">
            <Table.Row className="hover:bg-light-green bg-white">
              <Table.Cell className="  md:rounded-tl-xl xxs:rounded-tl-md text-right text-green">
                {t('[Monthly] % on the revenue')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2 xxs:text-s xs:text-s text-base normal text-center">
                0%
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2  xxs:text-s xs:text-s text-base text-center">
                4-2% *
                <br />
                <i>min €50, max €1200</i>
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2 xxs:text-s xs:text-s text-base  text-center">
                2-1% **
                <br />
                <i>min €50, max €1200</i>
              </Table.Cell>
            </Table.Row>

            <Table.Row className="hover:bg-light-green bg-white ">
              <Table.Cell className=" text-right text-green ">
                {t('[Monthly] Fixed monthly cost')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2 xxs:text-s xs:text-s text-base  text-center">
                €40
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2 xxs:text-s xs:text-s text-base   text-center">
                €0
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2 xxs:text-s xs:text-s text-base   text-center">
                €0
              </Table.Cell>
            </Table.Row>

            <Table.Row className="hover:bg-light-green bg-white ">
              <Table.Cell className=" text-right text-green ">
                {t('[Monthly] Domain name & Google Workspace account')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2 xxs:text-s xs:text-s text-base  text-center">
                €10
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2 xxs:text-s xs:text-s text-base   text-center">
                €10
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2 xxs:text-s xs:text-s text-base   text-center">
                €10
              </Table.Cell>
            </Table.Row>

            <Table.Row className="hover:bg-light-green bg-white ">
              <Table.Cell className="  text-right text-green ">
                {t('[One-off] Startup Cost')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2 xxs:text-s xs:text-s text-base  text-center">
                €0
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2 xxs:text-s xs:text-s text-base  text-center">
                €0
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2 xxs:text-s xs:text-s text-base  text-center">
                €8.000
              </Table.Cell>
            </Table.Row>

            <Table.Row className="hover:bg-light-green bg-white ">
              <Table.Cell className="  text-right text-green ">
                {t('[One-off] Cooperative share')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2  xxs:text-s xs:text-s text-base text-center">
                €250
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2 xxs:text-s xs:text-s text-base  text-center">
                €250
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2 xxs:text-s xs:text-s text-base  text-center">
                €250
              </Table.Cell>
            </Table.Row>


            <Table.Row className="   text-white w-full bg-green">
              <Table.Cell className="  text-left font-lexend text-white ">
                {t('Linked.farm Services / OPTIONS')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2  text-center"></Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2  text-center"></Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2  text-center"></Table.Cell>
            </Table.Row>

            <Table.Row className=" w-full ">
              <Table.Cell className="  bg-white md:rounded-bl-xl xxs:rounded-bl-md text-right text-green">
                {t('[Unique] Page created for your website based on existing templates (about, contact,…)')} <br />{t('if you can’t do it by yourself')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:text-s xs:text-s text-base  bg-white  xxs:p-0 sm:p-2  text-center">
                €1000
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:text-s xs:text-s text-base  xxs:p-0 sm:p-2  bg-white text-center">
                €1000
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:text-s xs:text-s text-base xxs:p-0 sm:p-2  md:rounded-br-xl xxs:rounded-br-md  bg-white  text-center">
                €0
              </Table.Cell>
            </Table.Row>

            <Table.Row className=" w-full ">
              <Table.Cell className="  bg-white md:rounded-bl-xl xxs:rounded-bl-md text-right text-green">
                {t('[Purchasing] module for your own farm shop')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:text-s xs:text-s text-base  bg-white  xxs:p-0 sm:p-2  text-center">
                €0
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:text-s xs:text-s text-base  xxs:p-0 sm:p-2  bg-white text-center">
                €0
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:text-s xs:text-s text-base xxs:p-0 sm:p-2  md:rounded-br-xl xxs:rounded-br-md  bg-white  text-center">
                €0
              </Table.Cell>
            </Table.Row>

            <Table.Row className=" w-full ">
              <Table.Cell className="  bg-white md:rounded-bl-xl xxs:rounded-bl-md text-right text-green">
                {t('[Purchasing] hub for your store, kitchen, organisation, ...')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:text-s xs:text-s text-base  bg-white  xxs:p-0 sm:p-2  text-center">
                <Link to="/contact" className="underline ">
                  {t('Contact us')}
                </Link>
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:text-s xs:text-s text-base  xxs:p-0 sm:p-2  bg-white text-center">
                <Link to="/contact" className="underline ">
                  {t('Contact us')}
                </Link>
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:text-s xs:text-s text-base xxs:p-0 sm:p-2  md:rounded-br-xl xxs:rounded-br-md  bg-white  text-center">
                <Link to="/contact" className="underline ">
                  {t('Contact us')}
                </Link>
              </Table.Cell>
            </Table.Row>

          </Table.Body>
        </Table>

        <div className="flex justify-beetween xxs:items-center md:items-start xxs:flex-col md:flex-row xxs:text-xs mb-10 sm:text-xs md:text-s">
          <div className="xxs:w-full md:w-1/2 xxs:pr-0 md:pr-5 xxs:mb-5 md:mb-0">
            <p className="font-lexend mb-2 text-green text-left w-full">
              {t('* Degressive based on turnover per quarter:')}  <br />{t('Minimum monthly cost of € 50. Maximum of € 1200.')}
            </p>
            <Table className="mb-5 font-lexend drop-shadow-s xxs:text-xs sm:text-xs md:text-s w-full">
              <Table.Head className="hyphens-none  normal-case    w-full text-white ">
                <Table.HeadCell className="md:p-3  bg-green md:rounded-tl-xl xxs:rounded-tl-md font-lexend xxs:p-0 sm:p-2 normal text-center ">
                  {t('* From')}
                </Table.HeadCell>
                <Table.HeadCell className="md:p-3 xxs:p-0 sm:p-2 font-lexend bg-green text-center normal">
                  {t('* Till')}
                </Table.HeadCell>
                <Table.HeadCell className="md:p-3 xxs:p-0 font-lexend bg-green md:rounded-tr-xl xxs:rounded-tr-md sm:p-2 text-center normal">
                  %
                </Table.HeadCell>
              </Table.Head>

              <Table.Body className="divide-y w-full text-center">
                <Table.Row className="hover:bg-light-green bg-white">
                  <Table.Cell className="md:p-3 xxs:text-s xs:text-s xxs:p-0 text-base sm:p-2  text-center">
                    € 0
                  </Table.Cell>
                  <Table.Cell className="md:p-3 xxs:text-s xs:text-s xxs:p-0 text-base sm:p-2  text-center">
                    € 30.000
                  </Table.Cell>
                  <Table.Cell className="md:p-3 xxs:text-s xs:text-s xxs:p-0 sm:p-2  text-base text-center">
                    4 %
                  </Table.Cell>
                </Table.Row>

                <Table.Row className="hover:bg-light-green bg-white ">
                  <Table.Cell className="md:p-3 xxs:text-s xs:text-s xxs:p-0 sm:p-2 text-base  text-center">
                    € 30.000
                  </Table.Cell>
                  <Table.Cell className="md:p-3 xxs:text-s xs:text-s xxs:p-0 sm:p-2 text-base  text-center">
                    € 45.000
                  </Table.Cell>
                  <Table.Cell className="md:p-3 xxs:text-s xs:text-s xxs:p-0 sm:p-2 text-base  text-center">
                    3,5 %
                  </Table.Cell>
                </Table.Row>

                <Table.Row className="hover:bg-light-green bg-white ">
                  <Table.Cell className="md:p-3 xxs:text-s xs:text-s xxs:p-0 sm:p-2 text-base  text-center">
                    € 45.000
                  </Table.Cell>
                  <Table.Cell className="md:p-3 xxs:text-s xs:text-s xxs:p-0 sm:p-2 text-base  text-center">
                    € 60.000
                  </Table.Cell>
                  <Table.Cell className="md:p-3 xxs:text-s xs:text-s xxs:p-0 sm:p-2 text-base  text-center">
                    3 %
                  </Table.Cell>
                </Table.Row>

                <Table.Row className="hover:bg-light-green bg-white ">
                  <Table.Cell className="md:p-3 xxs:text-s xs:text-s xxs:p-0 sm:p-2 text-base  text-center">
                    € 60.000
                  </Table.Cell>
                  <Table.Cell className="md:p-3 xxs:text-s xs:text-s xxs:p-0 sm:p-2 text-base  text-center">
                    € 75.000
                  </Table.Cell>
                  <Table.Cell className="md:p-3 xxs:text-s xs:text-s xxs:p-0 sm:p-2 text-base  text-center">
                    2,5 %
                  </Table.Cell>
                </Table.Row>

                <Table.Row className=" w-full ">
                  <Table.Cell className="md:p-3 xxs:text-s xs:text-s bg-white md:rounded-bl-xl text-base  xxs:rounded-bl-md xxs:p-0 sm:p-2  text-center">
                    € 75.000
                  </Table.Cell>
                  <Table.Cell className="md:p-3 xxs:text-s xs:text-s bg-white xxs:p-0 sm:p-2 text-base  text-center">
                    -
                  </Table.Cell>
                  <Table.Cell className="md:p-3  bg-white md:rounded-br-xl xxs:text-s xs:text-s text-base  xxs:rounded-br-md xxs:p-0 sm:p-2  text-center">
                    2 %
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <p className="font-lexend mb-5 text-justify ">
              {t("Every quarter, a commission invoice is generated for the platform based on the turnover of the past 3 months. Example: With a quarterly turnover of € 105,000 you pay 4% on the bracket of € 30,000, 3.5% on € 15,000, 3% on € 15,000, 2.5% on € 15,000 and 2% on € 30,000 so a commission of € 3,150. Minimum monthly cost of € 50. Maximum of € 1200")}
            </p>
            <p className="mb-10 font-lexend text-left w-full">
              {t("All of our prices are excl. VAT")}
            </p>
          </div>

          <div className="xxs:w-full md:w-1/2 xxs:pl-0 md:pl-5">
            <p className="font-lexend mb-2 text-green text-left w-full">
              *{t("* Degressive based on turnover per quarter:")} <br />  {t("Minimum monthly cost of € 50. Maximum of € 1200.")}
            </p>

            <Table className="font-lexend drop-shadow-s  xxs:text-xs mb-10 sm:text-xs md:text-s w-full">
              <Table.Head className="hyphens-none  normal-case    w-full text-white ">
                <Table.HeadCell className="md:p-3 xxs:p-0 font-lexend sm:p-2 md:rounded-tl-xl xxs:rounded-tl-md   bg-green text-center ">
                  *{t('* From')}
                </Table.HeadCell>
                <Table.HeadCell className="md:p-3 xxs:p-0 font-lexend bg-green sm:p-2 text-center normal">
                  *{t('* Till')}
                </Table.HeadCell>
                <Table.HeadCell className="md:p-3 xxs:p-0 font-lexend md:rounded-tr-xl xxs:rounded-tr-md  bg-green sm:p-2 text-center normal">
                  %
                </Table.HeadCell>
              </Table.Head>

              <Table.Body className="divide-y w-full text-center">
                <Table.Row className="hover:bg-light-green ">
                  <Table.Cell className="md:p-3 xxs:p-0 sm:p-2 xxs:text-s xs:text-s text-base  bg-white text-center">
                    € 0
                  </Table.Cell>
                  <Table.Cell className="md:p-3 bg-white xxs:text-s xs:text-s text-base  xxs:p-0 sm:p-2  text-center">
                    € 75.000
                  </Table.Cell>
                  <Table.Cell className="md:p-3 bg-white xxs:text-s xs:text-s text-base   xxs:p-0 sm:p-2  text-center">
                    2 %
                  </Table.Cell>
                </Table.Row>

                <Table.Row className="hover:bg-light-green  ">
                  <Table.Cell className="md:p-3 bg-white xxs:p-0 sm:p-2 xxs:text-s xs:text-s text-base text-center">
                    € 75.000
                  </Table.Cell>
                  <Table.Cell className="md:p-3 xxs:p-0 bg-white  sm:p-2 xxs:text-s xs:text-s text-base text-center">
                    € 150.000
                  </Table.Cell>
                  <Table.Cell className="md:p-3 xxs:p-0 bg-white  sm:p-2 xxs:text-s xs:text-s text-base text-center">
                    1,5 %
                  </Table.Cell>
                </Table.Row>

                <Table.Row className="">
                  <Table.Cell className="md:p-3 xxs:p-0 sm:p-2 md:rounded-bl-xl xxs:text-s xs:text-s text-base xxs:rounded-bl-md  bg-white  text-center">
                    € 150.000
                  </Table.Cell>
                  <Table.Cell className="md:p-3 bg-white  xxs:p-0 sm:p-2 xxs:text-s xs:text-s text-base text-center">
                    -
                  </Table.Cell>
                  <Table.Cell className="md:p-3 xxs:p-0 sm:p-2 md:rounded-br-xl xxs:text-s xs:text-s text-base xxs:rounded-br-md  bg-white  text-center">
                    1 %
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <p className="font-lexend mb-5 text-justify ">
              {t("Every quarter, a commission invoice is generated for the platform based on the turnover of the past 3 months. Example: with a quarterly turnover of € 105.000 you pay 2% on the bracket of € 75.000, 1.5% on € 30.000, so a commission of €1.950. Minimum monthly cost of € 50. Maximum of € 1.200.")}
            </p>
            <p className="mb-10 font-lexend text-left w-full">
              {t("All of our prices are excl. VAT")}
            </p>
          </div>
        </div>

        <Title2Amatic titleText={t('Included Features')} />

        {/*GENERAL*/}
        <Table className="font-lexend drop-shadow-s xxs:text-xs  sm:text-xs md:text-s mb-10  w-full">
          <Table.Head className="hyphens-none  normal-case  ">
            <Table.HeadCell className=" md:w-1/2 xxs:w-1/4 md:p-3 xxs:p-0 sm:p-2  text-center ">

            </Table.HeadCell>
            <Table.HeadCell className="md:p-3 xxs:p-0 font-amatic sm:p-2 xxs:text-base md:text-xl tracking-wide bg-white md:rounded-tl-xl xxs:rounded-tl-md  text-green text-center ">
              {t('One producer')}
            </Table.HeadCell>
            <Table.HeadCell
              colSpan="2"
              className="md:p-3 xxs:p-0 font-amatic sm:p-2 xxs:text-base md:text-xl tracking-wide bg-white md:rounded-tr-xl xxs:rounded-tr-md  text-green text-center  "
            >
              {t('Multiple Producers')}
            </Table.HeadCell>
          </Table.Head>

          <Table.Head className="hyphens-none  normal-case   xxs:text-xs  py-5  sm:text-xs md:text-s  text-white   ">
            <Table.HeadCell className="  bg-green md:rounded-tl-xl xxs:rounded-tl-md font-lexend text-left  ">
              {t('GENERAL')}
            </Table.HeadCell>
            <Table.HeadCell className=" xxs:p-0 xs:p-0 s:p-0 font-lexend   bg-green text-center ">
              Single producer
            </Table.HeadCell>
            <Table.HeadCell className="xxs:p-0 xs:p-0 s:p-0 font-lexend    bg-green text-center normal">
              Start small hub
            </Table.HeadCell>
            <Table.HeadCell className="xxs:p-0 xs:p-0 s:p-0 font-lexend   bg-green text-center normal">
              Think big hub
            </Table.HeadCell>
          </Table.Head>

          <Table.Body className="divide-y text-center">
            <Table.Row className="hover:bg-light-green bg-white">
              <Table.Cell className="  text-right text-green">
                {t('Direct start-up of your webshop for online sales (receiving orders)')}
              </Table.Cell>
              <Table.Cell className=" xxs:p-0 xs:p-0 s:p-0 md:p-3  sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className=" md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className="hover:bg-light-green bg-white ">
              <Table.Cell className=" text-right text-green ">
                {t('Define your own margin')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className="hover:bg-light-green bg-white ">
              <Table.Cell className="  text-right text-green ">
                {t('Sell to private individuals and to companies (B2C and B2B)')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className="hover:bg-light-green bg-white ">
              <Table.Cell className="  text-right text-green ">
                {t('Sell under your own name')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 xs:p-0 s:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className="hover:bg-light-green  text-green bg-white w-full  ">
              <Table.Cell className="  text-right text-green ">
                {t('Multilingual')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className="hover:bg-light-green  w-full bg-white   ">
              <Table.Cell className="  text-right text-green">
                {t('Email and domain')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className="hover:bg-light-green  w-full bg-white   ">
              <Table.Cell className="  text-right text-green">
                {t('Hosting')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className="hover:bg-light-green  w-full bg-white   ">
              <Table.Cell className="  text-right text-green">
                {t('Receive online payments via Mollie incl. Meal vouchers (cost Mollie for the user)')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className="hover:bg-light-green  w-full bg-white   ">
              <Table.Cell className="  text-green text-right">
                {t('Customers-page (B2B)')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className=" w-full  ">
              <Table.Cell className=" bg-white md:rounded-bl-xl xxs:rounded-bl-md text-green text-right">
                {t('Producers-page')}
              </Table.Cell>
              <Table.Cell className="bg-white md:p-3 xxs:p-0 sm:p-2  text-center"></Table.Cell>
              <Table.Cell className="bg-white  md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="bg-white md:rounded-br-xl xxs:rounded-br-md md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        {/*ADMIN*/}
        <Table className="font-lexend drop-shadow-s xxs:text-xs  sm:text-xs md:text-s mb-10 w-full">
          <Table.Head className="hyphens-none  normal-case  ">
            <Table.HeadCell className=" md:w-1/2 xxs:w-1/4 md:p-3 xxs:p-0 sm:p-2  text-center "></Table.HeadCell>
            <Table.HeadCell className="md:p-3 xxs:p-0 font-amatic sm:p-2 xxs:text-base md:text-xl tracking-wide bg-white md:rounded-tl-xl xxs:rounded-tl-md  text-green text-center ">
              {t('One producer')}
            </Table.HeadCell>
            <Table.HeadCell
              colSpan="2"
              className="md:p-3 xxs:p-0 font-amatic sm:p-2 xxs:text-base md:text-xl tracking-wide bg-white md:rounded-tr-xl xxs:rounded-tr-md  text-green text-center  "
            >
              {t('Multiple Producers')}
            </Table.HeadCell>
          </Table.Head>

          <Table.Head className="hyphens-none  normal-case   xxs:text-xs  py-5  sm:text-xs md:text-s  text-white   ">
            <Table.HeadCell className="font-lexend  bg-green md:rounded-tl-xl xxs:rounded-tl-md  text-left  ">
              {t('ADMIN')}
            </Table.HeadCell>

            <Table.HeadCell className="xxs:p-0 xs:p-0 s:p-0  font-lexend   bg-green text-center ">
              Single producer
            </Table.HeadCell>

            <Table.HeadCell className="xxs:p-0 xs:p-0 s:p-0 font-lexend    bg-green text-center normal">
              Start small hub
            </Table.HeadCell>

            <Table.HeadCell className=" xxs:p-0 xs:p-0 s:p-0 font-lexend   bg-green text-center normal">
              Think big hub
            </Table.HeadCell>
          </Table.Head>

          <Table.Body className="divide-y text-center">
            <Table.Row className="hover:bg-light-green bg-white">
              <Table.Cell className="  text-green text-right">
                {t('Automatic invoices')} <br />
                {t('based on packing slips')}
                <br />  {t('(producer -> customer or producer -> hub)')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className="hover:bg-light-green bg-white ">
              <Table.Cell className=" text-green text-right ">
                {t('Generate invoices for yourself')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className="hover:bg-light-green bg-white ">
              <Table.Cell className="  text-green text-right ">
                {t('Generate invoices for all famers of the hub')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center"></Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className="hover:bg-light-green bg-white ">
              <Table.Cell className="  text-green text-right ">
                {t('Allergens, nutrients,...')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className=" bg-white hover:bg-light-green  text-green  w-full  ">
              <Table.Cell className="  text-right text-green  ">
                {t('Holiday Management')} <br />
                {t('for yourself')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className=" bg-white hover:bg-light-green  w-full   ">
              <Table.Cell className="  text-green text-right">
                {t('Holiday management for all participating farmers')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center"></Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className=" bg-white hover:bg-light-green  w-full   ">
              <Table.Cell className="  text-green text-right">
                {t('Sales figures')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className=" bg-white hover:bg-light-green  w-full   ">
              <Table.Cell className="  text-green text-right">
                {t('Statistics and CRM')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className=" w-full ">
              <Table.Cell className=" bg-white md:rounded-bl-xl xxs:rounded-bl-md  text-green text-right">
                {t('Pay out producers')}
              </Table.Cell>
              <Table.Cell className=" bg-white md:p-3 xxs:p-0 sm:p-2  text-center"></Table.Cell>
              <Table.Cell className=" bg-white md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className=" bg-white md:rounded-br-xl xxs:rounded-br-md md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        {/*LOGISTICS*/}
        <Table className="font-lexend drop-shadow-s xxs:text-xs  sm:text-xs md:text-s mb-10 w-full">
          <Table.Head className="hyphens-none  normal-case  ">
            <Table.HeadCell className=" md:w-1/2 xxs:w-1/4 md:p-3 xxs:p-0 sm:p-2  text-center "></Table.HeadCell>
            <Table.HeadCell className="md:p-3 xxs:p-0 font-amatic sm:p-2 xxs:text-base md:text-xl tracking-wide bg-white md:rounded-tl-xl xxs:rounded-tl-md  text-green text-center ">
              {t('One producer')}
            </Table.HeadCell>
            <Table.HeadCell
              colSpan="2"
              className="md:p-3 xxs:p-0 font-amatic sm:p-2 xxs:text-base md:text-xl tracking-wide bg-white md:rounded-tr-xl xxs:rounded-tr-md  text-green text-center  "
            >
              {t('Multiple Producers')}
            </Table.HeadCell>
          </Table.Head>

          <Table.Head className="hyphens-none  normal-case   xxs:text-xs  py-5  sm:text-xs md:text-s  text-white   ">
            <Table.HeadCell className=" font-lexend bg-green md:rounded-tl-xl xxs:rounded-tl-md  text-left ">
              {t('LOGISTICS')}
            </Table.HeadCell>
            <Table.HeadCell className="xxs:p-0 xs:p-0 s:p-0  font-lexend   bg-green text-center ">
              Single producer
            </Table.HeadCell>

            <Table.HeadCell className="xxs:p-0 xs:p-0 s:p-0 font-lexend    bg-green text-center normal">
              Start small hub
            </Table.HeadCell>

            <Table.HeadCell className="xxs:p-0 xs:p-0 s:p-0 font-lexend   bg-green text-center normal">
              Think big hub
            </Table.HeadCell>
          </Table.Head>

          <Table.Body className="divide-y text-center">
            <Table.Row className="bg-white hover:bg-light-green hover:text-green">
              <Table.Cell className="  text-green text-right">
                {t('Packing slips (automatically in your mailbox, with QR code)')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>
            <Table.Row className="hover:bg-light-green bg-white ">
              <Table.Cell className=" text-green text-right ">
                {t('Stock management and price management of your own products')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>
            <Table.Row className="hover:bg-light-green bg-white ">
              <Table.Cell className="  text-green text-right ">
                {t('Multiple farmers have access to manage prices, stock,…')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center"></Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>
            <Table.Row className="hover:bg-light-green bg-white ">
              <Table.Cell className="  text-green text-right ">
                {t('Mutliple farmers automatically get their packing slips & invoices')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center"></Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>
            <Table.Row className="bg-white hover:bg-light-green  text-green w-full  ">
              <Table.Cell className="  text-green text-right ">
                {t('Labels based on packing slips (QR code)')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>
            <Table.Row className="bg-white hover:bg-light-green  w-full   ">
              <Table.Cell className="  text-green text-right">
                {t('Multiple delivery days possible per week')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>
            <Table.Row className="bg-white hover:bg-light-green  w-full   ">
              <Table.Cell className="  text-green text-right">
                {t('Change and recalculate products based on actual delivery or weight')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>
            <Table.Row className="bg-white hover:bg-light-green  w-full   ">
              <Table.Cell className="  text-green text-right">
                {t('Reusable packaging 0% VAT (and rotation)')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>
            <Table.Row className="bg-white hover:bg-light-green  w-full   ">
              <Table.Cell className="  text-green text-right">
                {t('User friendly order picking')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>
            <Table.Row className=" w-full ">
              <Table.Cell className=" bg-white md:rounded-bl-xl xxs:rounded-bl-md text-green text-right">
                {t('Route planner (Route XL integration)')}
              </Table.Cell>
              <Table.Cell className="bg-white md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="bg-white md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="bg-white md:rounded-br-xl xxs:rounded-br-md md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        {/*MARKETING*/}
        <Table className="font-lexend drop-shadow-s xxs:text-xs  sm:text-xs md:text-s mb-10 w-full">
          <Table.Head className="hyphens-none  normal-case  ">
            <Table.HeadCell className=" md:w-1/2 xxs:w-1/4 md:p-3 xxs:p-0 sm:p-2  text-center "></Table.HeadCell>
            <Table.HeadCell className="md:p-3 xxs:p-0 font-amatic sm:p-2 xxs:text-base md:text-xl tracking-wide bg-white md:rounded-tl-xl xxs:rounded-tl-md  text-green text-center ">
              {t('One producer')}
            </Table.HeadCell>
            <Table.HeadCell
              colSpan="2"
              className="md:p-3 xxs:p-0 font-amatic sm:p-2 xxs:text-base md:text-xl tracking-wide bg-white md:rounded-tr-xl xxs:rounded-tr-md  text-green text-center  "
            >
              {t('Multiple Producers')}
            </Table.HeadCell>
          </Table.Head>

          <Table.Head className="hyphens-none  normal-case   xxs:text-xs  py-5  sm:text-xs md:text-s  text-white   ">
            <Table.HeadCell className=" font-lexend bg-green md:rounded-tl-xl xxs:rounded-tl-md  text-left  ">
              MARKETING
            </Table.HeadCell>

            <Table.HeadCell className="xxs:p-0 xs:p-0 s:p-0  font-lexend   bg-green text-center ">
              Single producer
            </Table.HeadCell>

            <Table.HeadCell className="xxs:p-0 xs:p-0 s:p-0 font-lexend    bg-green text-center normal">
              Start small hub
            </Table.HeadCell>

            <Table.HeadCell className="xxs:p-0 xs:p-0 s:p-0 font-lexend   bg-green text-center normal">
              Think big hub
            </Table.HeadCell>
          </Table.Head>

          <Table.Body className="divide-y w-full">
            <Table.Row className="hover:bg-light-green bg-white">
              <Table.Cell className="   text-green text-right">
                {t('Gift cards / vouchers / Tell-a-friend')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className="hover:bg-light-green bg-white ">
              <Table.Cell className=" text-green text-right ">
                {t('Promotional messages')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className="hover:bg-light-green bg-white ">
              <Table.Cell className="  text-green text-right ">
                {t("Volume / Client discounts")}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className="hover:bg-light-green bg-white ">
              <Table.Cell className="  text-green text-right ">
                {t('Link to Mailchimp')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className="hover:bg-light-green  bg-white text-green w-full  ">
              <Table.Cell className="  text-green text-right ">
                {t('Link to Social media')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className="hover:bg-light-green  bg-white  w-full   ">
              <Table.Cell className="  text-green text-right">
                {t('Generate marketing (Point of sales) for in-store material')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className="hover:bg-light-green  bg-white w-full   ">
              <Table.Cell className="  text-green text-right">
                {t('Food score')}
                <br />  {t('(food mileage, freshness,...)')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className=" w-full  ">
              <Table.Cell className="  md:rounded-bl-xl xxs:rounded-bl-md bg-white text-green text-right">
                SEO
                <br /> (Facebook pixel, Google analytics)
              </Table.Cell>
              <Table.Cell className=" bg-white md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className=" bg-white md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className=" bg-white md:rounded-br-xl xxs:rounded-br-md md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        {/*SELLING*/}
        <Table className="font-lexend drop-shadow-s xxs:text-xs  sm:text-xs md:text-s mb-10 w-full">
          <Table.Head className="hyphens-none  normal-case  ">
            <Table.HeadCell className=" md:w-1/2 xxs:w-1/4 md:p-3 xxs:p-0 sm:p-2  text-center "></Table.HeadCell>
            <Table.HeadCell className="md:p-3 xxs:p-0 font-amatic sm:p-2 xxs:text-base md:text-xl tracking-wide bg-white md:rounded-tl-xl xxs:rounded-tl-md  text-green text-center ">
              {t('One producer')}
            </Table.HeadCell>
            <Table.HeadCell
              colSpan="2"
              className="md:p-3 xxs:p-0 font-amatic sm:p-2 xxs:text-base md:text-xl tracking-wide bg-white md:rounded-tr-xl xxs:rounded-tr-md  text-green text-center  "
            >
              {t('Multiple Producers')}
            </Table.HeadCell>
          </Table.Head>

          <Table.Head className="hyphens-none  normal-case   xxs:text-xs  py-5  sm:text-xs md:text-s  text-white   ">
            <Table.HeadCell className=" font-lexend bg-green md:rounded-tl-xl xxs:rounded-tl-md  text-left  ">
              {t('SELLING')}
            </Table.HeadCell>

            <Table.HeadCell className=" xxs:p-0 xs:p-0 s:p-0 font-lexend  bg-green text-center ">
              Single producer
            </Table.HeadCell>

            <Table.HeadCell className="xxs:p-0 xs:p-0 s:p-0 font-lexend   bg-green text-center normal">
              Start small hub
            </Table.HeadCell>

            <Table.HeadCell className=" xxs:p-0 xs:p-0 s:p-0 font-lexend   bg-green text-center normal">
              Think big hub
            </Table.HeadCell>
          </Table.Head>

          <Table.Body className="divide-y w-full text-center">
            <Table.Row className="hover:bg-light-green bg-white">
              <Table.Cell className="text-right  text-green   ">
                {t('Electronic customer wallet')} <br />
                {t('(the use of online balance for refunds, …)')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center  text-green   ">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className="hover:bg-light-green bg-white  text-green  ">
              <Table.Cell className=" text-green text-right     ">
                {t('Link groups to users and businesses')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center   ">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className="hover:bg-light-green bg-white ">
              <Table.Cell className="    text-green text-right ">
                {t('Repeat-orders')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2    text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className="hover:bg-light-green bg-white ">
              <Table.Cell className="  text-green text-right ">
                {t('Favourites')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className="hover:bg-light-green bg-white text-green w-full  ">
              <Table.Cell className="  text-green text-right ">
                {t('Order from recipes')}
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>

            <Table.Row className=" w-full ">
              <Table.Cell className=" bg-white md:rounded-bl-xl xxs:rounded-bl-md text-green text-right">
                {t('Integration with the cashier desk')}
                <br />  {t('of Touch Systems or Microconcept')}
              </Table.Cell>
              <Table.Cell className="bg-white md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="bg-white md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
              <Table.Cell className="bg-white md:rounded-br-xl xxs:rounded-br-md md:p-3 xxs:p-0 sm:p-2  text-center">
                <FontAwesomeIcon icon={faCheck} />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Container>
    </div>
  );
};
export default Rates;
