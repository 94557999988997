import React from 'react';

const Title1AmaticGreen= ({titleText, title1Text,title2Text}) => {
  return (
    <div className=" hyphens-none
                      flex justify-center  w-full
                      text-green font-amatic sm:mb-10 xxs:mb-5  s:mb-5
      ">
    <h1 className="  xl:text-[70px] lg:text-[55px] md:text-[50px] sm:text-[45px] s:text-[46px] xs:text-[46px] xxs:text-[35px] 
                       xxs:text-center xs:text-center 
                       leading-tight tracking-wider 
                       xxs:mx-[3vw] sm:mx-[5vw] sm:hyphens-none "
        >
         
  {titleText} <br /> {title1Text} {title2Text} 
</h1>
</div>

    );
};

export default Title1AmaticGreen;