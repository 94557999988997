import React from "react";
import ButtonWhite from "./ButtonWhite";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Box = React.forwardRef(({ buttonText, text, link, onClick, target }, ref) => {
  return (
    <motion.div
      ref={ref}
      
      whileHover={{ scale: 1.02 }}
      transition={{ type: "spring", stiffness: 400, damping: 10 }}
    >
      <Link  target={target} onClick={onClick} to={link} className="box bg-black/30 rounded-2xl border-[1px]  text-white tracking-wide flex-col items-center justify-between drop-shadow-lg  flex
      xxs:w-[220px] xxs:h-[220px] sm:h-[220px] sm:w-[220px] md:h-[220px] md:w-[220px] lg:w-[240px] lg:h-[240px]  xl:w-[300px] xl:h-[300px] 
    lg:text-sm sm:text-sm drop-shadow-lg  backdrop-blur-s
   xl:py-8 xl:p-6 lg:py-10 lg:p-6 md:py-6 md:p-3 xxs:py-6 xxs:p-3">
      <h3 className="font-lexendbold leading-tight text-center tracking-tighter 
      xxs:text-[20px] md:text-[20px] lg:text-[20px] xl:text-[25px]">
        {text}
      </h3>
      
      <ButtonWhite text={buttonText} />
      </Link>
    </motion.div>
  );
});

export default Box;
