import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Title3Amatic from "./Title3Amatic";
import { useTranslation } from "react-i18next";

const ReusableList1 = () => {
  const { t } = useTranslation();

  return (
    <div className="xxs:flex-col xxs:items-center lg:items-start flex lg:flex-row w-full  justify-between mb-20">
      <Link className="xxs:w-[60vw] lg:w-1/4 lg:min-w-[200px] "
        to={"/become-cooperent-form"}
      >
        <motion.div
          whileHover={{ scale: 1.01 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
        >
          <Title3Amatic titleText={t("Step 1")} />
          <div
            className="flex flex-col py-2 w-full rounded-lg bg-green hover:bg-light-green  hover:text-green  border-2 border-green text-white  items-center "
          >
            <h2
              className="font-lexendbold uppercase whitespace-nowrap tracking-wide"
            >
              {t("Register")}
            </h2>
            <p className="font-lexend tracking-wider text-base xxl:text-lg "
            >
              {t("Fill out")}{" "}
            </p>
            <p
              className="font-lexend tracking-wider text-base xxl:text-lg "
            >
              {t("this")}
            </p>
            <p
              className="font-lexend tracking-wider text-base xxl:text-lg"
            >
              {t("form")}
            </p>
          </div>
        </motion.div>
      </Link>

      <div className=" xxs:rotate-90  lg:rotate-0  xxs:mb-5 mt-5 lg:mb-0 ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z" />
        </svg>
      </div>

      <div className=" xxs:w-[60vw] lg:w-1/4 lg:min-w-[200px] ">
        <div className="text-center">
          <Title3Amatic titleText={t("Step 2")} />

          <div className=" rounded-lg p-2 flex bg-light-green items-center text-green  justify-center border-2 border-green">
            <div className="w-full border-green  h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step">
              <h2 className="font-lexendbold whitespace-nowrap uppercase tracking-wider  ">
                {t("Validation")}
              </h2>
              <p className="font-lexend tracking-wider  text-base xxl:text-lg  ">
                {t("Approval")}
              </p>
              <p className="font-lexend tracking-wider  text-base xxl:text-lg  ">
                {t("by the Board")}
              </p>
              <p className="font-lexend tracking-wider text-base xxl:text-lg ">
                {t("of Directors")}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className=" xxs:rotate-90 lg:rotate-0 mt-5 xxs:mb-5 lg:mb-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z" />
        </svg>
      </div>

      <div className="xxs:w-[60vw] lg:w-1/4 lg:min-w-[200px]">
        <div className="text-center">
          <Title3Amatic titleText={t("Step 3")} />
          <div className=" rounded-lg p-2 flex bg-light-green items-center  text-green justify-center border-2 border-green">
            <div className="w-fullborder-green  h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step">
              <h2 className="font-lexendbold whitespace-nowrap uppercase tracking-wider">
                {t("Payment")}
              </h2>
              <p className="font-lexend  text-base xxl:text-lg  tracking-wider  ">
                {t("Full payment of")}
              </p>
              <p className="font-lexend tracking-wider  text-base xxl:text-lg  ">
                {t("shares =")}{" "}
              </p>
              <p className="font-lexend tracking-wider  text-base xxl:text-lg  ">
                {t("(formal start day)")}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className=" xxs:rotate-90 lg:rotate-0  xxs:mb-5 lg:mb-0 mt-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z" />
        </svg>
      </div>

      <div className="xxs:w-[60vw] lg:w-1/4 lg:min-w-[200px]">
        <div className="text-center">
          <Title3Amatic titleText={t("Step 4")} />
          <div className=" rounded-lg p-2 flex bg-light-green items-center text-green justify-center border-2 border-green">
            <div className="w-full border-green  h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step">
              <h2 className="font-lexendbold whitespace-nowrap uppercase tracking-wider">
                {t("ENROLLMENT")}
              </h2>
              <p className="font-lexend   text-base xxl:text-lg  tracking-wider  ">
                {t("You receive your")}
              </p>
              <p className="font-lexend tracking-wider  text-base xxl:text-lg ">
                {t("cooperative")}{" "}
              </p>
              <p className="font-lexend tracking-wider  text-base xxl:text-lg ">
                {t("certificate")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReusableList1;
