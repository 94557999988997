import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestion,
  faPeopleGroup,
  faHandshakeAngle,
} from "@fortawesome/free-solid-svg-icons";
import Title3Amatic from "./Title3Amatic";
import Cookies from "js-cookie";

const ReusableList = ({ linkform, pageId }) => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(
    "https://wiki.linkedfarm.eu/#/page/1047/en_EN"
  );
  const [isClicked1, setIsClicked1] = useState(
    Cookies.get(`isClicked1-${pageId}`) === "true"
  );
  const [isClicked2, setIsClicked2] = useState(
    Cookies.get(`isClicked2-${pageId}`) === "true"
  );
  const [isClicked3, setIsClicked3] = useState(
    Cookies.get(`isClicked3-${pageId}`) === "true"
  );

  const handleClick1 = () => {
    setIsClicked1(true);
    Cookies.set(`isClicked1-${pageId}`, "true", {
      expires: 1,
      sameSite: "None",
      secure: true,
    });
  };

  const handleClick2 = () => {
    setIsClicked2(true);
    Cookies.set(`isClicked2-${pageId}`, "true", {
      expires: 1,
      sameSite: "None",
      secure: true,
    });
  };

  const handleClick3 = () => {
    setIsClicked3(true);
    Cookies.set(`isClicked3-${pageId}`, "true", {
      expires: 1,
      sameSite: "None",
      secure: true,
    });
  };
  useEffect(() => {
    const language = i18n.language;
    let x;

    if (language === "fr") {
      x = "https://wiki.linkedfarm.eu/#/page/1047/fr_FR";
    } else if (language === "nl") {
      x = "https://wiki.linkedfarm.eu/#/page/1047/nl_NL";
    } else {
      x = "https://wiki.linkedfarm.eu/#/page/1047/en_EN";
    }
    setCurrentLanguage(x);
  }, [i18n.language]);

  return (
    <div className=" flex lg:flex-row xxs:flex-col w-full justify-between xxs:items-center lg:items-start mb-20">
      <Link
        className="xxs:w-[80vw] lg:max-w-[350px]  lg:w-1/3"
        to={currentLanguage}
        target={"_blank"}
      >
        <motion.div
          whileHover={{ scale: 1.02 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          onClick={handleClick1}
        >
          <Title3Amatic titleText={t("Step 1")} />

          <div
            className={`flex flex-col py-3 w-full rounded-lg bg-green hover:bg-light-green border-2 hover:text-green  border-green text-white  items-center ${
              isClicked1 ? "bg-light-green text-[#2D604D] font-lexend" : ""
            } `}
          >
            <FontAwesomeIcon icon={faQuestion} beat />

            <h2
              className={`font-lexendbold mt-1 uppercase whitespace-nowrap tracking-wider  ${
                isClicked1 ? " text-green  " : ""
              } `}
            >
              {t("How does it work?")}
            </h2>

            <div className=" hover:border-green border-white h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step">
              <p
                className={`font-lexend tracking-wider mt-1 text-base ${
                  isClicked1 ? " text-green  " : ""
                } `}
              >
                {t("Watch our demo")}
              </p>
              <p
                className={`font-lexend tracking-wider mt-1 text-base ${
                  isClicked1 ? " text-green  " : ""
                } `}
              >
                {t("to find out")}
              </p>
              <p
                className={`font-lexend tracking-wider mt-1 text-base ${
                  isClicked1 ? " text-green  " : ""
                } `}
              >
                {t("how easy")}
              </p>
              <p
                className={`font-lexend tracking-wider mt-1 text-base ${
                  isClicked1 ? " text-green  " : ""
                } `}
              >
                {t("it is")}
              </p>
            </div>
          </div>
        </motion.div>
      </Link>

      <div className=" xxs:rotate-90 lg:rotate-0 xxs:mb-5 lg:mb-0 mt-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z" />
        </svg>
      </div>

      <Link className=" xxs:w-[80vw] lg:max-w-[350px]  lg:w-1/3" to={linkform}>
        <motion.div
          whileHover={{ scale: 1.01 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          onClick={handleClick2}
        >
          <Title3Amatic titleText={t("Step 2")} />
          <div
            className={`flex flex-col py-3 w-full rounded-lg bg-green hover:bg-light-green border-2 hover:text-green  border-green text-white  items-center ${
              isClicked2 ? "bg-light-green" : ""
            } `}
          >
            <FontAwesomeIcon icon={faPeopleGroup} beat />

            <div className=" hover:border-green border-white h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step">
              <h2
                className={`font-lexendbold uppercase mt-1 whitespace-nowrap tracking-wider  ${
                  isClicked2 ? " text-green  " : ""
                } `}
              >
                {t("Join us")}
              </h2>
              <p
                className={`font-lexend tracking-wider mt-1 text-base ${
                  isClicked2 ? " text-green  " : ""
                } `}
              >
                {t("Fill out the form")}
              </p>
              <p
                className={`font-lexend tracking-wider mt-1 text-base ${
                  isClicked2 ? " text-green  " : ""
                } `}
              >
                {t("and become a")}
              </p>
              <p
                className={`font-lexend tracking-wider mt-1 text-base ${
                  isClicked2 ? " text-green  " : ""
                } `}
              >
                {t("member of the")}
              </p>
              <p
                className={`font-lexend tracking-wider mt-1 text-base ${
                  isClicked2 ? " text-green  " : ""
                } `}
              >
                {t("LF family")}
              </p>
            </div>
          </div>
        </motion.div>
      </Link>

      <div className=" xxs:rotate-90 xl:rotate-0  xxs:mb-5 lg:mb-0 mt-5 ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z" />
        </svg>
      </div>

      <Link
        className="xxs:w-[80vw] lg:max-w-[350px] box-border lg:w-1/3"
        to="mailto:support@linkedfarm.eu"
      >
        <motion.div
          whileHover={{ scale: 1.01 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          onClick={handleClick3}
        >
          <Title3Amatic titleText={t("Step 3")} />

          <div
            className={`flex flex-col py-3 w-full rounded-lg bg-green hover:bg-light-green border-2 hover:text-green  border-green text-white  items-center ${
              isClicked3 ? "bg-light-green " : ""
            } `}
          >
            <FontAwesomeIcon icon={faHandshakeAngle} beat />

            <h2
              className={`font-lexendbold mt-1 uppercase whitespace-nowrap tracking-wider  ${
                isClicked3 ? " text-green  " : ""
              } `}
            >
              {t("Get support")}
            </h2>

            <div className=" hover:border-green border-white h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step">
              <p
                className={`font-lexend tracking-wider mt-1 text-base ${
                  isClicked3 ? " text-green  " : ""
                } `}
              >
                {t("We hold your")}
              </p>
              <p
                className={`font-lexend tracking-wider mt-1 text-base ${
                  isClicked3 ? " text-green  " : ""
                } `}
              >
                {t("hand until")}
              </p>
              <p
                className={`font-lexend tracking-wider mt-1 text-base ${
                  isClicked3 ? " text-green  " : ""
                } `}
              >
                {" "}
                {t("you are ready")}
              </p>
              <p
                className={`font-lexend tracking-wider mt-1 text-base ${
                  isClicked3 ? " text-green  " : ""
                } `}
              >
                {t("to run")}
              </p>
            </div>
          </div>
        </motion.div>
      </Link>
    </div>
  );
};

export default ReusableList;
