import React from "react";

const ButtonWhite = 
    ({ text ,link, type, onClick,target}) => {
    return (
  
             <button    type={type} 
                        className="
                            box font-lexendbold flex justify-center items-center text-green bg-white shadow-sm
                              opacity-90 tracking-tighter
                            backdrop-blur-s
                       xxs:w-[200px] lg:w-[200px]  xl:w-[260px]  
                     xl:text-lg lg:text-sm xxs:text-sm px-3  hyphens-auto 
                       xxs:py-[7px] md:py-[6px]  xl:py-2 
                       xxs:rounded-lg sm:rounded-lg "
                           
                            onClick={onClick} 
            >
                {text}
                </button>                           

    );
};
export default ButtonWhite;
    