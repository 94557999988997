import React, { useEffect, useState } from "react";
import axios from "axios";
import Title1Amatic from "../components/Title1AmaticGreen";
import Container from "../components/Container";
import { useTranslation } from "react-i18next";
import Accordion from "../components/Accordion"; 
import "./Faq.css";

const FaqPage = () => {
  const [apiData, setApiData] = useState(null);
  const [accordions, setAccordions] = useState([]);
  const { i18n, t } = useTranslation();

  useEffect(() => {
    const language = i18n.language;
    let url ="https://www.linked.farm/api/v2/wiki/view/wiki.php?data=page&api=wefg496ad45afsjeg4s633924ujimshd4g56a4f13aghg56f4sa&pageID=1136&lang=" +
      (language === "fr" ? "fr_FR" : language === "nl" ? "nl_BE" : "en_US");

    axios
      .get(url)
      .then((response) => {
        setApiData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [i18n.language]);

  useEffect(() => {
    if (apiData) {
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(apiData, "text/html");
      const elements = htmlDoc.body.children;
      const newAccordions = [];

      let currentTitle = null;
      let currentContent = "";
      let currentLevel = null;

      Array.from(elements).forEach((element) => {
        if (element.tagName === "H1" || element.tagName === "H2") {
          if (currentTitle) {
            newAccordions.push({
              title: currentTitle,
              content: currentContent,
              level: currentLevel,
            });
          }
          currentTitle = element.innerHTML.replace(/&nbsp;$/, "");
          currentContent = "";
          currentLevel = element.tagName;
        } else {
          currentContent += element.outerHTML;
        }
      });

      if (currentTitle) {
        newAccordions.push({
          title: currentTitle,
          content: currentContent,
          level: currentLevel,
        });
      }

      setAccordions(newAccordions);
    }
  }, [apiData]);

  return (
    <div>
      <Container>
        <Title1Amatic titleText={t("Frequently asked questions") }/>
        <div className="faq-accordion-container">
          {accordions.map((accordion, index) =>
            accordion.level === "H1" ? (
              <div key={index}>
                <h1 className="faq-title mb-3 font-amatic text-green  xl:text-[60px] lg:text-[45px] md:text-[40px] s:text-[40px] sm:text-[40px] xs:text-[40px] xxs:text-[40px]">
                  {accordion.title}
                </h1>
                <div dangerouslySetInnerHTML={{ __html: accordion.content }} />
              </div>
            ) : (
              <Accordion
                key={index}
                title={accordion.title}
                content={accordion.content}
              />
            )
          )}
        </div>
      </Container>
    </div>
  );
};

export default FaqPage;
