import React from "react";
import Box from "./Box";

const SmallHeader1 = ({ buttonText, text, link, target }) => {
  return (
    <div
      className=" flex flex-col items-center w-full 
    xxs:px-[10vw] s:px-[10vw] sm:px-10 md:px-20 lg:px-20 xl:px-28 xxl:px-28 
    xxl:max-w-[2000px]"
    >
      <div className=" flex justify-center w-full items-center box-border">
        <Box buttonText={buttonText} link={link} text={text} target={target} />
      </div>
    </div>
  );
};

export default SmallHeader1;
