import React from 'react';

const BubbleLeft = ({ children  }) => {
  return (  
        <p className="  bg-light-green  rounded-b-3xl rounded-l-3xl text-green font-lexend 
                        xxs:w-full  md:w-[220px] lg:w-[240px]  xl:w-[280px]  xxl:w-[350px]  p-8
                        xxs:text-justify s:text-justify  text-center 
                        mb-10 "
        >
          {children}
        </p>
  );
};

export default BubbleLeft;
