import React, { useState } from "react";
import Title1Amatic from "../components/Title1AmaticGreen";
import { useForm } from "react-hook-form";
import Container from "../components/Container";
import SubmitForm from "../components/SubmitForm";
import ButtonGreen from "../components/ButtonGreen";
import Title2Amatic from "../components/Title2Amatic";
import Typeofshares from "../components/Typeofshares";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Goodtoknow from "../components/Goodtoknow";
import { Link } from "react-router-dom";

const StartSingleProducer = () => {
  const { t } = useTranslation();
  const { register } = useForm();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [addressStreet, setAddressStreet] = useState("");
  const [addressPlace, setAddressPlace] = useState("");
  const [addressPostalCode, setAddressPostalCode] = useState("");
  const [companyAddressStreet, setCompanyAddressStreet] = useState("");
  const [companyAddressPlace, setCompanyAddressPlace] = useState("");
  const [companyAddressPostalCode, setCompanyAddressPostalCode] = useState("");
  const [shareType, setShareType] = useState("B");
  const [amountShares, setAmountShares] = useState("1 €250");
  const [vatNumber, setVatNumber] = useState("");
  const [typeCooperant, setTypeCooperant] = useState("natural-person");
  const [legalForm, setlegalForm] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [nrn, setNnr] = useState("");
  const [hubType, setHubType] = useState("Single-producer");
  const [hubName, setHubName] = useState("");
  const [hubDomainName, setHubDomainName] = useState("");
  const [hubEmail, setHubEmail] = useState("");
  const [targetGroup, setTargetGroup] = useState("Dont-know-yet");
  const [language, setLanguage] = useState("");
  const [howDidYouKnowUs, setHowDidYouKnowUs] = useState("");
  const source = "StartSingleProducer";
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const today = new Date().toISOString().split("T")[0];


  const handleSubmit = () => {
    // Handle form submission
  };
  const handleCheckboxChange = (value, checked) => {
    if (checked) {
      setLanguage((prev) => [...prev, value]);
    } else {
      setLanguage((prev) => prev.filter((lang) => lang !== value));
    }
  };

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    setAmountShares(e.target.value);
  };

  const shares = [];
  for (let i = 1; i <= 20; i++) {
    const text = `${i} € ${(i * 250).toFixed(2)}`;
    shares.push(
      <option className="" key={i} value={i}>
        {text}
      </option>
    );
  }

  return (
    <Container>
      <Title1Amatic titleText={t("Start as a single producer")} />
      <Goodtoknow />

      <div className="w-full flex xxs:flex-col xxs:items-center md:items-start md:flex-row justify-between">
        <div className="text-left flex flex-col justify-center xxs:w-full md:w-1/2 mb-10 xxs:mr-0 md:mr-5  font-lexend">
          <p className="text-justify  font-lexend mb-3">
            {t(
              "Do you want to start a hub? Fill out the form below and we'll contact you as soon as possible to help you start up."
            )}
          </p>
          <FontAwesomeIcon
            icon={faArrowDown}
            className="text-green"
            beat
            size="2xl"
          />
        </div>

        <div className="text-left flex flex-col items-center xxs:w-full md:w-1/2 mb-10 xxs:ml-0 md:ml-5  font-lexend">
          <p className=" text-justify mb-10 ">
            {t(
              "If you want to find out more on our other formula’s, take a look at our Pricing page."
            )}
          </p>
          <div className="w-fit">
            <ButtonGreen link={"/rates"} text={t("Rates and pricing")} />
          </div>
        </div>
      </div>

      <div className="flex justify-between xxs:flex-col md:flex-row  md:items-start xxs:items-center mb-10">
        <SubmitForm
          onSubmit={handleSubmit}
          source={source}
          payload={{
            name,
            email,
            phone,
            typeCooperant,
            ...(typeCooperant === "natural-person" && {
              addressPlace,
              addressPostalCode,
              addressStreet,
              nrn,
            }),
            ...(typeCooperant === "company" && {
              legalForm,
              vatNumber,
              companyName,
              companyAddressStreet,
              companyAddressPlace,
              companyAddressPostalCode,
            }),
            shareType,
            amountShares,
            hubType,
            hubEmail,
            hubDomainName,
            hubName,
            targetGroup,
            language,
            howDidYouKnowUs,
            selectedDate,
          }}
        >
          <Title2Amatic titleText={t("Let's start")} />

          {/* Name */}
          <div className="flex flex-col my-2 ">
            <label className=" mb-1">{t("Full Name")} </label>

            <input
              {...register("name")}
              required
              onChange={(e) => setName(e.target.value)}
              className="flex p-2 border leading-tight shadow-inner rounded-lg  bg-grey  border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none  mb-3  "
              id="name"
              type="text"
              placeholder="Anna Jansen"
            />
          </div>

          {/*email*/}
          <div className="flex  flex-col my-2">
            <label className=" flex  mb-1">{t("Email")} </label>
            <input
              {...register("email")}
              required
              onChange={(e) => setEmail(e.target.value)}
              className="flex mb-3 p-2 border leading-tight shadow-inner rounded-lg  bg-grey  border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none  "
              id="email"
              type="email"
              placeholder="anna@gmail.com"
            />
          </div>

          {/*phone */}
          <div className="flex  flex-col my-2 ">
            <label className=" flex  mb-1">{t("Phone")} </label>
            <input
              {...register("phone")}
              required
              onChange={(e) => setPhone(e.target.value)}
              className="mb-3 flex p-2 border leading-tight shadow-inner rounded-lg  bg-grey  border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none  "
              id="phone"
              type="text"
              placeholder="0480000000"
              pattern="\d{9,}"
              title="Please enter a valid phone number with at least 9 digits"
            />
          </div>

          {/* Type Cooperant */}
          <div className="flex  flex-col my-2">
            <label className=" flex text-center  mb-1">
              {t("How do you wish to become cooperant ?")}
            </label>
            <select
              {...register("typeCooperant")}
              required
              onChange={(e) => setTypeCooperant(e.target.value)}
              id="typeCooperant"
              name="typeCooperant"
              value={typeCooperant}
              className=" bg-white border-green border mb-3 p-2 rounded-lg shadow leading-tight "
            >
              {/*<option value="already-cooperant" className="">
                {" "}
                I'm already a coöperant with B shares of Linked.Farm
              </option>*/}
              <option value="natural-person" className="">
                {t("As a natural person or as a self-employed")}
              </option>
              <option value="company" className="">
                {t("As a legal entity")}
              </option>
            </select>
          </div>

          {typeCooperant === "natural-person" && (
            <div id="naturalPersonForm" className="w-full">
              {/* nnr */}
              <div className="flex flex-col my-2 ">
                <label className=" flex mb-1" htmlFor="NRN" required>
                  {t("National Registration N°")}
                </label>
                <input
                  {...register("nnr")}
                  required
                  onChange={(e) => setNnr(e.target.value)}
                  className="mb-3 flex p-2 border leading-tight shadow-inner rounded-lg  bg-grey  border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none  "
                  id="NRN"
                  type="text"
                  placeholder="00000000000"
                  pattern="\d{11}"
                  title="Please enter a valid national registration number with 11 digits"
                />
              </div>

              {/* Address */}
              <div className="flex  flex-col my-2 ">
                <label
                  className=" flex text-start  mb-1"
                  htmlFor="address"
                  required
                >
                  {t("Address + n°")}
                </label>
                <input
                  {...register("addressStreet")}
                  required
                  onChange={(e) => setAddressStreet(e.target.value)}
                  className="mb-3 flex  p-2 border leading-tight shadow-inner rounded-lg  bg-grey  border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none  "
                  id="address"
                  type="address"
                  placeholder="123 Nieuwstraat Straat"
                />
              </div>

              <div className="flex  flex-col my-2 ">
                <label
                  className=" flex text-start  mb-1"
                  htmlFor="zip"
                  required
                >
                  {t("Zip Code")}
                </label>
                <input
                  {...register("addressPostalCode")}
                  required
                  onChange={(e) => setAddressPostalCode(e.target.value)}
                  className="mb-3 flex  p-2 border leading-tight shadow-inner rounded-lg  bg-grey  border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none  "
                  id="zip"
                  type="text"
                  placeholder="1000"
                  pattern="\d{4}"
                  title="Please enter a valid zip code number with 4 digits"
                />
              </div>

              <div className="flex  flex-col my-2  ">
                <label
                  className=" flex text-start mb-1"
                  htmlFor="city"
                  required
                >
                  {t("City")}
                </label>
                <input
                  {...register("addressPlace")}
                  required
                  onChange={(e) => setAddressPlace(e.target.value)}
                  className="mb-3 flex p-2 border leading-tight shadow-inner rounded-lg bg-grey  border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none  "
                  id="city"
                  type="text"
                  placeholder="Brussel"
                />
              </div>
            </div>
          )}

          {typeCooperant === "company" && (
            <div id="companyForm" className="w-full">
              {/* Office name */}
              <div className="flex  w-full flex-col my-2  ">
                <label className="flex  mb-1" htmlFor="companyName">
                  {t("Office name")}
                </label>

                <input
                  {...register("companyName")}
                  required
                  onChange={(e) => setCompanyName(e.target.value)}
                  className=" flex  p-2 border leading-tight shadow-inner rounded-lg  bg-grey  border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none mb-3  "
                  id="name"
                  type="text"
                  placeholder="Linked Farm"
                />
              </div>

              <div className="flex  flex-col my-2  ">
                <label className="flex text-start   mb-1" htmlFor="legalForm">
                  {t("Legal form")}
                </label>

                <input
                  {...register("legalForm")}
                  required
                  onChange={(e) => setlegalForm(e.target.value)}
                  className="  flex   p-2 border leading-tight shadow-inner rounded-lg  bg-grey  border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none mb-3  "
                  id="name"
                  type="text"
                  placeholder="BVBA, CVBA, NV, etc."
                />
              </div>

              <div className="flex  flex-col my-2  ">
                <label
                  className=" flex text-start mb-1"
                  htmlFor="vatNumber"
                  required
                >
                  {t("VAT Number")}
                </label>
                <input
                  required
                  {...register("vatNumber")}
                  onChange={(e) => setVatNumber(e.target.value)}
                  className="mb-3 text-green  flex p-2 border leading-tight shadow-inner rounded-lg  bg-grey  border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none  "
                  id="vat"
                  type="text"
                  placeholder="BEXXXXXXXXXX"
                  pattern="BE\d{10}"
                  title="Please enter a valid VAT number in the format BEXXXXXXXXXX"
                />
              </div>

              {/* Office address */}
              <div className="flex  flex-col my-2   ">
                <label className=" flex mb-1" htmlFor="address" required>
                  {t("Office Address + n°")}
                </label>
                <input
                  {...register("companyAddressStreet")}
                  required
                  onChange={(e) => setCompanyAddressStreet(e.target.value)}
                  className="mb-3 flex p-2 border leading-tight shadow-inner rounded-lg  bg-grey  border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none  "
                  id="address"
                  type="address"
                  placeholder="123 Nieuwstraat Straat"
                />
              </div>

              <div className="flex  flex-col my-2  ">
                <label className=" flex text-start   mb-1">
                  {t("Zip Code")}{" "}
                </label>
                <input
                  required
                  {...register("companyAddressPostalCode")}
                  onChange={(e) => setCompanyAddressPostalCode(e.target.value)}
                  className="mb-3  flex  p-2 border leading-tight shadow-inner rounded-lg  bg-grey  border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none  "
                  id="zip"
                  type="text"
                  placeholder="1000"
                  pattern="\d{4}"
                  title="Please enter a valid zip code number with 4 digits"
                />
              </div>

              <div className="flex  flex-col my-2  ">
                <label className=" flex text-start   mb-1">{t("City")} </label>
                <input
                  required
                  {...register("companyAddressPlace")}
                  onChange={(e) => setCompanyAddressPlace(e.target.value)}
                  className="mb-3 flex p-2 border leading-tight shadow-inner rounded-lg  bg-grey  border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none  "
                  id="city"
                  type="text"
                  placeholder="Brussel"
                />
              </div>
            </div>
          )}

          {/* Shares */}
          <div className="flex  flex-col my-2 ">
            <label className=" flex w-full  mb-1">{t("Share Type")} </label>

            <select
              {...register("shareType")}
              required
              onChange={(e) => setShareType(e.target.value)}
              id="shareType"
              className=" bg-white border-green border  p-2 rounded-lg shadow leading-tight "
            >
              <option value="B" className="">
                {t("B-Starter with digital tools-250Eur/sh")}
              </option>
            </select>

            <div className="text-green text-s">
              <p>{t("B max 20 pieces pp")} </p>
              <Link
                  /* target="_blank"*/
                href="/becomeacooperant"
                className="text-black hover:text-green text-sm mb-3 underline"
              >
                {t("More details +")}
              </Link>
            </div>
          </div>

          {/* n° Shares */}
          <div className="flex  flex-col my-2 ">
            <label className=" flex w-full  mb-1">
              {t("Number of shares")}{" "}
            </label>

            <select
              {...register("amountShares")}
              required
              className=" bg-white border-green border  p-2 rounded-lg shadow leading-tight "
              value={selectedValue}
              onChange={handleChange}
            >
              {shares}
            </select>
          </div>

          {/* Hub Type */}
          <div className="flex flex-col   my-2 ">
            <label className="mb-1" htmlFor="hubType" required>
              {t("Hub Type")}
            </label>
            <select
              {...register("hubType")}
              required
              onChange={(e) => setHubType(e.target.value)}
              id="hubType"
              className=" bg-white border-green border p-2 rounded-lg shadow leading-tight "
            >
              <option value="Single-producer" className="">
                {t("Single producer")}
              </option>
            </select>
            <p className="text-green text-s ">
              {t("Single producer (€50 / month)")}
            </p>
            <Link
               /* target="_blank"*/
              href="/rates"
              className="text-black hover:text-green text-sm underline"
            >
              {t("Find out more about other options")}
            </Link>
          </div>

          {/* Hub Name */}
          <div className="flex  flex-col my-2  ">
            <label className=" text-start mb-1">
              {t("What is the name of the hub you want to start ?")}
            </label>
            <input
              {...register("hubName")}
              required
              onChange={(e) => setHubName(e.target.value)}
              className="w-full  p-2 border leading-tight shadow-inner rounded-lg bg-grey border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none mb-1"
              id="name"
              type="text"
              placeholder="My farmer products"
            />
            <p className="text-green text-justify text-s mb-3 italic">
              {t("You can change this later")}
            </p>
          </div>

          {/* Hub Domain Name */}
          <div className="flex flex-col my-2  ">
            <label className=" mb-1" htmlFor="hubDomainName">
              {t("What is the domain name of the hub you want to start ?")}
            </label>
            <input
              {...register("hubDomainName")}
              onChange={(e) => setHubDomainName(e.target.value)}
              className="  mb-1 p-2 border leading-tight shadow-inner rounded-lg bg-grey border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none"
              id="hubDomainName"
              type="text"
              placeholder="www.myfarmerproducts.be"
              pattern="^(?:[a-z0-9](?:[a-z0-9\-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9\-]{0,61}[a-z0-9]$"
              title={t("Please enter a domain name")}
            />
            <p className="text-green text-justify text-s mb-3 italic">
              {t("If you don't know it yet, you can leave this field empty.")}
            </p>
          </div>

          {/* Hub Email */}
          <div className="flex flex-col  my-2 ">
            <label className=" mb-1">
              {t(
                "What is the email address you want to use for outgoing emails from the system (order confirmations, etc.)?"
              )}
            </label>

            <input
              {...register("hubEmail")}
              onChange={(e) => setHubEmail(e.target.value)}
              className="mb-1  p-2 border leading-tight shadow-inner rounded-lg  bg-grey  border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none  "
              id="hubEmail"
              type="email"
              placeholder="info@myfarmerproducts.eu"
            />
            <p className="text-green text-justify text-s mb-3 italic">
              {t("If you don't know, you can leave this field empty.")}
            </p>
          </div>

          {/* Target Group */}
          <div className="flex flex-col  my-2 ">
            <label className=" mb-1" htmlFor="targetGroup" required>
              {t("Target group")}
            </label>
            <select
              {...register("targetGroup")}
              required
              onChange={(e) => setTargetGroup(e.target.value)}
              id="targetGroup"
              className="flex  bg-white border-green border mb-3  p-2 rounded-lg shadow leading-tight "
            >
              <option value="Dont-know-yet" className="flex ">
                {t("Dont-know-yet")}
              </option>
              <option
                value="B2C-and-a-little-B2B"
                className="md:w-[265px] flex xxs:w-full"
              >
                {t("B2C-and-a-little-B2B")}
              </option>
              <option
                value="B2B-and-a-little-B2"
                className="md:w-[265px] flex xxs:w-full"
              >
                {t("B2B-and-a-little-B2C")}
              </option>
              <option value="Only-B2C" className="">
                {t("Only-B2C")}
              </option>
              <option value="Only-B2B" className="">
                {t("Only-B2B")}
              </option>
            </select>
          </div>

          {/* Desired Starting Date */}
          <div className="flex flex-col my-2">
            <label> {t("Desired starting date")}</label>
            <input
              {...register("selectedDate", {
                onChange: (e) => {
                  setSelectedDate(e.target.value);
                },
              })}
              type="date"
              min={today}
              required
              className="mb-1 appearance-none flex w-full  p-2 border leading-tightshadow-inner rounded-lg  bg-grey  border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none  "
              placeholder={selectedDate}
              value={selectedDate}
            />
             <p className="text-green text-justify text-s mb-3 italic">
              {t("We aim to get your hub up and running as soon as possible, but this date is not contractual. Once online, we will charge you the fixed fee of EUR 50/month min from 14 days after this date.")}
            </p>
          </div>


          {/* Languages? */}
          <div className="flex flex-col my-2">
            <label className="mb-1">
              {t("Which languages do you wish to use ?")}
            </label>

            <div className="mb-1">
              <label className="inline-flex items-center">
                <input
                  value="english"
                  type="checkbox"
                  name="languages[]"
                  onChange={(e) =>
                    handleCheckboxChange(e.target.value, e.target.checked)
                  }
                  className="mr-2"
                />
                {t("English")}
              </label>
            </div>

            <div className="mb-1">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  name="languages[]"
                  value="dutch"
                  onChange={(e) =>
                    handleCheckboxChange(e.target.value, e.target.checked)
                  }
                  className="mr-2"
                />
                {t("Dutch")}
              </label>
            </div>

            <div className="mb-1">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  name="languages[]"
                  value="french"
                  onChange={(e) =>
                    handleCheckboxChange(e.target.value, e.target.checked)
                  }
                  className="mr-2"
                />
                {t("French")}
              </label>
            </div>
            <p className="text-green text-justify text-s mb-3 italic">
              {t("Please select at least one language")}
            </p>
          </div>

          {/* How did you know us? */}
          <div className="flex flex-col my-2 mb-10  ">
            <label className=" flex mb-1">
              {t("How you heard about us ?")}
            </label>
            <input
              required
              {...register("howDidYouKnowUs")}
              className="mb-3 appearance-none flex w-full  p-2 border leading-tightshadow-inner rounded-lg  bg-grey  border-green focus:ring-green focus:ring focus:outline-none focus:border-light-green focus:none  "
              id=""
              type="text"
              placeholder={t("Please tell us")}
              onChange={(e) => setHowDidYouKnowUs(e.target.value)}
            />
          </div>
        </SubmitForm>

        <Typeofshares />
      </div>
    </Container>
  );
};

export default StartSingleProducer;
