import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import pinpickpoint from "../../assets/images/basket.png";
import pinhub from "../../assets/images/people-roof-solid.png";
import pinproducer from "../../assets/images/tractor.png";
import truck from "../../assets/images/truck.png";

const Filters = ({
  isOpen,
  setIsOpen,
  handleFiltering,
  filterRef,
  isB2BChecked,
  isB2CChecked,
  setIsB2BChecked,
  setIsB2CChecked,
  setFilterOption,
}) => {
  const { t } = useTranslation();
  const ulRef = useRef(null);
  const itemVariants = {
    open: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 300, damping: 24 },
    },
    closed: {
      opacity: 0,
      y: 20,
      transition: { duration: 0 },
    },
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        ulRef.current &&
        !ulRef.current.contains(event.target) &&
        filterRef.current &&
        !filterRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen, filterRef]);

  return (
    <motion.nav
      ref={filterRef}
      initial={false}
      animate={isOpen ? "open" : "closed"}
      className=" absolute z-10 flex flex-col right-0 w-fit items-end text-green "
    >
      <motion.button
        className="bg-white m-4 font-lexendbold xxs:px-0 s:px-4 py-2 xxs:w-[80px] s:w-[100px]"
        whileTap={{ scale: 0.97 }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <FontAwesomeIcon
          className=" text-green pr-2"
          size="lg"
          icon={faFilter}
          onClick={() => setIsOpen(!isOpen)}
        />
        {t("Sort")}
      </motion.button>

      <motion.ul
        ref={ulRef}
        name="filterMap"
        className="languages font-lexendbold flex w-fit rounded-l-3xl mr-4 rounded-br-3xl text-green z-10  text-right"
        variants={{
          open: {
            opacity: 1,
            height: "250px",
            boxShadow: "1px 1px 1px #2D604D",
            backgroundColor: "white",
            color: "#2D604D",
            padding: "15px",
            transition: {
              type: "spring",
              bounce: 0,
              duration: 0.1,
              delayChildren: 0.1,
              staggerChildren: 0.02,
            },
          },
          closed: {
            opacity: 0,
            height: 0,
            backgroundColor: "transparent",
            boxShadow: "none",
            padding: "0px",
            transition: {
              boxShadow: "none",
            },
          },
        }}
        style={{ pointerEvents: isOpen ? "auto" : "none" }}
      >
        <motion.li
          className="hover:text-black pb-2 flex justify-end items-center cursor-pointer"
          data-value="all"
          onClick={() => {
            setFilterOption("all");
            handleFiltering("all");
          }}
          variants={itemVariants}
        >
          <p> {t("Linked.Farm Family")}</p>
          <div className="w-4 ml-1">
            <img src={pinhub} alt="" className=" " />
          </div>
          <div className="w-4  ml-1">
            <img src={pinpickpoint} alt="" className="" />
          </div>
          <div className="w-4 ">
            <img src={pinproducer} alt="" className="ml-1 " />
          </div>
        </motion.li>
        <motion.li
          className="hover:text-black pb-2 flex justify-end items-center cursor-pointer"
          data-value="deliveries"
          onClick={() => {
            setFilterOption("deliveries");
            handleFiltering("deliveries");
          }}
          variants={itemVariants}
        >
          <p> {t("Deliveries")} </p>
          <div className="w-4">
            <img src={truck} alt="" className=" ml-1" />
          </div>
        </motion.li>
        <motion.li
          className="hover:text-black flex justify-end items-center pb-2 cursor-pointer"
          data-value="hub"
          onClick={() => {
            setFilterOption("hub");
            handleFiltering("hub");
          }}
          variants={itemVariants}
        >
          <p>Hubs</p>
          <div className="w-4">
            <img src={pinhub} alt="" className=" ml-1" />
          </div>
        </motion.li>
        <motion.li
          className="hover:text-black pb-2  flex justify-end items-center cursor-pointer "
          data-value="pickupLocation"
          onClick={() => {
            setFilterOption("pickupLocation");
            handleFiltering("pickupLocation");
          }}
          variants={itemVariants}
        >
          <p> {t("Pickup Points")}</p>
          <div className="w-4">
            <img src={pinpickpoint} alt="" className=" ml-1" />
          </div>
        </motion.li>
        <motion.li
          className="hover:text-black pb-2 flex justify-end items-center  cursor-pointer"
          data-value="producer"
          onClick={() => {
            setFilterOption("producer");
            handleFiltering("producer");
          }}
          variants={itemVariants}
        >
          <p> {t("Producers")}</p>
          <div className="w-4">
            <img src={pinproducer} alt="" className=" ml-1" />
          </div>
        </motion.li>

        <motion.li
          className="hover:text-black pb-2  cursor-pointer "
          variants={itemVariants}
        >
          <input
            type="checkbox"
            checked={isB2BChecked}
            onChange={() => setIsB2BChecked((prev) => !prev)}
          />
          <label>B2B</label>
        </motion.li>
        <motion.li
          className="hover:text-black cursor-pointer "
          variants={itemVariants}
        >
          <input
            type="checkbox"
            checked={isB2CChecked}
            onChange={() => setIsB2CChecked((prev) => !prev)}
          />
          <label>B2C</label>
        </motion.li>
      </motion.ul>
    </motion.nav>
  );
};
export default Filters;
