import ButtonGreen from "./ButtonGreen";
import Title3Amatic from "./Title3Amatic";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import desktop from "../assets/images/webshop-desktop.png";
import { useEffect, useState } from "react";

const GoodToKnow2 = () => {
  const [currentLanguage, setCurrentLanguage] = useState("en_EN");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const language = i18n.language;
    let x;
    if (language === "fr") {
      x = "fr_FR";
    } else if (language === "nl") {
      x = "nl_BE";
    } else {
      x = "en_EN";
    }
    setCurrentLanguage(x);
  }, [i18n.language]);

  return (
    <div className="flex xxs:flex-col-reverse lg:flex-row w-full justify-between items-center ">
      <div className="flex justify-center items-center xxs:w-[80vw]  sm:w-[80vw] md:w-[30vw] xxl:w-[30vw]  ">
        <div
          className="  xxs:w-[70vw] s:w-[70vw] sm:w-[240px] md:w-[240px] lg:w-[240px] xl:w-[300px] xxl:w-[350px]
                          xxs:h-[70vw] s:h-[70vw] sm:h-[240px] md:h-[240px] lg:h-[240px] xl:h-[300px] xxl:h-[350px]
                          rounded-3xl bg-light-green
                     lg:mb-10 xxs:mb-8 
                          flex items-center justify-center"
        >
          <img alt="schema" src={desktop} className="w-2/3" />
        </div>
      </div>
      <div className=" flex flex-col justify-center items-center  lg:mb-10 xxs:mb-8 rounded-3xl py-5">
        <Title3Amatic titleText={"*" + t("Good to know")} />
        <ul
          className=" text-left font-lexend list-disc  
                      xxs:w-[80vw]  sm:w-[80vw] md:w-[40vw] xxl:w-[40vw]  pt-5
                      xxs:leading-7 lg:leading-8 mb-8 box-border pl-9 hyphens-auto
                      "
        >
          <li>
            {t("We offer support via ")}
            <Link
              className="text-green underline"
              to="mailto:support@linkedfarm.eu"
            >
              mail
            </Link>{" "}
            {t(" or via our ")}
            <Link
              target="_blank"
              className="text-green underline"
              to={`https://wiki.linkedfarm.eu/#/${currentLanguage}`}
            >
              wiki
            </Link>
          </li>
          <li>{t("There are no hidden costs")}</li>
          <li>{t("The new developments are available to all cooperants.")}</li>
        </ul>
        <ButtonGreen text={t("Contact us")} link={"/contact"} />
      </div>
    </div>
  );
};
export default GoodToKnow2;
