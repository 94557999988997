import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import tractor from "../../assets/Drawings/tractor.png";
import deliveries from "../../assets/Drawings/deliveries.png";
import client from "../../assets/Drawings/clients.png";
import CountUp from "react-countup";
import { useTranslation  } from 'react-i18next';

const GetVars = () => {
  const url =
    "https://www.linked.farm/api/v2/linkedfarm/view/linkedfarm.php?data=totals&api=wefg496ad45afsjeg4s633924ujimshd4g56a4f13aghg56f4sa";
  const [apiData, setApiData] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const farmersRef = useRef(null);
  const customersRef = useRef(null);
  const deliveriesRef = useRef(null);
  const {t} = useTranslation ()
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );



    const elements = [farmersRef, customersRef, deliveriesRef];
    elements.forEach(ref => {
      if (ref.current) {
         observer.observe(ref.current);
      }
    });
    
    return () => {
      elements.forEach(ref => {
        if (ref.current) {
           observer.unobserve(ref.current);
        }
      });
    };
    
  }, []);

  useEffect(() => {
    axios
      .get(url)
      .then((response) => {
        setApiData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [url]);

  return (
    <div className="w-full h-full font-amatic flex justify-end tracking-wider">
      <ul className="bg-white border border-green backdrop-blur-s opacity-90 text-green drop-shadow-sm  
      xxs:text-s xs:text-base s:text-lg  sm:text-2xl   md:text-2xl xl:text-3xl 
                    flex  items-center flex-col 
                    xxs:p-3 xs:p-3 s:p-3 sm:p-3 md:p-3 lg:p-4 
                    xxs:mr-3 lg:mr-3 xl:mr-5
                    xxs:w-[36vw] md:w-[27vw]  lg:w-[18vw] xl:w-[17vw] xxl:w-[17vw]  
                    xxs:rounded-br-[30px] xxs:rounded-t-[30px] s:rounded-br-[50px] s:rounded-t-[50px] sm:ounded-t-[100px] rounded-br-[100px]"
      >
        <img
      className="xxl:w-[60px] xxl:h-[60px] lg:w-[35px] lg:h-[35px]  sm:w-[40px] sm:h-[40px] s:w-[30px] s:h-[30px] xxs:hidden s:block"
          alt=""
          src={tractor}
        />
        <li
          ref={farmersRef}
          className= {`lg:px-3 md:px-3 sm:px-2 s:px-2 xxs:px-1 s:p-0 xxs:p-0 flex  items-center xxs:mb-2  ${
            isVisible ? "animate-counter" : ""
          }`}
        >
          <div className="flex justify-end">
            {isVisible && (
              <CountUp
                start={apiData?.farmers?.[0]?.total-10}
                end={apiData?.farmers?.[0]?.total || 0}
                duration={120}
                separator=","
              />
            )}
          </div>
          <span className="ml-2">{t('Farmers')}</span>
        </li>
        <img
   className="xxl:w-[60px] xxl:h-[60px] lg:w-[35px] lg:h-[35px] sm:w-[40px] sm:h-[40px] s:w-[30px] s:h-[30px] xxs:hidden s:block"
          alt=""
          src={client}
        />
        <li
          ref={customersRef}
          className={`lg:pr-3 md:px-3 sm:px-2 s:px-2 xxs:px-1 sm:py-1 s:p-0 xxs:p-0 flex   items-center xxs:mb-2   ${
            isVisible ? "animate-counter" : ""
          }`}
        >
          <div className=" flex justify-end">
            {isVisible && (
              <>
                <CountUp
                  start={apiData?.customers?.[0]?.total-20}
                  end={apiData?.customers?.[0]?.total || 0}
                  duration={120}
                  separator=","
                />
              </>
            )}
          </div>
          <span className="ml-2  ">{t('Clients')}</span>
        </li>
        <img
        className="xxl:w-[60px] xxl:h-[60px] lg:w-[35px] lg:h-[35px] sm:w-[40px] sm:h-[40px] s:w-[30px] s:h-[30px] xxs:hidden s:block"
          alt=""
          src={deliveries}
        />
        <li
          ref={deliveriesRef}
          className={`lg:pr-2 md:pr-2 sm:pr-2 s:pr-2 xxs:pr-1 sm:py-1 s:p-0 xxs:p-0 flex  items-center  xxs:mb-0  ${
            isVisible ? "animate-counter" : ""
          }`}
        >
          <div className=" flex justify-end">
            {isVisible && (
              <CountUp
                start={apiData?.orders?.[0]?.total-50}
                end={apiData?.orders?.[0]?.total || 0}
                duration={120}
                separator=","
              />
            )}
          </div>
          <span className="ml-2  ">{t('Orders')}</span>
        </li>
      </ul>
    </div>
  );
};

export default React.memo(GetVars);