import React from 'react';

const BigImage= ({ imagePath }) => {
  return (
    <div className=" xxs:mb-8 sm:mb-8 mb-10
      xxs:w-[240px] xs:w-[260px] s:w-[350px] sm:w-[400px] md:w-[400px] lg:w-[650px] xl:w-[700px] xxl:w-[900px]
       border-box">
      <img alt="schema" src={imagePath} className="w-full" />
    </div>
  );
};

export default BigImage;